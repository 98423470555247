import { useEffect } from 'react'
import LoaderLinear from '../../components/LoaderLinear'

import { useIncome } from '../../hooks/income.hook'

import HistoryTable from './components/HistoryTable'

const HistoryPage = () => {
	const { loading, ready, getIncomeByUserId, incomeList } = useIncome()

	useEffect(() => {
		getIncomeByUserId()
	}, [])

	if (loading || !ready) return <LoaderLinear />

	return <HistoryTable rows={incomeList} />
}

export default HistoryPage
