import React, { useEffect } from 'react'
import { useParams } from 'react-router-dom'

import { AdminDashboardPage } from './DashboardPage'
import { AdminIncomesPage } from './IncomesPage'
import { AdminInvestmentsPage } from './InvestmentsPage'
import { AdminPaymentsPage } from './PaymentsPage'
import { AdminPlansPage } from './PlansPage'
import { AdminUsersPage } from './UsersPage'

import ContactPageIcon from '@mui/icons-material/ContactPage'
import TabContext from '@mui/lab/TabContext'
import TabList from '@mui/lab/TabList'
import TabPanel from '@mui/lab/TabPanel'
import Box from '@mui/material/Box'
import Tab from '@mui/material/Tab'
import PageLayout from '../../layout/PageLayout'

const pathname = window.location.pathname

const navItems = [
	{
		value: 'dashboard',
		label: 'Dashboard',
		to: '/admin',
		current: `${pathname}` === '/admin' ? true : false,
		icon: <ContactPageIcon />,
		element: <AdminDashboardPage />,
	},
	{
		value: 'users',
		label: 'Users',
		to: '/admin/users',
		current: `${pathname}` === '/admin/users' ? true : false,
		icon: <ContactPageIcon />,
		element: <AdminUsersPage />,
	},
	{
		value: 'payments',
		label: 'Payments',
		to: '/admin/payments',
		current: `${pathname}` === '/admin/payments' ? true : false,
		icon: <ContactPageIcon />,
		element: <AdminPaymentsPage />,
	},
	{
		value: 'investments',
		label: 'Investments',
		to: '/admin/investments',
		current: `${pathname}` === '/admin/investments' ? true : false,
		icon: <ContactPageIcon />,
		element: <AdminInvestmentsPage />,
	},
	{
		value: 'incomes',
		label: 'Incomes',
		to: '/admin/incomes',
		current: `${pathname}` === '/admin/incomes' ? true : false,
		icon: <ContactPageIcon />,
		element: <AdminIncomesPage />,
	},
	{
		value: 'plans',
		label: 'Plans',
		to: '/admin/plans',
		current: `${pathname}` === '/admin/plans' ? true : false,
		icon: <ContactPageIcon />,
		element: <AdminPlansPage />,
	},
]

export const AdminPage = () => {
	const { tab } = useParams()

	const [tabValue, setTabValue] = React.useState('dashboard')

	const handleTabChange = (event, newValue) => {
		setTabValue(newValue)
		window.history.pushState({}, undefined, `/admin/${newValue}`)
	}

	useEffect(() => {
		if (tab) setTabValue(tab)
	}, [tab])

	return (
		<PageLayout>
			<TabContext value={tabValue}>
				<Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
					<TabList
						onChange={handleTabChange}
						variant='scrollable'
						scrollButtons='auto'
					>
						{navItems.map((item, index) => {
							return (
								<Tab
									key={index}
									label={item.label}
									value={item.value}
									icon={item.icon}
									iconPosition='start'
								/>
							)
						})}
					</TabList>
				</Box>

				{navItems.map((item, index) => {
					return (
						<TabPanel key={index} value={item.value}>
							{item.element}
						</TabPanel>
					)
				})}
			</TabContext>
		</PageLayout>
	)
}
