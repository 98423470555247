import React, { useEffect, useState } from 'react'

import PlansTable from './components/PlansTable'
import { usePlan } from '../../hooks/admin/plan.hook'
import LoaderLinear from '../../components/LoaderLinear'

export const AdminPlansPage = () => {
	const { loading, ready, getPlans, addPlan, removePlan } = usePlan()

	const [data, setData] = useState([])

	const refresh = () => {
		getPlans().then(res => setData(res))
	}

	useEffect(() => {
		getPlans().then(res => setData(res))
	}, [])

	if (loading || !ready) return <LoaderLinear />

	return (
		<>
			<main>
				<div className='mx-auto max-w-7xl py-6 sm:px-6 lg:px-8'>
					<PlansTable
						rows={data}
						refresh={refresh}
						addPlan={addPlan}
						removePlan={removePlan}
					/>
				</div>
			</main>
		</>
	)
}
