import { useCallback, useContext, useState } from 'react'

import { AuthContext } from '../context/AuthContext'
import { useHttp } from './http.hook'

export const useInvestment = (amount = '', plan = '', userId = '') => {
	const { loading, request } = useHttp()
	const { token } = useContext(AuthContext)

	const [ready, setReady] = useState(false)

	const getInvestedSum = useCallback(async () => {
		try {
			const doc = await request('/api/investment/sum')

			return doc
		} catch (err) {
			console.log(err)
		}
	}, [request])

	const getInvestmentByUserId = useCallback(
		async (filterWallet, filterStatus) => {
			try {
				const doc = await request(
					`/api/investment/${filterWallet}/${filterStatus}`,
					'GET',
					null,
					{
						Authorization: `Bearer ${token}`,
					}
				)

				setReady(true)

				return doc
			} catch (err) {
				console.log(err)
			}
		},
		[token, request]
	)

	const insertInvestment = useCallback(
		async (amount, plan, wallet, userId) => {
			try {
				const doc = await request(
					`/api/investment`,
					'POST',
					{ amount, plan, wallet, userId },
					{
						Authorization: `Bearer ${token}`,
					}
				)

				// console.log('inv hook', doc)

				setReady(true)

				return doc
			} catch (err) {
				console.log(err)
			}
		},
		[token, request, amount]
	)

	return {
		loading,
		ready,
		getInvestmentByUserId,
		// investmentList,
		insertInvestment,
		getInvestedSum,
	}
}
