import React, { useEffect, useRef, useState } from 'react'
import ReCAPTCHA from 'react-google-recaptcha'
import { useNavigate, useParams } from 'react-router-dom'

import { PinInput } from 'react-input-pin-code'

import { useHttp } from '../../hooks/http.hook'
import { useIP } from '../../hooks/ip.hook'
import { useMessage } from '../../hooks/message.hook'

import PageLayout from '../../layout/PageLayout'

import Avatar from '@mui/material/Avatar'
import Button from '@mui/material/Button'
import Paper from '@mui/material/Paper'
import TextField from '@mui/material/TextField'
import Typography from '@mui/material/Typography'

import styles from './RegisterPage.module.scss'
import Input from '../../components/UI/Input'
import PinInput2 from '../../components/UI/PinInput2'

export const RegisterPage = () => {
	const param = useParams()
	const message = useMessage()
	const { loading, request, error, clearError } = useHttp()
	const ip = useIP()

	const navigate = useNavigate()

	// const [username, setUsername] = useState('')
	// const [email, setEmail] = useState('')
	// const [password, setPassword] = useState('')
	// const [passwordConfirmation, setPasswordConfirmation] = useState('')
	// const [pinCode, setPinCode] = useState(0)
	// const [referrer, setReferrer] = useState('')
	// const [referrerUsername, setReferrerUsername] = useState('')
	// const [createdIp, setCreatedIp] = useState('')

	// recaptcha
	const captchaRef = useRef(null)
	const pageRef = 'RegisterPage'

	const [formData, setFormData] = useState({
		username: '',
		email: '',
		password: '',
		passwordConfirmation: '',
		pinCode: '',
		referrer: '',
		referrerUsername: '',
		createdIp: '',
	})

	const registerHandler = async () => {
		try {
			// recaptcha
			const captchaToken = captchaRef.current.getValue()
			captchaRef.current.reset()
			const captchaData = await request('/api/recaptcha', 'POST', {
				captchaToken,
				pageRef,
			})
			// if (!captchaData.success) return message(captchaData.message, 'error')

			const res = await request('/api/auth/register', 'POST', formData)

			const errorsCount = res?.errors?.length

			if (errorsCount) {
				const errors = res?.errors?.map(el => el.param)

				if (errors.includes('username')) {
					// setUsernameError(true)
					// setUsernameHelperText('hhh')

					console.log('username error')

					// res?.errors?.map(el =>
					// 	el.param === 'username' ? setUsernameHelperText(el.msg) : ''
					// )
				} else {
					// setUsernameError(false)
					// setUsernameHelperText('')
				}
				if (errors.includes('email')) {
					// setEmailError(true)
					// setEmailHelperText('hhh')

					console.log('email error')

					// res?.errors?.map(el =>
					// 	el.param === 'email' ? setEmailHelperText(el.msg) : ''
					// )
				} else {
					// setEmailError(false)
					// setEmailHelperText('')
				}
				if (errors.includes('password')) {
					// setPasswordError(true)
					// setPasswordHelperText('hhh')

					console.log('password error')

					// res?.errors?.map(el =>
					// 	el.param === 'password' ? setPasswordHelperText(el.msg) : ''
					// )
				} else {
					// setPasswordError(false)
					// setPasswordHelperText('')
				}
				if (errors.includes('passwordConfirmation')) {
					// setPasswordConfirmationError(true)
					// setPasswordConfirmationHelperText('hhh')

					console.log('passwordConfirmation error')

					// res?.errors?.map(el =>
					// 	el.param === 'passwordConfirmation'
					// 		? setPasswordConfirmationHelperText(el.msg)
					// 		: ''
					// )
				} else {
					// setPasswordConfirmationError(false)
					// setPasswordConfirmationHelperText('')
				}
			} else {
				// setUsernameError(false)
				// setEmailError(false)
				// setPasswordError(false)
				// setPasswordConfirmationError(false)

				// setUsernameHelperText('')
				// setEmailHelperText('')
				// setPasswordHelperText('')
				// setPasswordConfirmationHelperText('')

				message(res.message)

				navigate('/login')
			}

			// console.log('res', res)
		} catch (err) {
			console.log('error', err)
		}
	}

	// useEffect(() => {
	// message(error, 'error')
	// clearError()
	// }, [error, message, clearError])

	useEffect(() => {
		setFormData({ ...formData, createdIp: ip.ipAddress })
	}, [ip.ipAddress])

	useEffect(() => {
		if (localStorage.getItem('referrer')) {
			setFormData({
				...formData,
				referrer: localStorage.getItem('referrer').split('_')[0],
			})
			setFormData({
				...formData,
				referrerUsername: localStorage.getItem('referrer').split('_')[1],
			})
		} else {
			if (param.id && param.id.length === 24) {
				request(`/api/referrer/${param.id}`).then(res => {
					setFormData({ ...formData, referrer: res._id })
					setFormData({ ...formData, referrerUsername: res.personal.username })

					if (!localStorage.getItem('referrer')) {
						localStorage.setItem(
							'referrer',
							`${res._id}_${res.personal.username}`
						)
					}
				})
			}
		}

		console.log('formData', formData)
	}, [request, param.id])

	return (
		<PageLayout>
			<div className='bg-gray-700'>
				<main id='content' role='main' className='w-full max-w-md mx-auto p-6'>
					<div className='mt-7 bg-white  rounded-xl shadow-lg dark:bg-gray-800 dark:border-gray-700'>
						<div className='p-4 sm:p-7'>
							<div className='text-center'>
								<h1 className='block text-2xl font-bold'>
									<span className='text-red-600'>Register</span> a new account
								</h1>
							</div>

							<div className='mt-5'>
								<form>
									<div className='grid gap-y-4'>
										<Input
											title='Username'
											placeholder='Enter your username'
											name='username'
											value={formData.username}
											onChange={e =>
												setFormData({
													...formData,
													[e.target.name]: e.target.value,
												})
											}
										/>
										<Input
											title='Email'
											placeholder='user@dominicash.com'
											type='email'
											name='email'
											value={formData.email}
											onChange={e =>
												setFormData({
													...formData,
													[e.target.name]: e.target.value,
												})
											}
										/>
										<Input
											title='Password'
											placeholder='**********'
											type='password'
											name='password'
											value={formData.password}
											onChange={e =>
												setFormData({
													...formData,
													[e.target.name]: e.target.value,
												})
											}
										/>
										<Input
											title='Repeat Password'
											placeholder='**********'
											type='password'
											name='passwordConfirmation'
											value={formData.passwordConfirmation}
											onChange={e =>
												setFormData({
													...formData,
													[e.target.name]: e.target.value,
												})
											}
										/>
										{/* <Input type='' name='' value={formData.} onChange={e => setFormData({...formData, [e.target.name]: e.target.value})}/> */}
										{/* <PinInput2 /> */}

										{/* <checkbox /> Agree with Terms and Conditions */}
										<ReCAPTCHA
											ref={captchaRef}
											sitekey={process.env.REACT_APP_RECAPTCHA_SITE_KEY}
											className='mb-5'
										/>
										<Button
											variant='contained'
											fullWidth
											disabled={loading}
											onClick={registerHandler}
										>
											SIGN UP
										</Button>
									</div>
								</form>
							</div>
						</div>
					</div>
				</main>
			</div>
		</PageLayout>

		// <PageLayout>
		// 	<Paper classes={{ root: styles.root }}>
		// 		<Typography classes={{ root: styles.title }} variant='h5'>
		// 			<span className='text-red-600'>Register</span> a new account
		// 		</Typography>

		// 		<div className={styles.avatar}>
		// 			<Avatar sx={{ width: 100, height: 100 }} />
		// 		</div>

		// 		{referrer && referrerUsername && (
		// 			<div className='block text-center text-sm font-medium leading-6 text-gray-900 mb-5'>
		// 				You were invited by:
		// 				<span className='border px-3 py-1 ml-3'>{referrerUsername}</span>
		// 				<input type='hidden' name='referrer' value={formData.referrer} />
		// 			</div>
		// 		)}

		// 		<TextField
		// 			className={styles.field}
		// 			label='Enter username'
		// 			fullWidth
		// 			type='text'
		// 			required
		// 			error={usernameError}
		// 			helperText={usernameHelperText}
		// 			value={formData.username}
		// 			onChange={e => setUsername(e.target.value)}
		// 		/>

		// 		<TextField
		// 			className={styles.field}
		// 			label='Enter email'
		// 			fullWidth
		// 			type='email'
		// 			required
		// 			error={emailError}
		// 			helperText={emailHelperText}
		// 			value={formData.email}
		// 			onChange={e => setEmail(e.target.value)}
		// 		/>

		// 		<TextField
		// 			className={styles.field}
		// 			label='Enter password'
		// 			fullWidth
		// 			type='password'
		// 			required
		// 			error={passwordError}
		// 			helperText={passwordHelperText}
		// 			value={formData.password}
		// 			onChange={e => setPassword(e.target.value)}
		// 		/>

		// 		<TextField
		// 			className={styles.field}
		// 			label='Repeat Password'
		// 			fullWidth
		// 			type='password'
		// 			required
		// 			error={passwordConfirmationError}
		// 			helperText={passwordConfirmationHelperText}
		// 			value={formData.passwordConfirmation}
		// 			onChange={e => setPasswordConfirmation(e.target.value)}
		// 		/>

		// 		<ReCAPTCHA
		// 			ref={captchaRef}
		// 			sitekey={process.env.REACT_APP_RECAPTCHA_SITE_KEY}
		// 			className='mb-5'
		// 		/>

		// 		<Button
		// 			size='large'
		// 			variant='contained'
		// 			fullWidth
		// 			disabled={loading}
		// 			onClick={registerHandler}
		// 		>
		// 			Sign up
		// 		</Button>
		// 	</Paper>
		// </PageLayout>
	)
}
