import React, { useEffect } from 'react'
import { useParams } from 'react-router-dom'

import DepositPage from './DepositPage'
import WithdrawalPage from './WithdrawalPage'

import TabContext from '@mui/lab/TabContext'
import TabList from '@mui/lab/TabList'
import TabPanel from '@mui/lab/TabPanel'
import Box from '@mui/material/Box'
import Tab from '@mui/material/Tab'
import PageLayout from '../../layout/PageLayout'

export const BankPage = () => {
	const { tab } = useParams()

	const [tabValue, setTabValue] = React.useState('deposit')

	const handleTabChange = (event, newValue) => {
		setTabValue(newValue)
		window.history.pushState({}, undefined, `/bank/${newValue}`)
	}

	useEffect(() => {
		if (tab) setTabValue(tab)
	}, [tab])

	return (
		<PageLayout>
			<TabContext value={tabValue}>
				<Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
					<TabList onChange={handleTabChange} aria-label='Bank tabs'>
						<Tab label='Deposit' value='deposit' />
						<Tab label='Withdraw' value='withdrawal' />
					</TabList>
				</Box>
				<TabPanel value='deposit'>
					<DepositPage />
				</TabPanel>
				<TabPanel value='withdrawal'>
					<WithdrawalPage />
				</TabPanel>
			</TabContext>
		</PageLayout>
	)
}
