import React, { useCallback, useContext, useEffect, useState } from 'react'
import { NavLink, useParams } from 'react-router-dom'

import { useHttp } from '../../hooks/http.hook'
import { AuthContext } from '../../context/AuthContext'
import { Loader } from '../../components/Loader'

import Box from '@mui/material/Box'
import Tab from '@mui/material/Tab'
import TabContext from '@mui/lab/TabContext'
import TabList from '@mui/lab/TabList'
import TabPanel from '@mui/lab/TabPanel'

import { UserCircleIcon } from '@heroicons/react/24/solid'
import { ProfilePage } from './ProfilePage'
import { VerificationPage } from './Verification'
import { WalletsPage } from './WalletsPage'
import { PersonalPage } from './PersonalPage'
import { ContactsPage } from './ContactsPage'
import { SecurityPage } from './SecurityPage'
import CopyToClipboard from 'react-copy-to-clipboard'
import { useMessage } from '../../hooks/message.hook'
import ContentCopyIcon from '@mui/icons-material/ContentCopy'
import Button from '@mui/material/Button'
import PageLayout from '../../layout/PageLayout'

export const UserProfilePage = () => {
	const { tab } = useParams()

	const [tabValue, setTabValue] = React.useState('profile')

	const handleTabChange = (event, newValue) => {
		setTabValue(newValue)
		window.history.pushState({}, undefined, `/profile/${newValue}`)
	}

	useEffect(() => {
		if (tab) setTabValue(tab)
	}, [tab])

	return (
		<PageLayout>
			<TabContext value={tabValue}>
				<Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
					<TabList
						onChange={handleTabChange}
						variant='scrollable'
						scrollButtons='auto'
					>
						<Tab label='Profile' value='profile' />
						{/* <Tab label='Verification' value='verification' /> */}
						<Tab label='Wallets' value='wallets' />
						<Tab label='Personal Information' value='personal' />
						<Tab label='Contacts' value='contacts' />
						<Tab label='Security' value='security' />
					</TabList>
				</Box>
				<TabPanel value='profile'>
					<ProfilePage />
				</TabPanel>
				{/* <TabPanel value='verification'>
					<VerificationPage />
				</TabPanel> */}
				<TabPanel value='wallets'>
					<WalletsPage />
				</TabPanel>
				<TabPanel value='personal'>
					<PersonalPage />
				</TabPanel>
				<TabPanel value='contacts'>
					<ContactsPage />
				</TabPanel>
				<TabPanel value='security'>
					<SecurityPage />
				</TabPanel>
			</TabContext>
		</PageLayout>
	)
}
