import { useEffect, useState } from 'react'

import { useHttp } from './http.hook'

export const useIP = () => {
	const { loading, request } = useHttp()

	const [readyIp, setReadyIp] = useState(false)

	const [ipAddress, setIpAddress] = useState('')

	useEffect(() => {
		const getIpAddress = async () => {
			try {
				const res = await request('https://api.ipify.org?format=json')

				setIpAddress(res.ip)
				setReadyIp(true)
			} catch (err) {
				console.log(err)
			}
		}

		getIpAddress()
	}, [request])

	return { loading, readyIp, ipAddress }
}
