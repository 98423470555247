import { useCallback } from 'react'
import { useHttp } from './http.hook'

export const useEmail = () => {
	const { request } = useHttp()

	const sendEmail = useCallback(
		async (to, subject, html) => {
			try {
				const res = await request(`/api/sendemail`, 'POST', {
					to,
					subject,
					html,
				})

				return res
			} catch (err) {
				console.log(err)
			}
		},
		[request]
	)

	return { sendEmail }
}
