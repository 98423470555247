import React from 'react'
import CopyToClipboard from 'react-copy-to-clipboard'

import { useMessage } from '../hooks/message.hook'

import ContentCopyIcon from '@mui/icons-material/ContentCopy'
import { Button } from '@mui/material'
import Tooltip from '@mui/material/Tooltip'

const CopyButton = ({
	name = 'Copy',
	value,
	onCopyMessage,
	messageType = 'info',
	tooltip = '',
}) => {
	const message = useMessage()

	return (
		<CopyToClipboard
			text={value}
			onCopy={() => message(onCopyMessage, messageType)}
		>
			<Tooltip title={tooltip}>
				<Button
					sx={{
						ml: 1,
						color: 'white',
						textTransform: 'lowercase',
					}}
					variant='contained'
					startIcon={<ContentCopyIcon />}
				>
					{name}
				</Button>
			</Tooltip>
		</CopyToClipboard>
	)
}

export default CopyButton
