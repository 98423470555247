// https://formidable.com/open-source/nuka-carousel/api

import Carousel from 'nuka-carousel'
import React, { useEffect, useState } from 'react'

import LoaderLinear from '../../../components/LoaderLinear'
import { usePlan } from '../../../hooks/plan.hook'
import currencyFormatUSD from '../../../utils/currencyFormatUSD'
import CalculateModal from './CalculateModal'

import { List, ListItem, ListItemText } from '@mui/material'
import Container from '@mui/material/Container'
import CssBaseline from '@mui/material/CssBaseline'
import GlobalStyles from '@mui/material/GlobalStyles'
import { createTheme, ThemeProvider } from '@mui/material/styles'
import useMediaQuery from '@mui/material/useMediaQuery'
import StyledHeading from '../../../components/StyledHeading'
import StyledHeading3 from '../../../components/StyledHeading3'

const defaultTheme = createTheme()

// Deposit included in Profit
// Deposit returned

const PlansCarousel = () => {
	// const xsMatches = useMediaQuery('(min-width:0px)')
	// const smMatches = useMediaQuery('(min-width:600px)')
	const mdMatches = useMediaQuery('(min-width:900px)')
	// const lgMatches = useMediaQuery('(min-width:1200px)')
	// const xlMatches = useMediaQuery('(min-width:1536px)')

	const { loading, ready, getPlans } = usePlan()

	const [data, setData] = useState([])

	useEffect(() => {
		getPlans().then(res => {
			setData(res)
		})
	}, [])

	if (loading || !ready) return <LoaderLinear />

	return (
		<div className='w-full bg-[#121212]'>
			<Container maxWidth='lg' component='main'>
				<Carousel
					// withoutControls
					wrapAround
					autoplay
					slidesToShow={mdMatches ? 3 : 1}
				>
					{data.map(obj => (
						<div
							key={obj.name}
							className='mx-auto max-w-xs -mt-2 p-2 lg:mt-0 md:w-full md:max-w-sm md:flex-shrink-0'
						>
							<div className='rounded-2xl bg-gray-700 border-2 border-gray-300 text-white pb-10 pt-5 text-center ring-1 ring-inset ring-gray-900/5 lg:flex lg:flex-col lg:justify-center md:py-16'>
								<div className='mx-auto w-full max-w-sm px-4 md:px-12'>
									<p className='text-base font-semibold'>{obj.name}</p>

									<List dense>
										<ListItem
											secondaryAction={`${obj.percentDaily}%`}
											className='text-lg'
										>
											<ListItemText primary='Daily Profit' />
										</ListItem>
										<ListItem
											secondaryAction={`${obj.termDays} days`}
											className='text-lg'
										>
											<ListItemText primary='Period' />
										</ListItem>
										<ListItem
											secondaryAction={currencyFormatUSD(obj.amountMin)}
											className='text-lg'
										>
											<ListItemText primary='Min Deposit' />
										</ListItem>
										<ListItem
											secondaryAction={currencyFormatUSD(obj.amountMax)}
											className='text-lg'
										>
											<ListItemText primary='Max Deposit' />
										</ListItem>
									</List>
									<p className='mt-6 text-sm'>
										The deposit will be returned at the end
									</p>

									<CalculateModal plan={obj} />
								</div>
							</div>
						</div>
					))}
				</Carousel>
			</Container>
		</div>
	)
}

export default PlansCarousel
