import { useContext, useEffect, useState } from 'react'

import { AuthContext } from '../../context/AuthContext'
import { useBalance } from '../../hooks/balance.hook'
import { useInvestment } from '../../hooks/investment.hook'
import { useMessage } from '../../hooks/message.hook'

import StepAmount from './components/StepAmount'
import StepPlans from './components/StepPlans'
import StepReview from './components/StepReview'
import StepWallet from './components/StepWallet'

import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Card from '@mui/material/Card'
import CardContent from '@mui/material/CardContent'
import CardHeader from '@mui/material/CardHeader'
import Container from '@mui/material/Container'
import Step from '@mui/material/Step'
import StepLabel from '@mui/material/StepLabel'
import Stepper from '@mui/material/Stepper'
import Typography from '@mui/material/Typography'

const steps = ['Choose a plan', 'Select a wallet', 'Enter amount', 'Review']

export default function PlansPage() {
	const auth = useContext(AuthContext)

	const message = useMessage()

	const { getBalanceByUserId } = useBalance()
	const { insertInvestment } = useInvestment()

	const [investmentData, setInvestmentData] = useState({
		plan: '',
		wallet: '',
		amount: '',
	})
	const [result, setResult] = useState({ title: '', text: '' })

	const [disabledNextButton, setDisabledNextButton] = useState(true)

	const [activeStep, setActiveStep] = useState(0)

	const enableNextButton = selectedValue => {
		setDisabledNextButton(false)
		setInvestmentData({ ...investmentData, plan: selectedValue })
	}

	const handleNext = () => {
		setActiveStep(prevActiveStep => prevActiveStep + 1)
	}

	const handleBack = () => {
		setDisabledNextButton(false)
		setActiveStep(prevActiveStep => prevActiveStep - 1)
	}

	const handleReset = () => {
		setActiveStep(0)
		setInvestmentData({
			plan: '',
			wallet: '',
			amount: '',
		})
		setDisabledNextButton(true)
	}

	const handleSubmit = () => {
		insertInvestment(
			investmentData.amount,
			investmentData.plan,
			investmentData.wallet,
			auth.userId
		).then(res => {
			message(res.message, res.type)
			getBalanceByUserId().then(res => {
				auth.setBalance(res.real + res.bonus)
				auth.setReal(res.real)
				auth.setBonus(res.bonus)
			})
			const title =
				res.type === 'success' ? 'Well done' : 'Something went wrong!'
			const text =
				res.type === 'success'
					? 'Successfully! To view active investments, go to tab "ACTIVE"'
					: 'Please try again later'
			setResult({ title, text })
		})

		setActiveStep(prevActiveStep => prevActiveStep + 1)
	}

	useEffect(() => {
		if (investmentData.plan) setDisabledNextButton(false)
	}, [])

	return (
		<Box sx={{ width: '100%' }}>
			<Stepper activeStep={activeStep} sx={{ mb: 3 }}>
				{steps.map((label, index) => {
					const stepProps = {}
					const labelProps = {}

					return (
						<Step key={label} {...stepProps}>
							<StepLabel {...labelProps}>{label}</StepLabel>
						</Step>
					)
				})}
			</Stepper>
			{activeStep === steps.length ? (
				<>
					<Typography sx={{ mt: 2, mb: 1 }}>
						<Container maxWidth='sm'>
							<Card variant='outlined'>
								<CardHeader
									title={result.title}
									className='bg-blue-400 text-white'
								/>
								<CardContent sx={{ padding: '12px' }}>
									{result.text}
								</CardContent>
							</Card>
						</Container>
					</Typography>
					<Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
						<Box sx={{ flex: '1 1 auto' }} />
						<Button onClick={handleReset}>Select plan again</Button>
					</Box>
				</>
			) : (
				<>
					{activeStep === 0 && (
						<StepPlans
							investmentData={investmentData}
							enableNextButton={enableNextButton}
						/>
					)}

					{activeStep === 1 && (
						<StepWallet
							investmentData={investmentData}
							setInvestmentData={setInvestmentData}
							setDisabledNextButton={setDisabledNextButton}
						/>
					)}

					{activeStep === 2 && (
						<StepAmount
							setDisabledNextButton={setDisabledNextButton}
							investmentData={investmentData}
							setInvestmentData={setInvestmentData}
						/>
					)}

					{activeStep === 3 && <StepReview investmentData={investmentData} />}

					<Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
						<Button
							color='inherit'
							disabled={activeStep === 0}
							onClick={handleBack}
							sx={{ mr: 1 }}
						>
							Back
						</Button>
						<Box sx={{ flex: '1 1 auto' }} />

						<Button
							disabled={disabledNextButton}
							onClick={
								activeStep === steps.length - 1 ? handleSubmit : handleNext
							}
							variant={activeStep === steps.length - 1 ? 'contained' : 'text'}
						>
							{activeStep === steps.length - 1 ? 'Make Payment' : 'Next'}
						</Button>
					</Box>
				</>
			)}
		</Box>
	)
}
