import { createContext } from 'react'

function noop() {}

export const AuthContext = createContext({
	token: null,
	userId: null,
	login: noop,
	logout: noop,
	isAuth: false,
	isAdmin: false,
	balance: null,
	setBalance: noop,
	real: null,
	setReal: noop,
	bonus: null,
	setBonus: noop,
	notifications: null,
	setNotifications: noop,
})
