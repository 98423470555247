import React, {
	useCallback,
	useContext,
	useEffect,
	useState,
	useMemo,
} from 'react'
import { NavLink } from 'react-router-dom'
import TimezoneSelect from 'react-timezone-select'
import countryList from 'react-select-country-list'
import Select from 'react-select'

import { useHttp } from '../../hooks/http.hook'
import { AuthContext } from '../../context/AuthContext'
import { Loader } from '../../components/Loader'
import { useMessage } from '../../hooks/message.hook'

import CheckIcon from '@mui/icons-material/Check'
import CloseIcon from '@mui/icons-material/Close'
import { Button, CircularProgress, Tooltip } from '@mui/material'

export const ContactsPage = () => {
	const [ready, setReady] = useState(false)
	const [form, setForm] = useState({
		contacts: {
			country: { value: '', label: '' },
			email: '',
			phone: '',
			timezone: '',
			timezoneId: 0,
		},
	})
	const { loading, request } = useHttp()
	const { token } = useContext(AuthContext)

	const message = useMessage()

	const fetchData = useCallback(async () => {
		try {
			const doc = await request(`/api/profile/contacts`, 'GET', null, {
				Authorization: `Bearer ${token}`,
			})

			console.log('contacts page, fetchData, doc: ', doc)

			setForm(doc)

			setReady(true)
		} catch (err) {
			console.log(err)
		}
	}, [token, request])

	const updateHandler = useCallback(async () => {
		try {
			const doc = await request(
				`/api/profile/contacts`,
				'POST',
				{ ...form },
				{
					Authorization: `Bearer ${token}`,
				}
			)

			console.log('form', form)

			console.log('contacts page, updateHandler, doc: ', doc)

			if (doc.success) {
				message('Saved successful!', 'success')
			} else {
				message('Error!', 'error')
			}

			// setReady(true)
		} catch (err) {
			console.log(err)
		}
	}, [token, request, form])

	const CountrySelector = () => {
		const options = useMemo(() => countryList().getData(), [])

		const changeHandler = value => {
			setForm({ ...form, country: value })
		}

		return (
			<Select
				options={options}
				value={form.contacts.country}
				onChange={changeHandler}
			/>
		)
	}

	const changeHandler = event => {
		setForm({ ...form, [event.target.name]: event.target.value })
	}

	const changeTimezoneHandler = event => {
		setForm({ ...form, timezone: event.value })
	}

	const fetchVerify = useCallback(async () => {
		try {
			const doc = await request(
				`/api/auth/verify/sendCode`,
				'POST',
				{ email: form.contacts.email },
				{
					Authorization: `Bearer ${token}`,
				}
			)

			console.log(doc)
		} catch (err) {
			console.log(err)
		}
	}, [token, request])

	const handlerSendCode = () => {
		console.log('---handlerSendCode---')
		fetchVerify()
	}

	useEffect(() => {
		fetchData()
	}, [fetchData])

	if (loading || !ready) {
		return <CircularProgress />
	}

	console.log('form', form)

	return (
		<main>
			<div className='mx-auto max-w-7xl py-6 sm:px-6 lg:px-8'>
				<div className='mt-10 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6'>
					<div className='sm:col-span-2 sm:col-start-1'>
						<label
							htmlFor='email'
							className='block text-sm font-medium leading-6 text-gray-900'
						>
							Email
							{form.account.emailVerified ? (
								<Tooltip title='Email verified'>
									<CheckIcon color='success' sx={{ ml: 1 }} />
								</Tooltip>
							) : (
								<>
									<Tooltip title='Email not verified'>
										<CloseIcon color='error' sx={{ ml: 1 }} />
									</Tooltip>
									<Button onClick={handlerSendCode}>
										Send activation code
									</Button>
								</>
							)}
						</label>
						<div className='mt-2'>
							<input
								type='text'
								name='email'
								disabled
								className='block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6'
								value={form.contacts.email}
								onChange={changeHandler}
							/>
						</div>
					</div>

					{/* <div className='sm:col-span-2 sm:col-start-1'>
						<label
							htmlFor='phone'
							className='block text-sm font-medium leading-6 text-gray-900'
						>
							Phone
						</label>
						<div className='mt-2'>
							<input
								type='text'
								name='phone'
								id='phone'
								className='block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6'
								value={form.contacts.phone}
								onChange={changeHandler}
							/>
						</div>
					</div> */}

					<div className='sm:col-span-2 sm:col-start-1'>
						<label
							htmlFor='country'
							className='block text-sm font-medium leading-6 text-gray-900'
						>
							Country
						</label>
						<div className='mt-2'>
							<CountrySelector />
						</div>
					</div>

					<div className='sm:col-span-2 sm:col-start-1'>
						<label
							htmlFor='timezone'
							className='block text-sm font-medium leading-6 text-gray-900'
						>
							Time Zone
						</label>
						<div className='mt-2'>
							<TimezoneSelect
								value={form.contacts.timezone}
								onChange={changeTimezoneHandler}
							/>
						</div>
					</div>

					<div className='sm:col-span-2 sm:col-start-1 text-center'>
						<button
							type='button'
							className='rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 w-1/2'
							onClick={updateHandler}
						>
							Save
						</button>
					</div>
				</div>
			</div>
		</main>
	)
}
