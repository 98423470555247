import { useState, useCallback } from 'react'

// import { useAuth } from './auth.hook'

export const useHttp = () => {
	const [loading, setLoading] = useState(false)
	const [error, setError] = useState(null)

	// const { logout } = useAuth()

	const request = useCallback(
		async (url, method = 'GET', body = null, headers = {}) => {
			setLoading(true)
			try {
				if (body) {
					body = JSON.stringify(body)
					headers['Content-Type'] = 'application/json'
				}

				const response = await fetch(url, { method, body, headers })
				const data = await response.json()

				// #todo if (data?.message === 'TokenExpiredError: jwt expired') logout()

				// if (!response.ok) {
				// throw new Error(data.message || 'Unexpected error')
				// throw new Error(data || 'Unexpected error')
				// }

				setLoading(false)

				return data
			} catch (err) {
				setLoading(false)

				setError(err.message)

				throw err
			}
		},
		[]
	)

	const clearError = useCallback(() => setError(null), [])

	return { loading, request, error, clearError }
}
