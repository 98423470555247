import { useState } from 'react'

import Overview from './Overview'
import { MyReferralsPage } from './MyReferralsPage'
import ReferralHistory from './ReferralHistory'

import TabContext from '@mui/lab/TabContext'
import TabList from '@mui/lab/TabList'
import TabPanel from '@mui/lab/TabPanel'
import Box from '@mui/material/Box'
import Tab from '@mui/material/Tab'
import PageLayout from '../../layout/PageLayout'

export const ReferralPage = () => {
	const [tabValue, setTabValue] = useState('1')

	const handleTabChange = (event, newValue) => {
		setTabValue(newValue)
	}

	return (
		<PageLayout>
			<TabContext value={tabValue}>
				<Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
					<TabList onChange={handleTabChange} aria-label='Investment Tabs'>
						<Tab label='Overview' value='1' />
						<Tab label='My Referrals' value='2' />
						<Tab label='History' value='3' />
					</TabList>
				</Box>
				<TabPanel value='1'>
					<Overview />
				</TabPanel>
				<TabPanel value='2'>
					<MyReferralsPage />
				</TabPanel>
				<TabPanel value='3'>
					<ReferralHistory />
				</TabPanel>
			</TabContext>
		</PageLayout>
	)
}
