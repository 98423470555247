import { useCallback, useContext, useState } from 'react'

import { useHttp } from './http.hook'
import { AuthContext } from '../context/AuthContext'

export const usePlan = () => {
	const { loading, request } = useHttp()

	const { token } = useContext(AuthContext)

	const [ready, setReady] = useState(false)

	const [plan, setPlan] = useState({})
	const [plans, setPlans] = useState([])
	const [data, setData] = useState('')

	const getPlans = useCallback(async () => {
		try {
			const doc = await request('/api/plan/')

			setReady(true)

			return doc
		} catch (err) {
			console.log(err)
		}
	}, [request])

	const getPlan = useCallback(
		async id => {
			try {
				const doc = await request(`/api/plan/${id}`)

				setReady(true)

				return doc
			} catch (err) {
				console.log(err)
			}
		},
		[request]
	)

	const getPercentDaily = useCallback(async () => {
		try {
			const doc = await request('/api/plan/percentDaily')

			setReady(true)

			return doc
		} catch (err) {
			console.log(err)
		}
	}, [token, request])

	const getTermDays = useCallback(async () => {
		try {
			const doc = await request('/api/plan/termDays')

			setReady(true)

			return doc
		} catch (err) {
			console.log(err)
		}
	}, [token, request])

	const fetchPlans = useCallback(async () => {
		try {
			const doc = await request(`/api/plan/`, 'GET', null, {
				Authorization: `Bearer ${token}`,
			})

			// console.log('doc', doc)

			setReady(true)

			setPlans(doc)
		} catch (err) {
			console.log(err)
		}
	}, [token, request])

	const fetchPlan = useCallback(
		async id => {
			try {
				// console.log('plan', plan)

				const doc = await request(`/api/plan/${id}`, 'GET', null, {
					Authorization: `Bearer ${token}`,
				})

				// console.log('doc', doc)

				setReady(true)

				// setPlan(doc)

				return doc
			} catch (err) {
				console.log(err)
			}
		},
		[token, request]
	)

	return {
		loading,
		ready,
		fetchPlan,
		plan,
		fetchPlans,
		plans,
		getPercentDaily,
		getTermDays,
		data,
		getPlan,
		getPlans,
	}
}
