import { useCallback, useContext, useState } from 'react'

import { useHttp } from './http.hook'
import { AuthContext } from '../context/AuthContext'

export const useReferral = () => {
	// const userId = JSON.parse(localStorage.getItem('userData'))['userId']

	const { token } = useContext(AuthContext)

	const { loading, request } = useHttp()

	const [ready, setReady] = useState(false)

	const [referralData, setReferralData] = useState([])

	const [referralCount, setReferralCount] = useState([])

	const getReferral = useCallback(async () => {
		try {
			const doc = await request(`/api/referral`, 'GET', null, {
				Authorization: `Bearer ${token}`,
			})

			setReferralData(doc)

			setReady(true)
		} catch (err) {
			console.log(err)
		}
	}, [token, request])

	const getReferralCount = useCallback(async () => {
		try {
			const doc = await request(`/api/referral/count`, 'GET', null, {
				Authorization: `Bearer ${token}`,
			})

			setReferralCount(doc)

			setReady(true)
		} catch (err) {
			console.log(err)
		}
	}, [token, request])

	return {
		ready,
		loading,
		getReferral,
		referralData,
		getReferralCount,
		referralCount,
	}
}
