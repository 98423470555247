import React from 'react'
import { Routes, Route, Navigate } from 'react-router-dom'

import StartPage from './pages/start'

import { NewsPage } from './pages/NewsPage'

import { LinksPage } from './pages/LinksPage'
import { CreatePage } from './pages/CreatePage'
import { DetailPage } from './pages/DetailPage'

import { LoginPage } from './pages/auth/LoginPage'
import { RegisterPage } from './pages/auth/RegisterPage'
import { ForgotPage } from './pages/auth/ForgotPage'

import { DashboardPage } from './pages/cabinet/DashboardPage'
import { ReferralPage } from './pages/referral'

import { BankPage } from './pages/bank'

import { InvestmentPage } from './pages/investment'

// import DepositPage from './pages/bank/DepositPage'
// import WithdrawalPage from './pages/bank/WithdrawalPage'

import { UserProfilePage } from './pages/profile'
import { VerificationPage } from './pages/profile/Verification'
import { PersonalPage } from './pages/profile/PersonalPage'
import { ContactsPage } from './pages/profile/ContactsPage'
import { SecurityPage } from './pages/profile/SecurityPage'
import { WalletsPage } from './pages/profile/WalletsPage'

import { AdminPage } from './pages/admin'
import AdminUserPage from './pages/admin/UserPage'
import AdminPlanPage from './pages/admin/PlanPage'
import AboutPage from './pages/AboutPage'
import FaqPage from './pages/FaqPage'
import BenefitsPage from './pages/BenefitsPage'
import SupportPage from './pages/SupportPage'
import SuccessPage from './pages/bank/SuccessPage'
import FailPage from './pages/bank/FailPage'
import LogoutPage from './pages/auth/LogoutPage'
import TestPage from './pages/TestPage'
import { RecoveryPage } from './pages/auth/RecoveryPage'
import VerifyPage from './pages/auth/VerifyPage'

export const useRoutes = (isAuth, isAdmin) => {
	if (isAdmin) {
		// Admin Routes
		return (
			<Routes>
				<Route path='/test' element={<TestPage />} exact />
				<Route path='/verify/:userId/:shortId' element={<VerifyPage />} />

				<Route path='/logout' element={<LogoutPage />} exact />
				<Route path='/' element={<StartPage />} exact />
				<Route path='/news' element={<NewsPage />} exact />

				<Route path='/about' element={<AboutPage />} exact />
				<Route path='/faq' element={<FaqPage />} exact />
				<Route path='/benefits' element={<BenefitsPage />} exact />
				<Route path='/support' element={<SupportPage />} exact />

				{/* Cabinet */}
				<Route path='/cabinet/dashboard' element={<DashboardPage />} exact />

				<Route path='/referral' element={<ReferralPage />} exact />

				{/* Profile */}
				<Route path='/profile' element={<UserProfilePage />} exact />
				<Route path='/profile/:tab' element={<UserProfilePage />} />

				{/* Bank */}
				<Route path='/bank/:tab' element={<BankPage />} />
				{/* <Route path='/bank/deposit' element={<DepositPage />} exact /> */}
				{/* <Route path='/bank/withdrawal' element={<WithdrawalPage />} exact /> */}
				{/* <Route path='/bank/transactions' element={<TransactionsPage />} exact /> */}

				{/* Investment */}
				<Route path='/investment' element={<InvestmentPage />} exact />

				{/* Payment Gateways */}
				<Route path='/success' element={<SuccessPage />} exact />
				<Route path='/fail' element={<FailPage />} exact />

				{/* Temp */}
				<Route path='/links' element={<LinksPage />} exact />
				<Route path='/create' element={<CreatePage />} exact />
				<Route path='/detail/:id' element={<DetailPage />} />

				{/* Admin */}
				<Route path='/admin' element={<AdminPage />} exact />
				<Route path='/admin/user/:id' element={<AdminUserPage />} />
				<Route path='/admin/plan/:id' element={<AdminPlanPage />} />
				<Route path='/admin/:tab' element={<AdminPage />} />

				{/* Redirect */}
				<Route path='*' element={<Navigate to='/cabinet/dashboard' />} />
			</Routes>
		)
	}

	if (isAuth) {
		// User Routes
		return (
			<Routes>
				<Route path='/test' element={<TestPage />} exact />
				<Route path='/verify/:userId/:shortId' element={<VerifyPage />} />

				<Route path='/logout' element={<LogoutPage />} exact />
				<Route path='/' element={<StartPage />} exact />
				<Route path='/news' element={<NewsPage />} exact />

				<Route path='/about' element={<AboutPage />} exact />
				<Route path='/faq' element={<FaqPage />} exact />
				<Route path='/benefits' element={<BenefitsPage />} exact />
				<Route path='/support' element={<SupportPage />} exact />

				{/* Cabinet */}
				<Route path='/cabinet/dashboard' element={<DashboardPage />} exact />

				<Route path='/referral' element={<ReferralPage />} exact />

				{/* Profile */}
				<Route path='/profile' element={<UserProfilePage />} exact />
				<Route path='/profile/:tab' element={<UserProfilePage />} exact />

				{/* Bank */}
				<Route path='/bank/:tab' element={<BankPage />} />

				{/* Investment */}
				<Route path='/investment' element={<InvestmentPage />} exact />

				{/* Payment Gateways */}
				<Route path='/success' element={<SuccessPage />} exact />
				<Route path='/fail' element={<FailPage />} exact />

				{/* Temp */}
				<Route path='/links' element={<LinksPage />} exact />
				<Route path='/create' element={<CreatePage />} exact />
				<Route path='/detail/:id' element={<DetailPage />} />

				{/* Redirect */}
				<Route path='*' element={<Navigate to='/cabinet/dashboard' />} />
			</Routes>
		)
	}

	// Guest Routes
	return (
		<Routes>
			{/* <Route path='/' element={<LoginPage />} exact /> */}
			<Route path='/test' element={<TestPage />} exact />
			<Route path='/verify/:userId/:shortId' element={<VerifyPage />} />

			<Route path='/about' element={<AboutPage />} exact />
			<Route path='/faq' element={<FaqPage />} exact />
			<Route path='/benefits' element={<BenefitsPage />} exact />
			<Route path='/support' element={<SupportPage />} exact />

			<Route path='/' element={<StartPage />} exact />
			<Route path='/news' element={<NewsPage />} exact />
			<Route path='/login' element={<LoginPage />} exact />
			<Route path='/register' element={<RegisterPage />} exact />
			<Route path='/register/:id' element={<RegisterPage />} />
			<Route path='/forgot' element={<ForgotPage />} exact />
			<Route path='/recovery/:userId/:shortId' element={<RecoveryPage />} />

			{/* Payment Gateways */}
			<Route path='/success' element={<SuccessPage />} exact />
			<Route path='/fail' element={<FailPage />} exact />

			{/* Redirect */}
			<Route path='*' element={<Navigate to='/' />} />
		</Routes>
	)
}
