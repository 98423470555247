import { useCallback, useContext, useEffect, useState } from 'react'

import { useHttp } from './http.hook'
import { AuthContext } from '../context/AuthContext'

export const useNotification = () => {
	const { token } = useContext(AuthContext)

	const { loading, request } = useHttp()

	const [ready, setReady] = useState(false)

	const getUnread = useCallback(async () => {
		try {
			const doc = await request(`/api/notification/unread`, 'GET', null, {
				Authorization: `Bearer ${token}`,
			})

			setReady(true)

			return doc
		} catch (err) {
			console.log(err)
		}
	}, [token, request])

	const setReaded = useCallback(
		async (id = 'all') => {
			try {
				const doc = await request(
					`/api/notification/readed/${id}`,
					'POST',
					null,
					{
						Authorization: `Bearer ${token}`,
					}
				)

				setReady(true)

				// console.log('notif hook', doc)

				return doc
			} catch (err) {
				console.log(err)
			}
		},
		[token, request]
	)

	return { loading, ready, getUnread, setReaded }
}
