import { useEffect } from 'react'

import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import Paper from '@mui/material/Paper'
import { pink, blue } from '@mui/material/colors'
import Radio from '@mui/material/Radio'
import { usePlan } from '../../../hooks/plan.hook'

export default function StepPlans({
	// rows,
	investmentData,
	enableNextButton,
}) {
	const { fetchPlans, plans } = usePlan()

	const handleChange = event => {
		enableNextButton(event.target.value)
	}

	useEffect(() => {
		fetchPlans()
	}, [])

	return (
		<>
			<TableContainer component={Paper} className='md:hidden'>
				<Table aria-label='simple table'>
					<TableHead>
						<TableRow>
							<TableCell></TableCell>
							<TableCell>Plan</TableCell>
							<TableCell>Min - Max</TableCell>
						</TableRow>
					</TableHead>
					<TableBody>
						{plans.map(obj => (
							<TableRow
								key={obj._id}
								sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
								className={investmentData.plan === obj._id ? 'bg-gray-100' : ''}
							>
								<TableCell align='left'>
									<Radio
										checked={investmentData.plan === obj._id}
										onChange={handleChange}
										value={obj._id}
										name='color-radio-button-demo'
										sx={{
											color: blue[800],
											'&.Mui-checked': {
												color: blue[600],
											},
										}}
									/>
								</TableCell>
								<TableCell component='th' scope='row'>
									<p>{obj.name}</p>
									<p>{obj.percentDaily}% per day</p>
									<p>for {obj.termDays} days</p>
								</TableCell>
								<TableCell>
									${obj.amountMin} - ${obj.amountMax}
								</TableCell>
							</TableRow>
						))}
					</TableBody>
				</Table>
			</TableContainer>

			<TableContainer component={Paper} className='hidden md:block'>
				<Table sx={{ minWidth: 650 }} aria-label='simple table'>
					<TableHead>
						<TableRow>
							<TableCell></TableCell>
							<TableCell>Title</TableCell>
							<TableCell align='right'>Daily</TableCell>
							<TableCell align='right'>Term</TableCell>
							<TableCell align='right'>Min Amount</TableCell>
							<TableCell align='right'>Max amount</TableCell>
						</TableRow>
					</TableHead>
					<TableBody>
						{plans.map(obj => (
							<TableRow
								key={obj._id}
								sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
								className={investmentData.plan === obj._id ? 'bg-gray-100' : ''}
							>
								<TableCell align='left'>
									<Radio
										checked={investmentData.plan === obj._id}
										onChange={handleChange}
										value={obj._id}
										name='color-radio-button-demo'
										sx={{
											color: blue[800],
											'&.Mui-checked': {
												color: blue[600],
											},
										}}
									/>
								</TableCell>
								<TableCell component='th' scope='row'>
									{obj.name}
								</TableCell>
								<TableCell align='right'>{obj.percentDaily}%</TableCell>
								<TableCell align='right'>{obj.termDays} days</TableCell>
								<TableCell align='right'>${obj.amountMin}</TableCell>
								<TableCell align='right'>${obj.amountMax}</TableCell>
							</TableRow>
						))}
					</TableBody>
				</Table>
			</TableContainer>
		</>
	)
}
