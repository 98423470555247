import { useCallback, useContext, useEffect, useState } from 'react'

import { useHttp } from '../http.hook'
import { AuthContext } from '../../context/AuthContext'

export const useIncome = () => {
	const { loading, request } = useHttp()
	const { token } = useContext(AuthContext)

	const [ready, setReady] = useState(false)

	const [incomeList, setIncomeList] = useState([])

	const getIncome = useCallback(async () => {
		try {
			const doc = await request(`/api/admin/income`, 'GET', null, {
				Authorization: `Bearer ${token}`,
			})
			setReady(true)
			setIncomeList(doc)
		} catch (err) {
			console.log(err)
		}
	}, [token, request])

	return {
		loading,
		ready,
		getIncome,
		incomeList,
	}
}
