import React, { useContext, useEffect, useState } from 'react'

import { AppContext } from '../../context/AppContext'
import { AuthContext } from '../../context/AuthContext'

import { useReferral } from '../../hooks/referral.hook'
import { useIncome } from '../../hooks/income.hook'

import { currencyFormatUSD } from '../../utils/currency'

import CopyButton from '../../components/CopyButton'
import LoaderLinear from '../../components/LoaderLinear'

const Overview = () => {
	const { userId } = useContext(AuthContext)
	const {
		siteUri,
		referral1LineProfit,
		referral2LineProfit,
		referral3LineProfit,
	} = useContext(AppContext)

	const [totalEarned, setTotalEarned] = useState(0)

	const { loading, ready, getReferralCount, referralCount } = useReferral()

	const { getSumRefIncomeByUserId } = useIncome()

	useEffect(() => {
		getReferralCount()
		getSumRefIncomeByUserId().then(res => setTotalEarned(res))
	}, [])

	const stats = [
		{
			id: 1,
			name: `Referrals I lvl (${referral1LineProfit}%)`,
			value: referralCount[0],
		},
		{
			id: 2,
			name: `Referrals II lvl (${referral2LineProfit}%)`,
			value: referralCount[1],
		},
		{
			id: 3,
			name: `Referrals III lvl (${referral3LineProfit}%)`,
			value: referralCount[2],
		},
		{
			id: 4,
			name: `Total earned`,
			value: currencyFormatUSD(totalEarned),
		},
	]

	if (loading || !ready) return <LoaderLinear />

	return (
		<div className='container my-6 mx-auto md:px-6'>
			<div className='bg-white py-1 sm:py-3'>
				<div className='mx-auto max-w-7xl px-6 lg:px-8'>
					<dl className='grid grid-cols-2 sm:grid-cols-4 gap-x-8 gap-y-16 text-center'>
						{stats.map(stat => (
							<div
								key={stat.id}
								className='mx-auto flex max-w-xs flex-col gap-y-4'
							>
								<dt className='text-base leading-7 text-gray-600'>
									{stat.name}
								</dt>
								<dd className='order-first text-3xl font-semibold tracking-tight text-gray-900'>
									{stat.value}
								</dd>
							</div>
						))}
					</dl>
				</div>
			</div>

			<h2 className='mt-6 p-3 text-2xl text-red-600 text-center border-red-600 rounded rounded-xl'>
				Affiliate link:
				<CopyButton
					name='Click to copy!'
					value={`${siteUri}/register/${userId}`}
					onCopyMessage='Referral link copied'
				/>
			</h2>
		</div>
	)
}

export default Overview
