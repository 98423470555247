import * as React from 'react'
import { CopyToClipboard } from 'react-copy-to-clipboard'

import { useMessage } from '../../../hooks/message.hook'

import Button from '@mui/material/Button'
import Switch from '@mui/material/Switch'
import TableCell from '@mui/material/TableCell'
import TableRow from '@mui/material/TableRow'
import ContentCopyIcon from '@mui/icons-material/ContentCopy'
import Tooltip from '@mui/material/Tooltip'
import CopyButton from '../../../components/CopyButton'
import { NavLink } from 'react-router-dom'

const PlanRow = ({ row, handleRemovePlan }) => {
	const message = useMessage()

	return (
		<TableRow hover role='checkbox' tabIndex={-1}>
			<TableCell component='th' scope='row'>
				<CopyButton
					name={row._id.substring(20)}
					value={row._id}
					onCopyMessage='Plan ID copied.'
					tooltip={row._id}
				/>
			</TableCell>

			<TableCell align='center'>
				<NavLink to={`/admin/plan/${row._id}`}>{row.name}</NavLink>
			</TableCell>
			<TableCell align='center'>{row.percentDaily}%</TableCell>
			<TableCell align='center'>{row.termDays} days</TableCell>
			<TableCell align='center'>${row.amountMin}</TableCell>
			<TableCell align='center'>${row.amountMax}</TableCell>
			<TableCell align='center'>{row.displayOrder}</TableCell>
			<TableCell align='center'>
				<Switch checked={row.status} disabled />
			</TableCell>
			<TableCell align='center'>
				<Button onClick={() => handleRemovePlan(row._id)} color='error'>
					Delete
				</Button>
			</TableCell>
		</TableRow>
	)
}

export default PlanRow
