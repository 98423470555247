function shuffle(array) {
	let currentIndex = array.length,
		randomIndex

	// While there remain elements to shuffle.
	while (currentIndex > 0) {
		// Pick a remaining element.
		randomIndex = Math.floor(Math.random() * currentIndex)
		currentIndex--

		// And swap it with the current element.
		;[array[currentIndex], array[randomIndex]] = [
			array[randomIndex],
			array[currentIndex],
		]
	}

	return array
}

function randomArray(arr, len) {
	arr = shuffle(arr)

	arr.sort(function () {
		return Math.random() > 0.5
	})
	arr.length = len
	return arr
}

export { shuffle, randomArray }
