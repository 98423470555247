import React, { useContext, useEffect } from 'react'

import { AuthContext } from '../../../context/AuthContext'

import currencyFormatUSD from '../../../utils/currencyFormatUSD'

import Typography from '@mui/material/Typography'
import { useBalance } from '../../../hooks/balance.hook'

import Button from '@mui/material/Button'
import Menu from '@mui/material/Menu'
import MenuItem from '@mui/material/MenuItem'
import {
	Box,
	ButtonGroup,
	List,
	ListItem,
	ListItemText,
	MenuList,
} from '@mui/material'

import CommentIcon from '@mui/icons-material/Comment'
import IconButton from '@mui/material/IconButton'
import { NavLink } from 'react-router-dom'

const Balance = () => {
	const { isAuth, setBalance, setReal, setBonus, balance, real, bonus } =
		useContext(AuthContext)

	const { getBalanceByUserId } = useBalance()

	const [anchorEl, setAnchorEl] = React.useState(null)
	const open = Boolean(anchorEl)
	const handleClick = event => {
		setAnchorEl(event.currentTarget)
	}
	const handleClose = () => {
		setAnchorEl(null)
	}

	useEffect(() => {
		if (isAuth) {
			getBalanceByUserId().then(res => {
				setBalance(res?.real + res?.bonus)
				setReal(res?.real)
				setBonus(res?.bonus)
			})

			const timerId = setInterval(() => {
				getBalanceByUserId().then(res => {
					setBalance(res?.real + res?.bonus)
					setReal(res?.real)
					setBonus(res?.bonus)
				})
			}, 30000)

			return () => clearInterval(timerId)
		}
	}, [isAuth])

	return (
		<>
			<Typography
				component='span'
				className='cursor-pointer'
				sx={{ marginRight: 1 }}
				aria-controls={open ? 'basic-menu' : undefined}
				aria-haspopup='true'
				aria-expanded={open ? 'true' : undefined}
				onClick={handleClick}
			>
				{currencyFormatUSD(balance)}
			</Typography>
			<Menu
				id='basic-menu'
				anchorEl={anchorEl}
				open={open}
				onClose={handleClose}
				MenuListProps={{
					'aria-labelledby': 'basic-button',
				}}
			>
				<List sx={{ width: '100%', minWidth: 180 }}>
					<ListItem secondaryAction={currencyFormatUSD(real)}>
						<ListItemText primary='Real' />
					</ListItem>
					<ListItem secondaryAction={currencyFormatUSD(bonus)}>
						<ListItemText primary='Bonus' />
					</ListItem>
				</List>

				<ButtonGroup variant='outlined' fullWidth size='small' sx={{ mx: 1 }}>
					<NavLink to='/bank/deposit' onClick={handleClose}>
						<Button color='success'>Deposit</Button>
					</NavLink>
					<NavLink to='/bank/withdrawal' onClick={handleClose}>
						<Button color='error'>Withdraw</Button>
					</NavLink>
				</ButtonGroup>
			</Menu>
		</>
	)
}

export default Balance
