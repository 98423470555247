import { Box, Button, Typography } from '@mui/material'
import React, { useState } from 'react'
import { useEmail } from '../hooks/email.hook'
import { isEmail } from '../utils/validators'

const ContactUsBlock = () => {
	const [formData, setFormData] = useState({ name: '', email: '', message: '' })
	const [errors, setErrors] = useState({})

	const [sentSuccessful, setSentSuccessful] = useState(false)

	// const supportEmail = 'support@dominicash.com'
	const supportEmail = 'dominicash.com@gmail.com'

	const { sendEmail } = useEmail()

	// const isEmail = email =>
	// 	/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(email)

	const sendHandler = () => {
		const errors = {}

		if (!formData.name) {
			errors.name = 'Enter name!'
		}

		if (!formData.email) {
			errors.email = 'Enter email!'
		}

		if (!isEmail(formData.email)) {
			errors.email = 'Wrong email'
		}

		if (!formData.message) {
			errors.message = 'Enter message!'
		}

		setErrors(errors)

		if (!Object.keys(errors).length) {
			console.log(JSON.stringify(formData, null, 2))

			sendEmail(formData.email, supportEmail, formData.name, formData.message)

			setSentSuccessful(true)
		}
	}

	return (
		// Tailwind Play: https://play.tailwindcss.com/qIqvl7e7Ww

		<div className='flex min-h-screen items-center justify-start bg-gray-700'>
			<div className='mx-auto w-full max-w-lg bg-gray-900 p-3 rounded border-2 border-white text-white'>
				{!sentSuccessful ? (
					<>
						<h1 className='text-4xl font-medium'>Contact us</h1>
						<p className='mt-3'>
							Email us at support@dominicash.com or message below:
						</p>

						<div className='mt-10'>
							<div className='grid gap-6 sm:grid-cols-2'>
								<div className='relative z-0'>
									<input
										type='text'
										name='name'
										value={formData.name}
										onChange={e =>
											setFormData({ ...formData, name: e.target.value })
										}
										className='peer block w-full appearance-none border-0 border-b border-gray-500 bg-transparent py-2.5 px-0 text-sm text-white focus:border-blue-600 focus:outline-none focus:ring-0'
										placeholder=' '
									/>
									<label className='absolute top-3 -z-10 origin-[0] -translate-y-6 scale-75 transform text-sm text-gray-500 duration-300 peer-placeholder-shown:translate-y-0 peer-placeholder-shown:scale-100 peer-focus:left-0 peer-focus:-translate-y-6 peer-focus:scale-75 peer-focus:text-blue-600 peer-focus:dark:text-blue-500'>
										Your name
									</label>
									{errors.name ? (
										<span className='text-red-600'>{errors.name}</span>
									) : (
										''
									)}
								</div>
								<div className='relative z-0'>
									<input
										type='text'
										name='email'
										value={formData.email}
										onChange={e =>
											setFormData({ ...formData, email: e.target.value })
										}
										className='peer block w-full appearance-none border-0 border-b border-gray-500 bg-transparent py-2.5 px-0 text-sm text-white focus:border-blue-600 focus:outline-none focus:ring-0'
										placeholder=' '
									/>
									<label className='absolute top-3 -z-10 origin-[0] -translate-y-6 scale-75 transform text-sm text-gray-500 duration-300 peer-placeholder-shown:translate-y-0 peer-placeholder-shown:scale-100 peer-focus:left-0 peer-focus:-translate-y-6 peer-focus:scale-75 peer-focus:text-blue-600 peer-focus:dark:text-blue-500'>
										Your email
									</label>
									{errors.email ? (
										<span className='text-red-600'>{errors.email}</span>
									) : (
										''
									)}
								</div>
								<div className='relative z-0 col-span-2'>
									<textarea
										name='message'
										value={formData.message}
										onChange={e =>
											setFormData({ ...formData, message: e.target.value })
										}
										rows='5'
										className='peer block w-full appearance-none border-0 border-b border-gray-500 bg-transparent py-2.5 px-0 text-sm text-white focus:border-blue-600 focus:outline-none focus:ring-0'
										placeholder=' '
									></textarea>
									<label className='absolute top-3 -z-10 origin-[0] -translate-y-6 scale-75 transform text-sm text-gray-500 duration-300 peer-placeholder-shown:translate-y-0 peer-placeholder-shown:scale-100 peer-focus:left-0 peer-focus:-translate-y-6 peer-focus:scale-75 peer-focus:text-blue-600 peer-focus:dark:text-blue-500'>
										Your message
									</label>
									{errors.message ? (
										<span className='text-red-600'>{errors.message}</span>
									) : (
										''
									)}
								</div>
							</div>
							<button
								className='w-full mt-5 rounded-md bg-black px-10 py-2 border border-gray-400 hover:border-white text-gray-400 hover:text-white'
								onClick={sendHandler}
							>
								Send Message
							</button>
						</div>
					</>
				) : (
					<>
						<Typography variant='h4' mt={2} className='text-white text-center'>
							Message sent.
						</Typography>
						<Typography variant='h4' className='text-gray-400 text-center'>
							Thank you!
						</Typography>
						<Box className='mt-5 mx-auto'>
							<button
								className='w-full mt-5 rounded-md bg-black px-10 py-2 border border-gray-400 hover:border-white text-gray-400 hover:text-white'
								onClick={() => {
									setSentSuccessful(false)
									setFormData({ name: '', email: '', message: '' })
								}}
							>
								OK
							</button>
						</Box>
					</>
				)}
			</div>
		</div>
	)
}

export default ContactUsBlock
