import React, { useContext, useEffect, useState } from 'react'

import { AuthContext } from '../../context/AuthContext'

import { useBalance } from '../../hooks/balance.hook'
import { useIP } from '../../hooks/ip.hook'
import { useMessage } from '../../hooks/message.hook'
import { usePayment } from '../../hooks/payment.hook'

import currencyFormatUSD from '../../utils/currencyFormatUSD'

import PaymentHistory from './components/PaymentHistory'

import Typography from '@mui/material/Typography'
import { AppContext } from '../../context/AppContext'
import LoaderLinear from '../../components/LoaderLinear'
import { Alert } from '@mui/material'
import { usePayeer } from '../../hooks/payeer.hook'

const DepositPage = () => {
	const auth = useContext(AuthContext)
	const app = useContext(AppContext)

	const { createPayment } = usePayeer()

	const message = useMessage()

	const { ipAddress } = useIP()

	const { getBalanceByUserId } = useBalance()

	const [amount, setAmount] = useState(10)

	const {
		loading,
		ready,
		insertDeposit,
		getDepositByUserId,
		getSumDepositByUserId,
		depositList,
		sumDeposit,
	} = usePayment()

	const formSubmit = event => {
		event.preventDefault()

		if (amount < app.depositMinAmount)
			return message(
				`Минимальная сумма депозита $${app.depositMinAmount}.`,
				'error'
			)

		insertDeposit(amount, ipAddress).then(res => {
			// message(res.message, res.type)
			getBalanceByUserId().then(res => {
				auth.setBalance(res.real + res.bonus)
				auth.setReal(res.real)
				auth.setBonus(res.bonus)
			})
			getDepositByUserId()
			getSumDepositByUserId()

			createPayment(amount, res._id, res.owner).then(res =>
				window.location.replace(res.redirectUri)
			)

			// createPayment(amount, res._id, res.owner).then(res => console.log(res))
		})
	}

	useEffect(() => {
		getBalanceByUserId().then(res => {
			auth.setBalance(res.real + res.bonus)
			auth.setReal(res.real)
			auth.setBonus(res.bonus)
		})
		getDepositByUserId()
		getSumDepositByUserId()

		// getIpAddress().then(res => setIpAddress(res))
	}, [])

	if (loading || !ready) return <LoaderLinear />

	// console.log('depositList', depositList)

	return (
		<main className='text-center mx-auto'>
			<div className='flex flex-col max-w-full md:h-56 bg-white shadow-lg overflow-hidden md:flex-row'>
				<div className='md:flex items-center justify-center md:w-1/2 md:bg-gray-700'>
					<div className='py-6 px-8 md:py-0'>
						<h2 className='text-gray-700 text-2xl font-bold md:text-gray-100'>
							{currencyFormatUSD(auth.real)}
						</h2>
						<p className='mt-2 text-gray-600 md:text-gray-400'>
							Current Balance
						</p>
					</div>
				</div>
				<div className='flex items-center justify-center pb-6 md:py-0 md:w-1/2 md:border-y-8 border-gray-700'>
					<form onSubmit={formSubmit}>
						<Typography variant='h6' gutterBottom>
							Enter amount to deposit
						</Typography>
						<div className='flex flex-col rounded-lg overflow-hidden sm:flex-row max-w-sm px-3 mx-auto'>
							<input
								className='w-full py-3 px-4 bg-gray-200 text-gray-800 border-gray-300 border-2 outline-none placeholder-gray-500 focus:bg-gray-100'
								type='number'
								name='amount'
								value={amount}
								onChange={e => setAmount(e.target.value)}
								placeholder='Min. deposit: $10'
							/>
							<button className='py-3 px-4 bg-gray-700 text-gray-100 font-semibold uppercase hover:bg-gray-600'>
								Submit
							</button>
						</div>

						{depositList.length === 1 && (
							<Alert severity='info' className='m-3'>
								For your first deposit you will be awarded 30% bonuses from the
								payment amount
							</Alert>
						)}
					</form>
				</div>
				<div className='md:flex items-center justify-center md:w-1/2 md:bg-gray-700'>
					<div className='py-6 px-8 md:py-0'>
						<h2 className='text-gray-700 text-2xl font-bold md:text-gray-100'>
							{currencyFormatUSD(sumDeposit)}
						</h2>
						<p className='mt-2 text-gray-600 md:text-gray-400'>
							Total Payments
						</p>
					</div>
				</div>
			</div>

			<div className='mt-6 p-3 grid gap-y-3 rounded'>
				<Typography variant='h4' gutterBottom>
					History
				</Typography>
				<PaymentHistory rows={depositList} />
			</div>
		</main>
	)
}

export default DepositPage
