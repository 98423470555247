import React, { useCallback, useEffect, useRef, useState } from 'react'
import ReCAPTCHA from 'react-google-recaptcha'
import { useParams } from 'react-router-dom'

import { useHttp } from '../../hooks/http.hook'
import { useMessage } from '../../hooks/message.hook'

import PageLayout from '../../layout/PageLayout'

import Button from '@mui/material/Button'
import Paper from '@mui/material/Paper'
import TextField from '@mui/material/TextField'
import Typography from '@mui/material/Typography'

import styles from './LoginPage.module.scss'
import EditInput from '../../components/EditInput'
import { Alert } from '@mui/material'

const VerifyPage = () => {
	const { request } = useHttp()

	const { userId, shortId } = useParams()

	const [ready, setReady] = useState(false)
	const [result, setResult] = useState({ message: '', type: '' })

	const fetchVerify = useCallback(async () => {
		try {
			const doc = await request(
				`/api/auth/verify/checkCode/${userId}/${shortId}`
			)

			setResult(doc)

			setReady(true)
		} catch (err) {
			console.log(err)
		}
	}, [request])

	useEffect(() => {
		fetchVerify()
	}, [])

	if (!ready) return 'Loading...'

	return (
		<PageLayout>
			<div className='bg-gray-700'>
				<main id='content' role='main' className='w-full max-w-md mx-auto p-6'>
					<div className='mt-7 bg-white  rounded-xl shadow-lg dark:bg-gray-800 dark:border-gray-700'>
						<div className='p-4 sm:p-7'>
							<div className='text-center'>
								<h1 className='block text-2xl font-bold text-gray-800 dark:text-white'>
									Email Verification
								</h1>
								<p className='mt-2 text-sm text-gray-600 dark:text-gray-400'>
									<Alert severity={result.type}>{result.message}</Alert>
								</p>
							</div>
						</div>
					</div>
				</main>
			</div>
		</PageLayout>
	)
}

export default VerifyPage
