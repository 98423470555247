import { useCallback, useContext, useEffect, useState } from 'react'

import { useHttp } from '../http.hook'
import { AuthContext } from '../../context/AuthContext'

export const useUser = () => {
	const { loading, request } = useHttp()
	const { token } = useContext(AuthContext)

	const [ready, setReady] = useState(false)

	const getUser = useCallback(
		async id => {
			try {
				const doc = await request(`/api/admin/user/${id}`, 'GET', null, {
					Authorization: `Bearer ${token}`,
				})

				// console.log('admin user hook', doc)

				setReady(true)

				return doc
			} catch (err) {
				console.log(err)
			}
		},
		[token, request]
	)

	const returnMoney = useCallback(
		async (paymentId, userId, amount) => {
			try {
				const doc = await request(
					`/api/admin/user/returnMoney`,
					'POST',
					{ paymentId, userId, amount },
					{
						Authorization: `Bearer ${token}`,
					}
				)

				// console.log('admin user hook', doc)

				setReady(true)

				return doc
			} catch (err) {
				console.log(err)
			}
		},
		[token, request]
	)

	const getUsersCount = useCallback(async () => {
		try {
			const doc = await request(`/api/admin/user/usersCount`, 'GET', null, {
				Authorization: `Bearer ${token}`,
			})

			setReady(true)

			return doc
		} catch (err) {
			console.log(err)
		}
	}, [token, request])

	const getUsersActivatedCount = useCallback(async () => {
		try {
			const doc = await request(
				`/api/admin/user/usersActivatedCount`,
				'GET',
				null,
				{
					Authorization: `Bearer ${token}`,
				}
			)

			setReady(true)

			return doc
		} catch (err) {
			console.log(err)
		}
	}, [token, request])

	const getUsersVerifiedCount = useCallback(async () => {
		try {
			const doc = await request(
				`/api/admin/user/usersVerifiedCount`,
				'GET',
				null,
				{
					Authorization: `Bearer ${token}`,
				}
			)

			setReady(true)

			return doc
		} catch (err) {
			console.log(err)
		}
	}, [token, request])

	const getUsersBlockedCount = useCallback(async () => {
		try {
			const doc = await request(
				`/api/admin/user/usersBlockedCount`,
				'GET',
				null,
				{
					Authorization: `Bearer ${token}`,
				}
			)

			setReady(true)

			return doc
		} catch (err) {
			console.log(err)
		}
	}, [token, request])

	const getRegisteredTodayCount = useCallback(async () => {
		try {
			const doc = await request(
				`/api/admin/user/registeredTodayCount`,
				'GET',
				null,
				{
					Authorization: `Bearer ${token}`,
				}
			)

			setReady(true)

			return doc
		} catch (err) {
			console.log(err)
		}
	}, [token, request])

	const setAccountActivated = useCallback(
		async (id, value) => {
			try {
				const doc = await request(
					`/api/admin/user/accountActivated/${id}`,
					'POST',
					{ value },
					{
						Authorization: `Bearer ${token}`,
					}
				)

				setReady(true)

				return doc
			} catch (err) {
				console.log(err)
			}
		},
		[token, request]
	)

	const setAccountVerified = useCallback(
		async (id, value) => {
			try {
				const doc = await request(
					`/api/admin/user/accountVerified/${id}`,
					'POST',
					{ value },
					{
						Authorization: `Bearer ${token}`,
					}
				)

				setReady(true)

				return doc
			} catch (err) {
				console.log(err)
			}
		},
		[token, request]
	)

	const setAccountBlocked = useCallback(
		async (id, value) => {
			try {
				const doc = await request(
					`/api/admin/user/accountBlocked/${id}`,
					'POST',
					{ value },
					{
						Authorization: `Bearer ${token}`,
					}
				)

				setReady(true)

				return doc
			} catch (err) {
				console.log(err)
			}
		},
		[token, request]
	)

	return {
		loading,
		ready,
		returnMoney,
		getUser,
		getUsersCount,
		getUsersActivatedCount,
		getUsersVerifiedCount,
		getUsersBlockedCount,
		getRegisteredTodayCount,
		setAccountActivated,
		setAccountVerified,
		setAccountBlocked,
	}
}
