import React, { useEffect } from 'react'
import { CopyToClipboard } from 'react-copy-to-clipboard'

import { AuthContext } from '../../../context/AuthContext'
import { useHttp } from '../../../hooks/http.hook'
import { useMessage } from '../../../hooks/message.hook'
import currencyFormatUSD from '../../../utils/currencyFormatUSD'

import ContentCopyIcon from '@mui/icons-material/ContentCopy'
import Button from '@mui/material/Button'
import FormControl from '@mui/material/FormControl'
import MenuItem from '@mui/material/MenuItem'
import Select from '@mui/material/Select'
import TableCell from '@mui/material/TableCell'
import TableRow from '@mui/material/TableRow'
import Tooltip from '@mui/material/Tooltip'
import Typography from '@mui/material/Typography'
import LoaderLinear from '../../../components/LoaderLinear'
import { useUser } from '../../../hooks/admin/user.hook'
import CopyButton from '../../../components/CopyButton'

const PaymentRow = ({ row }) => {
	const message = useMessage()

	// console.log('row', row)

	const { loading, request } = useHttp()
	const { token } = React.useContext(AuthContext)

	const { returnMoney } = useUser()

	const changestatus = React.useCallback(
		async (paymentId, status) => {
			try {
				const doc = await request(
					`/api/admin/payment/${paymentId}`,
					'PATCH',
					{ status },
					{
						Authorization: `Bearer ${token}`,
					}
				)

				if (!doc.nModified) return message('Ошибка изменения статуса', 'error')

				if (doc.nModified) {
					message('Статус изменён', 'success')
					row.status = status
				}

				// console.log(status)
			} catch (err) {
				console.log(err)
			}
		},
		[token, request]
	)

	const handleStatusChange = event => {
		changestatus(event.target.name, event.target.value)
	}

	const returnMoneyHandler = (paymentId, userId, amount) => {
		// console.log(paymentId, userId, amount)
		returnMoney(paymentId, userId, amount).then(res => console.log('res', res))
		row.returned = true
	}

	// useEffect(() => {
	// 	console.log(row)
	// }, [row])

	// if (!row) return <LoaderCircular />

	return (
		<TableRow
			hover
			role='checkbox'
			// aria-checked={isItemSelected}
			tabIndex={-1}
			key={row._id}
		>
			<TableCell component='th' id={row._id} scope='row'>
				<CopyButton
					name={row._id.substring(20)}
					value={row._id}
					onCopyMessage='Payment ID copied.'
					tooltip={row._id}
				/>
			</TableCell>

			{/* // 1 - New, 2 - In process, 3 - Successful, 4 - Failed, 5 - Canceled */}

			<TableCell align='center'>
				{row.type === 1 ? 'Deposit' : 'Withdraw'}
			</TableCell>
			<TableCell align='center'>
				{currencyFormatUSD(row.amount)}

				{row.returned === false && (row.status === 4 || row.status === 5) ? (
					<button
						onClick={() =>
							returnMoneyHandler(row._id, row.owner._id, row.amount)
						}
					>
						Return
					</button>
				) : (
					''
				)}
			</TableCell>
			<TableCell align='center'>{row.createdAt}</TableCell>
			<TableCell align='center'>
				<CopyButton
					name={row.owner._id.substring(20)}
					value={row.owner._id}
					onCopyMessage='Referrer ID copied.'
					tooltip={row.owner._id}
				/>

				<Typography
					variant='subtitle1'
					sx={{
						ml: 3,
						display: 'inline-block',
					}}
				>
					{row.owner.personal.username}
				</Typography>
			</TableCell>
			<TableCell align='center'>
				{currencyFormatUSD(row.owner.balance.real)}
			</TableCell>
			<TableCell align='center'>
				{loading && <LoaderLinear />}

				<FormControl fullWidth>
					<Select
						name={row._id}
						value={row.status}
						displayEmpty
						onChange={handleStatusChange}
						disabled={row.returned}
					>
						<MenuItem value={1}>New</MenuItem>
						<MenuItem value={2}>In process</MenuItem>
						<MenuItem value={3}>Successful</MenuItem>
						<MenuItem value={4}>Failed</MenuItem>
						<MenuItem value={5}>Canceled</MenuItem>
					</Select>
				</FormControl>
			</TableCell>
		</TableRow>
	)
}

export default PaymentRow
