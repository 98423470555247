import { useCallback, useContext, useState } from 'react'

import { AuthContext } from '../context/AuthContext'
import { useHttp } from './http.hook'

export const useBalance = amount => {
	const { token } = useContext(AuthContext)

	const { request } = useHttp()

	const [readyBalance, setReadyBalance] = useState(false)

	const getBalanceByUserId = useCallback(async () => {
		try {
			const doc = await request('/api/profile/balance', 'GET', null, {
				Authorization: `Bearer ${token}`,
			})

			return doc
		} catch (err) {
			console.log(err)
		}
	}, [token, request])

	const updateBalanceByUserId = useCallback(
		async amount => {
			try {
				const doc = await request(
					'/api/profile/balance',
					'POST',
					{ amount },
					{
						Authorization: `Bearer ${token}`,
					}
				)

				return { success: true }
			} catch (err) {
				console.log(err)
			}
		},
		[token, request, amount]
	)

	return {
		readyBalance,
		setReadyBalance,
		getBalanceByUserId,
		updateBalanceByUserId,
	}
}
