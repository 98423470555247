import { useCallback, useContext, useEffect, useState } from 'react'

import { useHttp } from './http.hook'
import { AuthContext } from '../context/AuthContext'

export const useIncome = () => {
	const { loading, request } = useHttp()
	const { token } = useContext(AuthContext)

	const [ready, setReady] = useState(false)

	const [incomeList, setIncomeList] = useState([])
	const [sumIncomeByUserId, setSumIncomeByUserId] = useState([])

	const [refIncomeList, setRefIncomeList] = useState([])
	const [sumRefIncomeByUserId, setSumRefIncomeByUserId] = useState([])

	const getIncomeByUserId = useCallback(async () => {
		try {
			const doc = await request(`/api/income`, 'GET', null, {
				Authorization: `Bearer ${token}`,
			})

			setReady(true)

			// console.log('income hook', doc)

			setIncomeList(doc)
		} catch (err) {
			console.log(err)
		}
	}, [token, request])

	const getSumIncomeByUserId = useCallback(async () => {
		try {
			const doc = await request(`/api/income/sumByUserId`, 'GET', null, {
				Authorization: `Bearer ${token}`,
			})

			setReady(true)

			setSumIncomeByUserId(doc)
		} catch (err) {
			console.log(err)
		}
	}, [token, request])

	const getRefIncomeByUserId = useCallback(async () => {
		try {
			const doc = await request(`/api/income/ref`, 'GET', null, {
				Authorization: `Bearer ${token}`,
			})

			setReady(true)

			setRefIncomeList(doc)
		} catch (err) {
			console.log(err)
		}
	}, [token, request])

	const getSumRefIncomeByUserId = useCallback(async () => {
		try {
			const doc = await request(`/api/income/ref/sumByUserId`, 'GET', null, {
				Authorization: `Bearer ${token}`,
			})

			return doc
		} catch (err) {
			console.log(err)
		}
	}, [token, request])

	return {
		loading,
		ready,

		getIncomeByUserId,
		incomeList,

		getSumIncomeByUserId,
		sumIncomeByUserId,

		getRefIncomeByUserId,
		refIncomeList,

		getSumRefIncomeByUserId,
		sumRefIncomeByUserId,
	}
}
