import React, { useContext, useEffect } from 'react'

import { AuthContext } from '../context/AuthContext'

import { useNotification } from '../hooks/notification.hook'

import { dateTimeFormat } from '../utils/dateTimeFormat'

import AttachMoneyIcon from '@mui/icons-material/AttachMoney'
import DeleteIcon from '@mui/icons-material/Delete'
import NotificationsNoneRoundedIcon from '@mui/icons-material/NotificationsNoneRounded'
import PersonAddIcon from '@mui/icons-material/PersonAdd'
import Avatar from '@mui/material/Avatar'
import Badge from '@mui/material/Badge'
import Box from '@mui/material/Box'
import Drawer from '@mui/material/Drawer'
import Grid from '@mui/material/Grid'
import IconButton from '@mui/material/IconButton'
import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'
import ListItemAvatar from '@mui/material/ListItemAvatar'
import ListItemText from '@mui/material/ListItemText'
import { styled } from '@mui/material/styles'
import Tooltip from '@mui/material/Tooltip'
import Typography from '@mui/material/Typography'

const Demo = styled('div')(({ theme }) => ({
	backgroundColor: theme.palette.background.paper,
}))

const NotificationDrawer = () => {
	const { notifications, setNotifications } = useContext(AuthContext)

	// console.log('notifications', notifications)

	const Notification = useNotification()

	const [state, setState] = React.useState({
		top: false,
		left: false,
		bottom: false,
		right: false,
	})

	const toggleDrawer = (anchor, open) => event => {
		if (
			event.type === 'keydown' &&
			(event.key === 'Tab' || event.key === 'Shift')
		) {
			return
		}

		setState({ ...state, [anchor]: open })
	}

	const readedOneHandler = id => {
		setNotifications(notifications.filter(obj => obj._id !== id))
		Notification.setReaded(id)
	}

	const readedAllHandler = () => {
		setNotifications(notifications.filter(obj => obj._id === 'all'))
		Notification.setReaded()
	}

	const notificationList = notifications => (
		<Box sx={{ flexGrow: 1, maxWidth: 550 }}>
			<Grid container spacing={2}>
				<Grid item xs={12}>
					<Demo>
						<List dense={true}>
							<div className='flex place-content-between'>
								<Typography
									variant='overline'
									display='block'
									gutterBottom
									className='cursor-pointer text-left pl-5'
									onClick={toggleDrawer('right', false)}
								>
									Close
								</Typography>
								<Typography
									variant='overline'
									display='block'
									gutterBottom
									className='cursor-pointer text-right pr-5'
									onClick={readedAllHandler}
								>
									Mark all as read
								</Typography>
							</div>

							{notifications?.map(obj => (
								<ListItem
									key={obj._id}
									onClick={() => readedOneHandler(obj._id)}
									secondaryAction={
										<IconButton edge='end' aria-label='delete'>
											<DeleteIcon />
										</IconButton>
									}
								>
									<ListItemAvatar>
										<Avatar>
											{obj.icon === 'AttachMoneyIcon' ? (
												<AttachMoneyIcon />
											) : (
												''
											)}
											{obj.icon === 'PersonAdd' ? <PersonAddIcon /> : ''}
										</Avatar>
									</ListItemAvatar>

									<ListItemText
										primary={`${obj.text}`}
										secondary={dateTimeFormat(obj.createdAt)}
									/>
								</ListItem>
							))}
						</List>
					</Demo>
				</Grid>
			</Grid>
		</Box>
	)

	useEffect(() => {
		Notification.getUnread().then(res => {
			setNotifications(res)
		})

		const timerId = setInterval(() => {
			Notification.getUnread().then(res => {
				setNotifications(res)
			})
		}, 31000)

		return () => {
			// console.log('NotifDrawer UNMOUNTED...')

			setTimeout(() => {
				clearInterval(timerId)
				// console.log('NotifDrawer stoped!!!')
			}, 5000)
		}
	}, [])

	useEffect(() => {
		if (!notifications?.length) setState({ ...state, right: false })
	}, [notifications])

	return (
		<Badge badgeContent={notifications?.length} color='primary' sx={{ mx: 3 }}>
			{notifications?.length ? (
				<Tooltip title='Open notifications' className='cursor-pointer'>
					<NotificationsNoneRoundedIcon onClick={toggleDrawer('right', true)} />
				</Tooltip>
			) : (
				<Tooltip title='No notifications'>
					<NotificationsNoneRoundedIcon />
				</Tooltip>
			)}

			<Drawer
				anchor='right'
				open={state['right']}
				onClose={toggleDrawer('right', false)}
			>
				{notificationList(notifications)}
			</Drawer>
		</Badge>
	)
}

export default NotificationDrawer
