import { useCallback, useContext, useState } from 'react'

import { useHttp } from './http.hook'
import { AuthContext } from '../context/AuthContext'

export const useUser = () => {
	const { token } = useContext(AuthContext)

	const { loading, request } = useHttp()
	const [ready, setReady] = useState(false)
	const [usersData, setUsersData] = useState([])

	const getBestInvestors = useCallback(async () => {
		try {
			const doc = await request('/api/user/bestInvestors')

			return doc
		} catch (err) {
			console.log(err)
		}
	}, [request])

	const getBestReferrers = useCallback(async () => {
		try {
			const doc = await request('/api/user/bestReferrers')

			return doc
		} catch (err) {
			console.log(err)
		}
	}, [request])

	const getUsersCount = useCallback(async () => {
		try {
			const doc = await request('/api/user/count')

			return doc
		} catch (err) {
			console.log(err)
		}
	}, [request])

	const getUsers = useCallback(async () => {
		try {
			const doc = await request(`/api/user/`, 'GET', null, {
				Authorization: `Bearer ${token}`,
			})

			setUsersData(doc)

			setReady(true)
		} catch (err) {
			console.log(err)
		}
	}, [token, request])

	return {
		ready,
		loading,
		getUsers,
		usersData,
		getUsersCount,
		getBestReferrers,
		getBestInvestors,
	}
}
