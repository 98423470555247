import * as React from 'react'

import { usePlan } from '../../../hooks/plan.hook'

import Container from '@mui/material/Container'
import Card from '@mui/material/Card'
import CardHeader from '@mui/material/CardHeader'
import CardContent from '@mui/material/CardContent'
import Typography from '@mui/material/Typography'
import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'
import ListItemText from '@mui/material/ListItemText'
import Grid from '@mui/material/Grid'
import currencyFormatUSD from '../../../utils/currencyFormatUSD'
import LoaderLinear from '../../../components/LoaderLinear'

export default function StepReview({ investmentData }) {
	const { loading, ready, fetchPlan } = usePlan()

	const [plan, setPlan] = React.useState({})

	React.useEffect(() => {
		fetchPlan(investmentData.plan).then(res => setPlan(res))
	}, [])

	if (loading || !ready) return <LoaderLinear />

	// console.log('Review', investmentData)
	// console.log('Review', plan)

	return (
		<Container maxWidth='sm'>
			<Card variant='outlined'>
				<CardHeader title='Review' className='bg-blue-400 text-white' />
				<CardContent sx={{ padding: '12px' }}>
					<List disablePadding>
						<ListItem sx={{ py: 1, px: 0 }}>
							<ListItemText
								primary='Selected plan'
								secondary={`$${plan.amountMin}-$${plan.amountMax}`}
							/>
							<Typography variant='subtitle1' sx={{ fontWeight: 700 }}>
								{plan.name}
							</Typography>
						</ListItem>

						<ListItem sx={{ py: 1, px: 0 }}>
							<ListItemText primary='Selected wallet' />
							<Typography variant='subtitle1' sx={{ fontWeight: 700 }}>
								{investmentData.wallet}
							</Typography>
						</ListItem>

						<ListItem sx={{ py: 1, px: 0 }}>
							<ListItemText
								primary='Investment amount'
								secondary='Payment from balance'
							/>
							<Typography variant='subtitle1' sx={{ fontWeight: 700 }}>
								${investmentData.amount}
							</Typography>
						</ListItem>
					</List>

					<Typography variant='h6' gutterBottom sx={{ mt: 2 }} align='center'>
						Investment Details
					</Typography>
					<List disablePadding>
						<ListItem sx={{ py: 1, px: 0 }}>
							<ListItemText
								primary='Daily profit'
								secondary={`${plan.percentDaily}%`}
							/>
							<Typography variant='subtitle1' sx={{ fontWeight: 700 }}>
								${(investmentData.amount * plan.percentDaily) / 100}
							</Typography>
						</ListItem>

						<ListItem sx={{ py: 1, px: 0 }}>
							<ListItemText primary='Investment term' secondary='' />
							<Typography variant='subtitle1' sx={{ fontWeight: 700 }}>
								{plan.termDays} days
							</Typography>
						</ListItem>

						<ListItem sx={{ py: 1, px: 0 }}>
							<ListItemText
								primary='Net profit'
								secondary={`${plan.percentDaily * plan.termDays}%`}
							/>
							<Typography variant='subtitle1' sx={{ fontWeight: 700 }}>
								$
								{((investmentData.amount * plan.percentDaily) / 100) *
									plan.termDays}
							</Typography>
						</ListItem>

						<ListItem
							sx={{
								mt: 3,
								pt: 1,
								px: 0,
								borderTop: '1px solid black',
							}}
						>
							<ListItemText
								primary='At the end of the term you will receive'
								secondary={
									investmentData.wallet === 'bonus'
										? 'The body of the investment will not be returned when using bonus funds'
										: 'The body of the investment will be returned at the end of the term'
								}
								sx={{ fontWeight: 700 }}
							/>
							<Typography
								variant='subtitle1'
								sx={{
									fontWeight: 700,
									color: 'red',
								}}
							>
								{currencyFormatUSD(
									((investmentData.amount * plan.percentDaily) / 100) *
										plan.termDays +
										(investmentData.wallet === 'real'
											? +investmentData.amount
											: 0)
								)}
							</Typography>
						</ListItem>
					</List>
				</CardContent>
			</Card>
		</Container>
	)
}
