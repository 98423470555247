const daysDifference = (dateStart, dateEnd) => {
	const date1 = new Date(dateStart)
	const date2 = new Date(dateEnd)

	const daysLag = Math.ceil(
		Math.abs(date2.getTime() - date1.getTime()) / (1000 * 3600 * 24)
	)

	return daysLag
}

const dateFormat = date => {
	date = new Date(date)
	return new Intl.DateTimeFormat('en-US', {
		dateStyle: 'medium',
		timeZone: 'GMT',
	}).format(date)
}

const dateTimeFormat = (date, timeZone = 'Etc/GMT') => {
	date = new Date(date)
	return new Intl.DateTimeFormat('en-US', {
		dateStyle: 'medium',
		timeStyle: 'short',
		timeZone,
	}).format(date)

	// return new Date(date).toLocaleString('en-US', {timeZone: 'Europe/Amsterdam'})
}

export { daysDifference, dateFormat, dateTimeFormat }
