import React, {
	useCallback,
	useContext,
	useEffect,
	useState,
	useRef,
} from 'react'
import CopyToClipboard from 'react-copy-to-clipboard'

import { AuthContext } from '../../context/AuthContext'
import { useHttp } from '../../hooks/http.hook'
import { useMessage } from '../../hooks/message.hook'
import { dateFormat } from '../../utils/dateTimeFormat'

import CheckIcon from '@mui/icons-material/Check'
import CloseIcon from '@mui/icons-material/Close'
import ContentCopyIcon from '@mui/icons-material/ContentCopy'
import { Avatar, CircularProgress, Tooltip } from '@mui/material'
import Button from '@mui/material/Button'
import CopyButton from '../../components/CopyButton'

export const ProfilePage = () => {
	const message = useMessage()

	const [ready, setReady] = useState(false)

	const [userData, setUserData] = useState([])
	const { loading, request } = useHttp()
	const { token } = useContext(AuthContext)

	const fetchData = useCallback(async () => {
		try {
			const doc = await request(`/api/profile`, 'GET', null, {
				Authorization: `Bearer ${token}`,
			})

			console.log('ProfilePage, fetchData, doc', doc)

			setReady(true)

			return doc
		} catch (err) {
			console.log(err)
		}
	}, [token, request])

	const filePicker = useRef(null)
	const handlePick = () => {
		filePicker.current.click()
	}

	// const [avatarUrl, setAvatarUrl] = useState('')

	// const handlerUpload = async e => {
	// 	const formData = new FormData()
	// 	formData.append('file', e.target.files[0])

	// 	const res = await fetch('/api/file/upload', {
	// 		method: 'POST',
	// 		body: formData,
	// 	})
	// 	const data = await res.json()

	// 	await request(`/api/profile/setAvatar`, 'POST', data, {
	// 		Authorization: `Bearer ${token}`,
	// 	})

	// 	setAvatarUrl(data.imgUrl)
	// }

	// const deleteAvatar = async () => {
	// 	const resultConfirm = window.confirm('Удалить аватар?')

	// 	if (resultConfirm) {
	// 		await request(`/api/profile/deleteAvatar`, 'POST', null, {
	// 			Authorization: `Bearer ${token}`,
	// 		})
	// 		setAvatarUrl('')
	// 	}
	// }

	useEffect(() => {
		fetchData().then(res => {
			setUserData(res)
			// setAvatarUrl(res.avatarUrl)
		})
	}, [fetchData])

	if (loading || !ready) {
		return <CircularProgress />
	}
	console.log('userData', userData)

	return (
		<main>
			<div className='mx-auto max-w-7xl py-6 sm:px-6 lg:px-8'>
				<form>
					<div className='space-y-12'>
						<div className='border-b border-gray-900/10 pb-12'>
							{/* <div className='col-span-full'>
								<div className='mt-2 flex items-center gap-x-3'>
									{avatarUrl ? (
										<Avatar alt='' src={avatarUrl} />
									) : (
										<Avatar>
											{userData.personal.username.substring(0, 1).toUpperCase()}
										</Avatar>
									)}

									<input
										type='file'
										name='file'
										className='hidden'
										ref={filePicker}
										onChange={handlerUpload}
									/>

									<button
										type='button'
										className='rounded-md bg-white px-2.5 py-1.5 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50'
										onClick={handlePick}
									>
										Change
									</button>
									<button
										type='button'
										className='rounded-md bg-red-500 px-2.5 py-1.5 text-sm font-semibold text-white shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-red-600'
										onClick={deleteAvatar}
									>
										Delete
									</button>
								</div>
							</div> */}

							<div className='mt-10 grid grid-cols-1 sm:grid-cols-3 gap-x-24'>
								<div>
									<div className='profile-group'>
										<div className='profile-group-label'>Profile ID</div>
										<div className='profile-group-value'>
											{userData._id}

											<CopyButton
												value={userData._id}
												onCopyMessage='Your ID copied.'
											/>
										</div>
									</div>
								</div>
								<div>
									<div className='profile-group'>
										<div className='profile-group-label'>Your referrer</div>
										<div className='profile-group-value'>
											{userData.referrer ? userData.referrer : '-'}
										</div>
									</div>
								</div>
							</div>

							{/* <div className='mt-10 grid grid-cols-3 gap-x-6 gap-y-8 sm:grid-cols-3'> */}
							<div className='mt-10 grid grid-cols-1 sm:grid-cols-3 gap-x-24'>
								<div>
									<div className='profile-group'>
										<div className='profile-group-label'>Full name</div>
										<div className='profile-group-value'>
											{userData.personal.firstName
												? userData.personal.firstName
												: '-'}{' '}
											{userData.personal.lastName
												? userData.personal.lastName
												: '-'}
										</div>
									</div>
									<div className='profile-group'>
										<div className='profile-group-label'>Username</div>
										<div className='profile-group-value'>
											{userData.personal.username
												? userData.personal.username
												: '-'}
										</div>
									</div>
									<div className='profile-group'>
										<div className='profile-group-label'>Date of Birth</div>
										<div className='profile-group-value'>
											{userData.personal.birthday
												? dateFormat(userData.personal.birthday.split('T')[0])
												: '-'}
										</div>
									</div>
								</div>

								<div>
									<div className='profile-group'>
										<div className='profile-group-label'>Email</div>
										<div className='profile-group-value'>
											{userData.contacts.email}{' '}
											{userData.account.emailVerified ? (
												<Tooltip title='Email verified'>
													<CheckIcon color='success' sx={{ ml: 1 }} />
												</Tooltip>
											) : (
												<Tooltip title='Email not verified'>
													<CloseIcon color='error' sx={{ ml: 1 }} />
												</Tooltip>
											)}
										</div>
									</div>
									<div className='profile-group'>
										<div className='profile-group-label'>Phone</div>
										<div className='profile-group-value'>
											{userData.contacts.phone ? userData.contacts.phone : '-'}
										</div>
									</div>
									<div className='profile-group'>
										<div className='profile-group-label'>Country</div>
										<div className='profile-group-value'>
											{userData.contacts.country.label
												? userData.contacts.country.label
												: '-'}
										</div>
									</div>
									<div className='profile-group'>
										<div className='profile-group-label'>Time Zone</div>
										<div className='profile-group-value'>
											{userData.contacts.timezone
												? userData.contacts.timezone
												: '-'}
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</form>
			</div>
		</main>
	)
}
