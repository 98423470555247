import { useCallback, useContext, useEffect, useState } from 'react'

import { useHttp } from './http.hook'
import { AuthContext } from '../context/AuthContext'

export const useLoggin = () => {
	const { loading, request } = useHttp()
	const { token } = useContext(AuthContext)

	const [ready, setReady] = useState(false)

	const getLogginList = useCallback(async () => {
		try {
			const doc = await request(`/api/loggin`, 'GET', null, {
				Authorization: `Bearer ${token}`,
			})

			setReady(true)

			return doc
		} catch (err) {
			console.log(err)
		}
	}, [token, request])

	const getTimeZone = useCallback(async () => {
		try {
			const doc = await request(`/api/profile/timezone`, 'GET', null, {
				Authorization: `Bearer ${token}`,
			})

			setReady(true)

			return doc
		} catch (err) {
			console.log(err)
		}
	}, [token, request])

	return {
		loading,
		ready,
		getLogginList,
		getTimeZone,
	}
}
