import { useHttp } from './http.hook'

export const usePayeer = () => {
	const { request } = useHttp()

	const createPayment = async (amount, id, owner) => {
		const data = await request('/payment_gw/payeer/create', 'POST', {
			amount,
			id,
			owner,
		})

		return data
	}

	return { createPayment }
}
