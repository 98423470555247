import { useEffect } from 'react'

import { useIncome } from '../../hooks/admin/income.hook'

import IncomesTable from './components/IncomesTable'
import LoaderLinear from '../../components/LoaderLinear'

export const AdminIncomesPage = () => {
	const { loading, ready, getIncome, incomeList } = useIncome()

	useEffect(() => {
		getIncome()
	}, [])

	if (loading || !ready) return <LoaderLinear />

	return <IncomesTable rows={incomeList} />
}
