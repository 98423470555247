import React from 'react'

import HistoryPage from './HistoryPage'
import InvestmentsPage from './InvestmentsPage'
import PlansPage from './PlansPage'

import TabContext from '@mui/lab/TabContext'
import TabList from '@mui/lab/TabList'
import TabPanel from '@mui/lab/TabPanel'
import Box from '@mui/material/Box'
import Tab from '@mui/material/Tab'
import PageLayout from '../../layout/PageLayout'

export const InvestmentPage = () => {
	const [tabValue, setTabValue] = React.useState('plans')

	const handleTabChange = (event, newValue) => {
		setTabValue(newValue)
	}

	return (
		<PageLayout>
			<TabContext value={tabValue}>
				<Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
					<TabList onChange={handleTabChange} aria-label='Investment Tabs'>
						<Tab label='Plans' value='plans' />
						<Tab label='My Investments' value='investments' />
						<Tab label='History' value='history' />
					</TabList>
				</Box>
				<TabPanel value='plans'>
					<PlansPage />
				</TabPanel>
				<TabPanel value='investments'>
					<InvestmentsPage />
				</TabPanel>
				<TabPanel value='history'>
					<HistoryPage />
				</TabPanel>
			</TabContext>
		</PageLayout>
	)
}
