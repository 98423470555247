import { useCallback, useContext, useEffect, useState } from 'react'

import { useHttp } from '../http.hook'
import { AuthContext } from '../../context/AuthContext'

export const usePlan = () => {
	const { loading, request } = useHttp()
	const { token } = useContext(AuthContext)

	const [ready, setReady] = useState(false)

	const addPlan = useCallback(
		async ({
			name,
			percentDaily,
			termDays,
			amountMin,
			amountMax,
			displayOrder,
			status,
		}) => {
			try {
				const doc = await request(
					`/api/admin/plan/`,
					'POST',
					{
						name,
						percentDaily,
						termDays,
						amountMin,
						amountMax,
						displayOrder,
						status,
					},
					{
						Authorization: `Bearer ${token}`,
					}
				)

				// console.log('doc', doc)
				// setReady(true)
				// setPlansList(doc)
			} catch (err) {
				console.log(err)
			}
		},
		[token, request]
	)

	const getPlan = useCallback(
		async id => {
			try {
				setReady(false)

				const doc = await request(`/api/admin/plan/${id}`, 'GET', null, {
					Authorization: `Bearer ${token}`,
				})

				setReady(true)

				return doc
			} catch (err) {
				console.log(err)
			}
		},
		[token, request]
	)

	const updatePlan = useCallback(
		async (id, plan) => {
			try {
				setReady(false)

				const doc = await request(
					`/api/admin/plan/${id}`,
					'POST',
					{ ...plan },
					{
						Authorization: `Bearer ${token}`,
					}
				)

				setReady(true)

				return { success: true }
			} catch (err) {
				console.log(err)
			}
		},
		[token, request]
	)

	const getPlans = useCallback(async () => {
		try {
			const doc = await request(`/api/admin/plan/`, 'GET', null, {
				Authorization: `Bearer ${token}`,
			})
			setReady(true)

			return doc
		} catch (err) {
			console.log(err)
		}
	}, [token, request])

	const removePlan = useCallback(
		async plan => {
			try {
				const doc = await request(`/api/admin/plan/${plan}`, 'DELETE', null, {
					Authorization: `Bearer ${token}`,
				})

				// console.log('doc', doc)
				// setReady(true)
				// setPlansList(doc)
			} catch (err) {
				console.log(err)
			}
		},
		[token, request]
	)

	return {
		loading,
		ready,
		addPlan,
		removePlan,
		getPlans,
		getPlan,
		updatePlan,
	}
}
