import React from 'react'
import CopyToClipboard from 'react-copy-to-clipboard'

import { useMessage } from '../hooks/message.hook'

import ContentCopyIcon from '@mui/icons-material/ContentCopy'
import { Button, Typography } from '@mui/material'
import Tooltip from '@mui/material/Tooltip'

const CopyText = ({
	name,
	value,
	onCopyMessage,
	messageType = 'info',
	tooltip = '',
}) => {
	const message = useMessage()

	return (
		<CopyToClipboard
			text={value}
			onCopy={() => message(onCopyMessage, messageType)}
		>
			<Tooltip title={tooltip} className='cursor-pointer'>
				<Typography variant='inherit'>{name}</Typography>
			</Tooltip>
		</CopyToClipboard>
	)
}

export default CopyText
