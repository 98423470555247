import React from 'react'
import ContactUsBlock from '../components/ContactUsBlock'
import PageLayout from '../layout/PageLayout'

const SupportPage = () => {
	return (
		<PageLayout> 
			<ContactUsBlock />
		</PageLayout>
	)
}

export default SupportPage
