import { useContext, useEffect, useState } from 'react'
import { NavLink } from 'react-router-dom'

import { AppContext } from '../../../context/AppContext'
import { usePlan } from '../../../hooks/plan.hook'

import { CircularProgress } from '@mui/material'

const HeaderSection = () => {
	const { loading, ready, getPercentDaily, getTermDays } = usePlan()

	const { referral1LineProfit, referral2LineProfit, referral3LineProfit } =
		useContext(AppContext)

	const [dailyProfit, setDailyProfit] = useState([])
	const [termDays, setTermDays] = useState([])

	useEffect(() => {
		getPercentDaily().then(res => setDailyProfit(res))
		getTermDays().then(res => setTermDays(res))
	}, [])

	const minPercentDaily = dailyProfit[0]
	const maxPercentDaily = dailyProfit[dailyProfit.length - 1]

	const minTermDays = termDays[0]
	const maxTermDays = termDays[termDays.length - 1]

	const daily = `from ${minPercentDaily}% to ${maxPercentDaily}%`
	const planTerms = `${minTermDays} - ${maxTermDays} days`
	const referralsProfit = `${referral1LineProfit}% - ${referral2LineProfit}% - ${referral3LineProfit}%`

	const stats = [
		{
			name: 'Daily Profit',
			value: daily,
		},
		{ name: 'Plan Term', value: planTerms },
		{ name: 'Affiliate Program', value: 'Three Levels' },
		{
			name: "Referral's profit",
			value: referralsProfit,
		},
	]

	return (
		<div className='relative isolate overflow-hidden py-9 sm:py-12 bg-gray-700'>
			<div className='mx-auto max-w-7xl px-6 lg:px-8'>
				<div className='flex'>
					<div>
						<div className='mx-auto max-w-2xl lg:mx-0'>
							<h2 className='text-4xl font-bold tracking-tight text-white sm:text-6xl'>
								Work with us
							</h2>
							<p className='mt-6 text-lg leading-8 text-gray-300'>
								Invest with us. You don't have to do anything. Just trust us and
								get profitable interest. Favorable terms of cooperation are
								available to everyone!
							</p>
						</div>
					</div>
					<div className='flex items-center gap-3'>
						<NavLink to='/login'>
							<button
								className='relative text-center h-20 w-60 transition-all duration-500
            before:absolute before:top-0 before:left-0 before:w-full before:h-full before:bg-green-400 before:transition-all
            before:duration-300 before:opacity-50 before:hover:opacity-0 before:hover:scale-50
            after:absolute after:top-0 after:left-0 after:w-full after:h-full after:opacity-0 after:transition-all after:duration-300
            after:border after:border-green-400/50 after:scale-125 after:hover:opacity-100 after:hover:scale-100'
							>
								<span className='relative text-white uppercase font-thin flex justify-center gap-1'>
									LOG IN
									<svg
										xmlns='http://www.w3.org/2000/svg'
										fill='none'
										viewBox='0 0 24 24'
										strokeWidth={1.5}
										stroke='currentColor'
										className='w-6 h-6'
									>
										<path
											strokeLinecap='round'
											strokeLinejoin='round'
											d='M7.864 4.243A7.5 7.5 0 0119.5 10.5c0 2.92-.556 5.709-1.568 8.268M5.742 6.364A7.465 7.465 0 004.5 10.5a7.464 7.464 0 01-1.15 3.993m1.989 3.559A11.209 11.209 0 008.25 10.5a3.75 3.75 0 117.5 0c0 .527-.021 1.049-.064 1.565M12 10.5a14.94 14.94 0 01-3.6 9.75m6.633-4.596a18.666 18.666 0 01-2.485 5.33'
										/>
									</svg>
								</span>
							</button>
						</NavLink>

						<NavLink to='/register'>
							<button
								className='relative text-center h-20 w-60 transition-all duration-500
            before:absolute before:top-0 before:left-0 before:w-full before:h-full before:bg-red-400 before:transition-all
            before:duration-300 before:opacity-50 before:hover:opacity-0 before:hover:scale-50
            after:absolute after:top-0 after:left-0 after:w-full after:h-full after:opacity-0 after:transition-all after:duration-300
            after:border after:border-red-400/50 after:scale-125 after:hover:opacity-100 after:hover:scale-100'
							>
								<span className='relative text-white uppercase font-thin flex justify-center gap-1'>
									REGISTER NOW
									<svg
										xmlns='http://www.w3.org/2000/svg'
										fill='none'
										viewBox='0 0 24 24'
										strokeWidth={1.5}
										stroke='currentColor'
										className='w-6 h-6'
									>
										<path
											strokeLinecap='round'
											strokeLinejoin='round'
											d='M15.75 9V5.25A2.25 2.25 0 0013.5 3h-6a2.25 2.25 0 00-2.25 2.25v13.5A2.25 2.25 0 007.5 21h6a2.25 2.25 0 002.25-2.25V15m3 0l3-3m0 0l-3-3m3 3H9'
										/>
									</svg>
								</span>
							</button>
						</NavLink>
					</div>
				</div>
				<div className='mx-auto mt-3 max-w-2xl lg:mx-0 lg:max-w-none'>
					<dl className='mt-8 grid grid-cols-1 gap-8 sm:mt-16 sm:grid-cols-2 lg:grid-cols-4 border-t border-dashed pt-14'>
						{stats.map(stat => (
							<div key={stat.name} className='flex flex-col-reverse'>
								<dt className='text-base leading-7 text-gray-300'>
									{stat.name}
								</dt>
								<dd className='text-2xl font-bold leading-9 tracking-tight text-white'>
									{stat.value.indexOf('undefined') !== -1 ? (
										<CircularProgress />
									) : (
										stat.value
									)}
								</dd>
							</div>
						))}
					</dl>
				</div>
			</div>
		</div>
	)
}

export default HeaderSection
