import React, { useEffect, useState } from 'react'
import { NavLink, useParams } from 'react-router-dom'
import { useUser } from '../../hooks/admin/user.hook'
import currencyFormatUSD from '../../utils/currencyFormatUSD'

import ContactPageIcon from '@mui/icons-material/ContactPage'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp'
import TabContext from '@mui/lab/TabContext'
import TabList from '@mui/lab/TabList'
import TabPanel from '@mui/lab/TabPanel'
import { FormControlLabel, Switch } from '@mui/material'
import Box from '@mui/material/Box'
import Collapse from '@mui/material/Collapse'
import IconButton from '@mui/material/IconButton'
import Paper from '@mui/material/Paper'
import Tab from '@mui/material/Tab'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import Typography from '@mui/material/Typography'
import PropTypes from 'prop-types'
import { useMessage } from '../../hooks/message.hook'

function createData(name, calories, fat, carbs, protein, price) {
	return {
		name,
		calories,
		fat,
		carbs,
		protein,
		price,
		history: [
			{
				date: '2020-01-05',
				customerId: '11091700',
				amount: 3,
			},
			{
				date: '2020-01-02',
				customerId: 'Anonymous',
				amount: 1,
			},
		],
	}
}

function Row(props) {
	const { row } = props
	const [open, setOpen] = React.useState(false)

	return (
		<React.Fragment>
			<TableRow sx={{ '& > *': { borderBottom: 'unset' } }}>
				<TableCell>
					<IconButton
						aria-label='expand row'
						size='small'
						onClick={() => setOpen(!open)}
					>
						{open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
					</IconButton>
				</TableCell>
				<TableCell component='th' scope='row'>
					{row.name}
				</TableCell>
				<TableCell align='right'>{row.calories}</TableCell>
				<TableCell align='right'>{row.fat}</TableCell>
				<TableCell align='right'>{row.carbs}</TableCell>
				<TableCell align='right'>{row.protein}</TableCell>
			</TableRow>
			<TableRow>
				<TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
					<Collapse in={open} timeout='auto' unmountOnExit>
						<Box sx={{ margin: 1 }}>
							<Typography variant='h6' gutterBottom component='div'>
								History
							</Typography>
							<Table size='small' aria-label='purchases'>
								<TableHead>
									<TableRow>
										<TableCell>Date</TableCell>
										<TableCell>Customer</TableCell>
										<TableCell align='right'>Amount</TableCell>
										<TableCell align='right'>Total price ($)</TableCell>
									</TableRow>
								</TableHead>
								<TableBody>
									{row.history.map(historyRow => (
										<TableRow key={historyRow.date}>
											<TableCell component='th' scope='row'>
												{historyRow.date}
											</TableCell>
											<TableCell>{historyRow.customerId}</TableCell>
											<TableCell align='right'>{historyRow.amount}</TableCell>
											<TableCell align='right'>
												{Math.round(historyRow.amount * row.price * 100) / 100}
											</TableCell>
										</TableRow>
									))}
								</TableBody>
							</Table>
						</Box>
					</Collapse>
				</TableCell>
			</TableRow>
		</React.Fragment>
	)
}

Row.propTypes = {
	row: PropTypes.shape({
		calories: PropTypes.number.isRequired,
		carbs: PropTypes.number.isRequired,
		fat: PropTypes.number.isRequired,
		history: PropTypes.arrayOf(
			PropTypes.shape({
				amount: PropTypes.number.isRequired,
				customerId: PropTypes.string.isRequired,
				date: PropTypes.string.isRequired,
			})
		).isRequired,
		name: PropTypes.string.isRequired,
		price: PropTypes.number.isRequired,
		protein: PropTypes.number.isRequired,
	}).isRequired,
}

const rows = [
	createData('Frozen yoghurt', 159, 6.0, 24, 4.0, 3.99),
	createData('Ice cream sandwich', 237, 9.0, 37, 4.3, 4.99),
	createData('Eclair', 262, 16.0, 24, 6.0, 3.79),
	createData('Cupcake', 305, 3.7, 67, 4.3, 2.5),
	createData('Gingerbread', 356, 16.0, 49, 3.9, 1.5),
]

const AdminUserPage = () => {
	const { id } = useParams()

	const message = useMessage()

	const [data, setData] = useState({})

	const {
		loading,
		ready,
		getUser,
		setAccountActivated,
		setAccountVerified,
		setAccountBlocked,
	} = useUser()

	const changeHandle = e => {
		switch (e.target.name) {
			case 'activated':
				setData({
					...data,
					account: { ...data.account, activated: e.target.checked },
				})

				setAccountActivated(id, e.target.checked).then(res =>
					message('Changed and saved succefully!', 'success')
				)
				break
			case 'verified':
				setData({
					...data,
					account: { ...data.account, verified: e.target.checked },
				})

				setAccountVerified(id, e.target.checked).then(res =>
					message('Changed and saved succefully!', 'success')
				)
				break
			case 'blocked':
				setData({
					...data,
					account: { ...data.account, blocked: e.target.checked },
				})

				setAccountBlocked(id, e.target.checked).then(res =>
					message('Changed and saved succefully!', 'success')
				)
				break
		}
	}

	useEffect(() => {
		getUser(id).then(res => setData(res))
	}, [])

	// if (loading || !ready) return 'Loading...'

	if (loading) return 'Loading...'

	if (!ready) return 'Not ready...'

	console.log('userPage: ', data)

	return (
		<TabContext value='user'>
			<Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
				<TabList variant='scrollable' scrollButtons='auto'>
					<NavLink to='/admin/users'>
						<Tab
							label='Back to Users'
							value='users'
							icon={<ContactPageIcon />}
							iconPosition='start'
						/>
					</NavLink>
					<Tab
						label='User Info'
						value='user'
						icon={<ContactPageIcon />}
						iconPosition='start'
					/>
				</TabList>
			</Box>

			<TabPanel value='user'>
				<main>
					<div className='mx-auto max-w-7xl py-6 sm:px-6 lg:px-8'>
						<div className='flex justify-between mt-3'>
							<div className='flex space-x-3'>
								<div>Real: {currencyFormatUSD(data.balance.real)}</div>
								<div>Bonus: {currencyFormatUSD(data.balance.bonus)}</div>
							</div>

							<div className='flex space-x-3'>
								<div>
									<FormControlLabel
										name='activated'
										control={<Switch color='primary' />}
										label='Activated'
										labelPlacement='start'
										checked={data.account.activated}
										onChange={changeHandle}
									/>
								</div>
								<div>
									<FormControlLabel
										name='verified'
										control={<Switch color='primary' />}
										label='Verified'
										labelPlacement='start'
										checked={data.account.verified}
										onChange={changeHandle}
									/>
								</div>
								<div>
									<FormControlLabel
										name='blocked'
										control={<Switch color='primary' />}
										label='Blocked'
										labelPlacement='start'
										checked={data.account.blocked}
										onChange={changeHandle}
									/>
								</div>
							</div>
						</div>

						<div>Investments</div>

						<TableContainer component={Paper}>
							<Table aria-label='collapsible table'>
								<TableHead>
									<TableRow>
										<TableCell />
										<TableCell>Date</TableCell>
										<TableCell align='right'>Calories</TableCell>
										<TableCell align='right'>Fat&nbsp;(g)</TableCell>
										<TableCell align='right'>Carbs&nbsp;(g)</TableCell>
										<TableCell align='right'>Protein&nbsp;(g)</TableCell>
									</TableRow>
								</TableHead>
								<TableBody>
									{rows.map(row => (
										<Row key={row.name} row={row} />
									))}
								</TableBody>
							</Table>
						</TableContainer>

						<div>Payments</div>

						<div>Incomes: 0</div>
						<div>Referrals: 0</div>
					</div>
				</main>
			</TabPanel>
		</TabContext>
	)
}

export default AdminUserPage
