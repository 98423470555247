import { useEffect, useState } from 'react'

import LoaderLinear from '../../components/LoaderLinear'
import { useInvestment } from '../../hooks/investment.hook'
import InvestmentCard from './components/InvestmentCard'
import InvestmentsTable from './components/InvestmentsTable'

import Radio from '@mui/material/Radio'
import RadioGroup from '@mui/material/RadioGroup'
import FormControlLabel from '@mui/material/FormControlLabel'
import FormControl from '@mui/material/FormControl'
import FormLabel from '@mui/material/FormLabel'

export default function InvestmentsPage() {
	const { loading, ready, getInvestmentByUserId } = useInvestment()

	const [data, setData] = useState([])

	const [filter, setFilter] = useState({ wallet: 'all', status: 'all' })

	const onChangeFilter = e => {
		setFilter({ ...filter, [e.target.name]: e.target.value })
	}

	useEffect(() => {
		getInvestmentByUserId(filter.wallet, filter.status).then(res =>
			setData(res)
		)
	}, [filter])

	if (loading || !ready) return <LoaderLinear />

	return (
		<>
			<div className='flex flex-col sm:flex-row mb-3 p-3'>
				<div className='sm:mr-auto'>
					<FormControl>
						<FormLabel>Wallet</FormLabel>
						<RadioGroup row name='wallet' onChange={e => onChangeFilter(e)}>
							<FormControlLabel
								value='all'
								checked={filter.wallet === 'all'}
								control={<Radio size='small' />}
								label='All'
							/>
							<FormControlLabel
								value='real'
								checked={filter.wallet === 'real'}
								control={<Radio size='small' />}
								label='Real'
							/>
							<FormControlLabel
								value='bonus'
								checked={filter.wallet === 'bonus'}
								control={<Radio size='small' />}
								label='Bonus'
							/>
						</RadioGroup>
					</FormControl>
				</div>

				<div className='sm:ml-auto'>
					<FormControl>
						<FormLabel>Status</FormLabel>
						<RadioGroup row name='status' onChange={e => onChangeFilter(e)}>
							<FormControlLabel
								value='all'
								checked={filter.status === 'all'}
								control={<Radio size='small' />}
								label='All'
							/>
							<FormControlLabel
								value='active'
								checked={filter.status === 'active'}
								control={<Radio size='small' />}
								label='Active'
							/>
							<FormControlLabel
								value='closed'
								checked={filter.status === 'closed'}
								control={<Radio size='small' />}
								label='Closed'
							/>
						</RadioGroup>
					</FormControl>
				</div>
			</div>
			<div className='grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-3'>
				{data.map(obj => (
					<InvestmentCard key={obj._id} obj={obj} />
				))}
			</div>
		</>
	)
}
