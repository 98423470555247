import { useContext, useEffect, useState } from 'react'

import { AuthContext } from '../../../context/AuthContext'

import { usePlan } from '../../../hooks/plan.hook'

import Container from '@mui/material/Container'
import TextField from '@mui/material/TextField'

export default function StepAmount({
	setDisabledNextButton,
	investmentData,
	setInvestmentData,
}) {
	const { fetchPlan } = usePlan()

	const [plan, setPlan] = useState({})

	const { real, bonus } = useContext(AuthContext)

	useEffect(() => {
		fetchPlan(investmentData.plan).then(res => {
			setPlan(res)
			setInvestmentData({ ...investmentData, amount: res.amountMin })
		})
	}, [])

	if (
		investmentData.amount >= plan.amountMin &&
		investmentData.amount <= plan.amountMax &&
		investmentData.amount <= (investmentData.wallet === 'real' ? real : bonus)
	) {
		setDisabledNextButton(false)
	} else {
		setDisabledNextButton(true)
	}

	const handleChange = e => {
		if (!e.target.value || e.target.value < 1) return

		if (
			e.target.value >= plan.amountMin &&
			e.target.value <= plan.amountMax &&
			investmentData.amount <= (investmentData.wallet === 'real' ? real : bonus)
		) {
			setDisabledNextButton(false)
		} else {
			setDisabledNextButton(true)
		}

		setInvestmentData({
			...investmentData,
			[e.target.name]: e.target.value,
		})
	}

	return (
		<Container maxWidth='xs' sx={{ textAlign: 'center' }}>
			<TextField
				name='amount'
				value={investmentData.amount}
				onChange={handleChange}
				type='number'
				error={
					investmentData.amount < plan.amountMin ||
					investmentData.amount > plan.amountMax ||
					investmentData.amount >
						(investmentData.wallet === 'real' ? real : bonus)
				}
				helperText={
					investmentData.amount >
					(investmentData.wallet === 'real' ? real : bonus)
						? 'Insufficient balance'
						: `Min: $${plan.amountMin}. Max: $${plan.amountMax}`
				}
				className='w-1/2'
			/>
		</Container>
	)
}
