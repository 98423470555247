import React, { useEffect, useState } from 'react'
import { NavLink } from 'react-router-dom'

import faqItems from '../../../components/FAQ/items'
import { randomArray } from '../../../utils/array'

const FaqBlock = () => {
	const [randomItems, setRandomItems] = useState([])

	useEffect(() => {
		setRandomItems(randomArray(faqItems, 3))
	}, [])

	return (
		<div className='bg-gray-700 p-4 shadow-xl py-8'>
			<h4 className='text-4xl font-bold text-white tracking-widest uppercase text-center'>
				FAQ
			</h4>
			<p className='text-center text-white text-sm mt-2'>
				Here are some of the frequently asked questions
			</p>
			<div className='space-y-12 px-2 xl:px-16 mt-12'>
				{randomItems.map((item, idx) => (
					<div key={idx} className='mt-4 flex'>
						<div>
							<div className='flex items-center h-16 border-l-4 border-white'>
								<span className='text-4xl text-white px-4'>Q.</span>
							</div>
							<div className='flex items-center h-16 border-l-4 border-white'>
								<span className='text-4xl text-white px-4'>A.</span>
							</div>
						</div>
						<div>
							<div className='flex items-center h-16'>
								<span className='text-lg text-white font-bold'>{item.q}</span>
							</div>
							<div className='flex items-center py-2'>
								<span className='text-white'>{item.a}</span>
							</div>
						</div>
					</div>
				))}
			</div>
			<div className='text-center mt-10'>
				<NavLink to='/faq'>
					<button
						className='relative text-center h-20 w-60 transition-all duration-500
            before:absolute before:top-0 before:left-0 before:w-full before:h-full before:bg-yellow-400 before:transition-all
            before:duration-300 before:opacity-20 before:hover:opacity-0 before:hover:scale-50
            after:absolute after:top-0 after:left-0 after:w-full after:h-full after:opacity-0 after:transition-all after:duration-300
            after:border after:border-yellow-400/50 after:scale-125 after:hover:opacity-100 after:hover:scale-100'
					>
						<span className='relative text-white uppercase font-thin'>
							MORE ANSWERS &rarr;
						</span>
					</button>
				</NavLink>
			</div>
		</div>
	)
}

export default FaqBlock
