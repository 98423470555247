import React, { useContext, useEffect, useRef, useState } from 'react'
import ReCAPTCHA from 'react-google-recaptcha'
import { NavLink } from 'react-router-dom'

import { AuthContext } from '../../context/AuthContext'
import { useHttp } from '../../hooks/http.hook'
import { useIP } from '../../hooks/ip.hook'
import { useMessage } from '../../hooks/message.hook'

import PageLayout from '../../layout/PageLayout'

import Button from '@mui/material/Button'
import Paper from '@mui/material/Paper'
import TextField from '@mui/material/TextField'
import Typography from '@mui/material/Typography'

import styles from './LoginPage.module.scss'

export const LoginPage = () => {
	const auth = useContext(AuthContext)
	const message = useMessage()
	const { loading, request, error, clearError } = useHttp()

	const [email, setEmail] = useState('')
	const [password, setPassword] = useState('')
	const [userIp, setUserIp] = useState('')

	const [emailError, setEmailError] = useState(false)
	const [passwordError, setPasswordError] = useState(false)

	const [emailHelperText, setEmailHelperText] = useState('')
	const [passwordHelperText, setPasswordHelperText] = useState('')

	// recaptcha
	const captchaRef = useRef(null)
	const pageRef = 'LoginPage'

	const ip = useIP()

	const userAgent = navigator.userAgent

	useEffect(() => {
		message(error, 'error')
		clearError()
	}, [error, message, clearError])

	useEffect(() => {
		setUserIp(ip.ipAddress)
	}, [ip.ipAddress])

	const loginHandler = async () => {
		try {
			// recaptcha
			const captchaToken = captchaRef.current.getValue()
			captchaRef.current.reset()
			const captchaData = await request('/api/recaptcha', 'POST', {
				captchaToken,
				pageRef,
			})
			// if (!captchaData.success) return message(captchaData.message, 'error')

			const data = await request('/api/auth/login', 'POST', {
				email,
				password,
				userIp,
				userAgent,
			})

			// console.log('data', data)

			!data.success && message(data.message, 'error')

			const success = auth.login(data.token, data.userId, data.expiresIn)

			const errorsCount = data?.errors?.length

			if (errorsCount) {
				const errors = data?.errors?.map(el => el.param)

				if (errors.includes('email')) {
					setEmailError(true)
					setEmailHelperText('hhh')

					data?.errors?.map(el =>
						el.param === 'email' ? setEmailHelperText(el.msg) : ''
					)
				} else {
					setEmailError(false)
					setEmailHelperText('')
				}
				if (errors.includes('password')) {
					setPasswordError(true)
					setPasswordHelperText('hhh')

					data?.errors?.map(el =>
						el.param === 'password' ? setPasswordHelperText(el.msg) : ''
					)
				} else {
					setPasswordError(false)
					setPasswordHelperText('')
				}
			} else {
				setEmailError(false)
				setPasswordError(false)

				setEmailHelperText('')
				setPasswordHelperText('')

				message(data.message)

				// navigate('/login')
			}
		} catch (err) {}
	}

	return (
		<PageLayout>
			<div className='bg-gray-700'>
				<main id='content' role='main' className='w-full max-w-md mx-auto p-6'>
					<div className='mt-7 bg-white  rounded-xl shadow-lg dark:bg-gray-800 dark:border-gray-700'>
						<div className='p-4 sm:p-7'>
							<div className='text-center'>
								<h1 className='block text-2xl font-bold'>
									<span className='text-blue-600 mb-5'>Sign in</span> to account
								</h1>
							</div>

							<div className='mt-5'>
								<form>
									<div className='grid gap-y-4'>
										<div>
											<label
												htmlFor='email'
												className='block text-sm font-bold ml-1 mb-2 dark:text-white'
											>
												Email address
											</label>
											<div className='relative'>
												<input
													type='email'
													id='email'
													name='email'
													className='py-3 px-4 block w-full border-2 border-gray-200 rounded-md text-sm focus:border-blue-500 focus:ring-blue-500 shadow-sm'
													required
													aria-describedby='email-error'
													value={email}
													onChange={e => setEmail(e.target.value)}
												/>
											</div>
											<p
												className='hidden text-xs text-red-600 mt-2'
												id='email-error'
											>
												Please include a valid email address so we can get back
												to you
											</p>
										</div>
										<div>
											<label
												htmlFor='password'
												className='block text-sm font-bold ml-1 mb-2 dark:text-white'
											>
												Password
											</label>
											<div className='relative'>
												<input
													type='password'
													id='password'
													name='password'
													className='py-3 px-4 block w-full border-2 border-gray-200 rounded-md text-sm focus:border-blue-500 focus:ring-blue-500 shadow-sm'
													required
													aria-describedby='password-error'
													value={password}
													onChange={e => setPassword(e.target.value)}
												/>
											</div>
											<p
												className='hidden text-xs text-red-600 mt-2'
												id='email-error'
											>
												Please include a valid email address so we can get back
												to you
											</p>
										</div>

										<ReCAPTCHA
											ref={captchaRef}
											sitekey={process.env.REACT_APP_RECAPTCHA_SITE_KEY}
											className='mb-5'
										/>

										<button
											type='submit'
											className='py-3 px-4 inline-flex justify-center items-center gap-2 rounded-md border border-transparent font-semibold bg-green-500 text-white hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2 transition-all text-sm dark:focus:ring-offset-gray-800'
											disabled={loading}
											onClick={loginHandler}
										>
											SIGN IN
										</button>

										<div className='py-3 px-4 inline-flex justify-center items-center gap-2 rounded-md border border-transparent font-semibold text-blue-500 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2 transition-all text-sm dark:focus:ring-offset-gray-800 text-center'>
											<NavLink to='/forgot'>Forgot Password?</NavLink>
										</div>
									</div>
								</form>
							</div>
						</div>
					</div>
				</main>
			</div>
		</PageLayout>
	)

	// return (
	// 	<PageLayout>
	// 		<Paper classes={{ root: styles.root }}>
	// 			<Typography classes={{ root: styles.title }} variant='h5'>
	// 				<span className='text-blue-600 mb-5'>Sign in</span> to account
	// 			</Typography>
	// 			<TextField
	// 				className={styles.field}
	// 				label='Enter email'
	// 				error={emailError}
	// 				helperText={emailHelperText}
	// 				fullWidth
	// 				name='email'
	// 				type='email'
	// 				required
	// 				value={email}
	// 				onChange={e => setEmail(e.target.value)}
	// 			/>
	// 			<TextField
	// 				className={styles.field}
	// 				label='Enter password'
	// 				error={passwordError}
	// 				helperText={passwordHelperText}
	// 				fullWidth
	// 				name='password'
	// 				type='password'
	// 				required
	// 				value={password}
	// 				onChange={e => setPassword(e.target.value)}
	// 			/>

	// 			<ReCAPTCHA
	// 				ref={captchaRef}
	// 				sitekey={process.env.REACT_APP_RECAPTCHA_SITE_KEY}
	// 				className='mb-5'
	// 			/>

	// 			<Button
	// 				className={styles.button}
	// 				size='large'
	// 				variant='contained'
	// 				fullWidth
	// 				disabled={loading}
	// 				onClick={loginHandler}
	// 			>
	// 				Sign In
	// 			</Button>
	// 			<NavLink to='/forgot'>
	// 				<Button fullWidth>Forgot Password?</Button>
	// 			</NavLink>
	// 		</Paper>
	// 	</PageLayout>
	// )
}
