import { useContext, useEffect, useState } from 'react'

import { AppContext } from '../../../context/AppContext'

import { useInvestment } from '../../../hooks/investment.hook'
import { usePayment } from '../../../hooks/payment.hook'
import { useUser } from '../../../hooks/user.hook'

import currencyFormatUSD from '../../../utils/currencyFormatUSD'
import { daysDifference } from '../../../utils/date'
import numberFormat from '../../../utils/numberFormat'

import StatBlock from './StatBlock'

import GroupsIcon from '@mui/icons-material/Groups'
import QueryBuilderIcon from '@mui/icons-material/QueryBuilder'
import StyledHeading3 from '../../../components/StyledHeading3'

const Stats = () => {
	const [investedSum, setInvestedSum] = useState(0)
	const [withdrawalsSum, setWithdrawalsSum] = useState(0)
	const [usersCount, setUsersCount] = useState(0)

	const { getInvestedSum } = useInvestment()
	const { getWithdrawalsSum } = usePayment()
	const { getUsersCount } = useUser()

	const { siteDateStarted } = useContext(AppContext)

	const dateStarted = daysDifference(siteDateStarted, new Date())

	useEffect(() => {
		getInvestedSum().then(res => setInvestedSum(res))
		getWithdrawalsSum().then(res => setWithdrawalsSum(res))
		getUsersCount().then(res => setUsersCount(res))
	}, [])

	return (
		<div className='bg-[#121212]'>
			<div className='grid grid-cols-2 sm:grid-cols-4 py-3'>
				<div className='flex box-border p-3 items-center place-content-center w-full text-white'>
					<div>
						<svg
							xmlns='http://www.w3.org/2000/svg'
							fill='none'
							viewBox='0 0 24 24'
							strokeWidth={1.5}
							stroke='currentColor'
							className='w-12 h-12'
						>
							<path
								strokeLinecap='round'
								strokeLinejoin='round'
								d='M2.25 18.75a60.07 60.07 0 0115.797 2.101c.727.198 1.453-.342 1.453-1.096V18.75M3.75 4.5v.75A.75.75 0 013 6h-.75m0 0v-.375c0-.621.504-1.125 1.125-1.125H20.25M2.25 6v9m18-10.5v.75c0 .414.336.75.75.75h.75m-1.5-1.5h.375c.621 0 1.125.504 1.125 1.125v9.75c0 .621-.504 1.125-1.125 1.125h-.375m1.5-1.5H21a.75.75 0 00-.75.75v.75m0 0H3.75m0 0h-.375a1.125 1.125 0 01-1.125-1.125V15m1.5 1.5v-.75A.75.75 0 003 15h-.75M15 10.5a3 3 0 11-6 0 3 3 0 016 0zm3 0h.008v.008H18V10.5zm-12 0h.008v.008H6V10.5z'
							/>
						</svg>
					</div>

					<div className='ml-3'>
						<div className='text-2xl'>{currencyFormatUSD(investedSum)}</div>
						<div className='text-[#a2a3a1]'>deposit</div>
					</div>
				</div>
				<div className='flex box-border p-3 items-center place-content-center w-full text-white'>
					<div>
						<svg
							xmlns='http://www.w3.org/2000/svg'
							fill='none'
							viewBox='0 0 24 24'
							strokeWidth={1.5}
							stroke='currentColor'
							className='w-12 h-12'
						>
							<path
								strokeLinecap='round'
								strokeLinejoin='round'
								d='M2.25 18.75a60.07 60.07 0 0115.797 2.101c.727.198 1.453-.342 1.453-1.096V18.75M3.75 4.5v.75A.75.75 0 013 6h-.75m0 0v-.375c0-.621.504-1.125 1.125-1.125H20.25M2.25 6v9m18-10.5v.75c0 .414.336.75.75.75h.75m-1.5-1.5h.375c.621 0 1.125.504 1.125 1.125v9.75c0 .621-.504 1.125-1.125 1.125h-.375m1.5-1.5H21a.75.75 0 00-.75.75v.75m0 0H3.75m0 0h-.375a1.125 1.125 0 01-1.125-1.125V15m1.5 1.5v-.75A.75.75 0 003 15h-.75M15 10.5a3 3 0 11-6 0 3 3 0 016 0zm3 0h.008v.008H18V10.5zm-12 0h.008v.008H6V10.5z'
							/>
						</svg>
					</div>
					<div className='ml-3'>
						<div className='text-2xl'>{currencyFormatUSD(withdrawalsSum)}</div>
						<div className='text-[#a2a3a1]'>withdraw</div>
					</div>
				</div>
				<div className='flex box-border p-3 items-center place-content-center w-full text-white'>
					<div>
						<svg
							xmlns='http://www.w3.org/2000/svg'
							fill='none'
							viewBox='0 0 24 24'
							strokeWidth={1.5}
							stroke='currentColor'
							className='w-12 h-12'
						>
							<path
								strokeLinecap='round'
								strokeLinejoin='round'
								d='M18 18.72a9.094 9.094 0 003.741-.479 3 3 0 00-4.682-2.72m.94 3.198l.001.031c0 .225-.012.447-.037.666A11.944 11.944 0 0112 21c-2.17 0-4.207-.576-5.963-1.584A6.062 6.062 0 016 18.719m12 0a5.971 5.971 0 00-.941-3.197m0 0A5.995 5.995 0 0012 12.75a5.995 5.995 0 00-5.058 2.772m0 0a3 3 0 00-4.681 2.72 8.986 8.986 0 003.74.477m.94-3.197a5.971 5.971 0 00-.94 3.197M15 6.75a3 3 0 11-6 0 3 3 0 016 0zm6 3a2.25 2.25 0 11-4.5 0 2.25 2.25 0 014.5 0zm-13.5 0a2.25 2.25 0 11-4.5 0 2.25 2.25 0 014.5 0z'
							/>
						</svg>
					</div>
					<div className='ml-3'>
						<div className='text-2xl'>{numberFormat(usersCount)}</div>
						<div className='text-[#a2a3a1]'>members</div>
					</div>
				</div>
				<div className='flex box-border p-3 items-center place-content-center w-full text-white'>
					<div>
						<svg
							xmlns='http://www.w3.org/2000/svg'
							fill='none'
							viewBox='0 0 24 24'
							strokeWidth={1.5}
							stroke='currentColor'
							className='w-12 h-12'
						>
							<path
								strokeLinecap='round'
								strokeLinejoin='round'
								d='M6.75 3v2.25M17.25 3v2.25M3 18.75V7.5a2.25 2.25 0 012.25-2.25h13.5A2.25 2.25 0 0121 7.5v11.25m-18 0A2.25 2.25 0 005.25 21h13.5A2.25 2.25 0 0021 18.75m-18 0v-7.5A2.25 2.25 0 015.25 9h13.5A2.25 2.25 0 0121 11.25v7.5m-9-6h.008v.008H12v-.008zM12 15h.008v.008H12V15zm0 2.25h.008v.008H12v-.008zM9.75 15h.008v.008H9.75V15zm0 2.25h.008v.008H9.75v-.008zM7.5 15h.008v.008H7.5V15zm0 2.25h.008v.008H7.5v-.008zm6.75-4.5h.008v.008h-.008v-.008zm0 2.25h.008v.008h-.008V15zm0 2.25h.008v.008h-.008v-.008zm2.25-4.5h.008v.008H16.5v-.008zm0 2.25h.008v.008H16.5V15z'
							/>
						</svg>
					</div>
					<div className='ml-3'>
						<div className='text-2xl'>{numberFormat(dateStarted)}</div>
						<div className='text-[#a2a3a1]'>Days online</div>
					</div>
				</div>
			</div>
		</div>
	)
}

export default Stats
