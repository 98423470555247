import React from 'react'

import PaymentsTable from './components/PaymentsTable'

export const AdminPaymentsPage = () => {
	return (
		<main>
			<div className='mx-auto max-w-7xl py-6 sm:px-6 lg:px-8'>
				<PaymentsTable />
			</div>
		</main>
	)
}
