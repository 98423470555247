import { useState, useCallback, useEffect } from 'react'
import { redirect } from 'react-router-dom'

const storageName = 'userData'

export const useAuth = () => {
	const [ready, setReady] = useState(false)
	const [token, setToken] = useState(null)
	const [userId, setUserId] = useState(null)
	const [expiresIn, setExpiresIn] = useState(null)
	const [balance, setBalance] = useState(null)
	const [real, setReal] = useState(null)
	const [bonus, setBonus] = useState(null)
	const [notifications, setNotifications] = useState([])

	const login = useCallback((token, userId, expiresIn) => {
		setToken(token)
		setUserId(userId)
		setExpiresIn(expiresIn)

		localStorage.setItem(
			storageName,
			JSON.stringify({
				token,
				userId,
				expiresIn,
			})
		)

		setTimeout(logout, expiresIn - Date.now())
	}, [])

	const logout = useCallback(() => {
		setToken(null)
		setUserId(null)
		setExpiresIn(null)
		localStorage.removeItem(storageName)
	}, [])

	useEffect(() => {
		const data = JSON.parse(localStorage.getItem(storageName))

		if (data && data.token && data.expiresIn) {
			const tokenRemainTime = data.expiresIn - Date.now()
			const isTokenExpired = tokenRemainTime < 0 ? true : false

			console.log('tokenRemainTime', tokenRemainTime)
			console.log('isTokenExpired', isTokenExpired)

			if (isTokenExpired === true) {
				logout()
			} else {
				login(data.token, data.userId, data.expiresIn)

				setTimeout(logout, tokenRemainTime)
			}
		}

		setReady(true)
	}, [login])

	const isAdmin = userId === '654dfb45f8fe0c26edb44711'

	return {
		login,
		logout,
		token,
		userId,
		expiresIn,
		ready,
		isAdmin,
		balance,
		setBalance,
		real,
		setReal,
		bonus,
		setBonus,
		notifications,
		setNotifications,
	}
}
