import React from 'react'

import PageLayout from '../../layout/PageLayout'

import FaqBlock from './components/FaqBlock'
import HeaderSection from './components/HeaderSection'
import PlansCarousel from './components/PlansCarousel'
import Stats from './components/Stats'
import Steps from './components/Steps'

const StartPage = () => {
	return (
		<PageLayout>
			<HeaderSection />
			<Stats />
			<Steps />
			<PlansCarousel />
			<FaqBlock />
		</PageLayout>
	)
}

export default StartPage
