import { useCallback, useContext, useEffect, useState } from 'react'

import { useHttp } from './http.hook'
import { AuthContext } from '../context/AuthContext'

export const usePayment = (amount = '') => {
	const { loading, request } = useHttp()
	const { token } = useContext(AuthContext)

	const [ready, setReady] = useState(false)

	const [depositList, setDepositList] = useState([])
	const [withdrawList, setWithdrawList] = useState([])

	const [sumDeposit, setSumDeposit] = useState([])
	const [sumWithdraw, setSumWithdraw] = useState([])

	const getDepositsSum = useCallback(async () => {
		try {
			const doc = await request('/api/payment/deposits/sum')

			return doc
		} catch (err) {
			console.log(err)
		}
	}, [request])

	const getWithdrawalsSum = useCallback(async () => {
		try {
			const doc = await request('/api/payment/withdrawals/sum')

			return doc
		} catch (err) {
			console.log(err)
		}
	}, [request])

	const getDepositByUserId = useCallback(async () => {
		try {
			const doc = await request(`/api/payment/deposit`, 'GET', null, {
				Authorization: `Bearer ${token}`,
			})
			setReady(true)
			setDepositList(doc)
		} catch (err) {
			console.log(err)
		}
	}, [token, request])

	const getSumDepositByUserId = useCallback(async () => {
		try {
			const doc = await request(`/api/payment/deposit/sum`, 'GET', null, {
				Authorization: `Bearer ${token}`,
			})
			// console.log(doc)

			setReady(true)

			setSumDeposit(doc)
		} catch (err) {
			console.log(err)
		}
	}, [token, request])

	const getWithdrawByUserId = useCallback(async () => {
		try {
			const doc = await request(`/api/payment/withdraw`, 'GET', null, {
				Authorization: `Bearer ${token}`,
			})

			setReady(true)

			setWithdrawList(doc)
		} catch (err) {
			console.log(err)
		}
	}, [token, request])

	const getSumWithdrawByUserId = useCallback(async () => {
		try {
			const doc = await request(`/api/payment/withdraw/sum`, 'GET', null, {
				Authorization: `Bearer ${token}`,
			})

			setReady(true)

			setSumWithdraw(doc)
		} catch (err) {
			console.log(err)
		}
	}, [token, request])

	const insertDeposit = useCallback(
		async (amount, createdIp) => {
			try {
				const doc = await request(
					`/api/payment/deposit`,
					'POST',
					{ amount, createdIp },
					{
						Authorization: `Bearer ${token}`,
					}
				)

				// console.log(doc)

				return doc
			} catch (err) {
				console.log(err)
			}
		},
		[token, request, amount]
	)

	const insertWithdraw = useCallback(
		async (amount, createdIp) => {
			try {
				const doc = await request(
					`/api/payment/withdraw`,
					'POST',
					{ amount, createdIp },
					{
						Authorization: `Bearer ${token}`,
					}
				)

				return doc
			} catch (err) {
				console.log(err)
			}
		},
		[token, request, amount]
	)

	return {
		loading,
		ready,
		getDepositByUserId,
		getSumDepositByUserId,
		depositList,
		sumDeposit,
		getWithdrawByUserId,
		getSumWithdrawByUserId,
		withdrawList,
		sumWithdraw,
		insertDeposit,
		insertWithdraw,
		getDepositsSum,
		getWithdrawalsSum,
	}
}
