import PropTypes from 'prop-types'
import * as React from 'react'

import { AuthContext } from '../../../context/AuthContext'
import { useHttp } from '../../../hooks/http.hook'
import { useMessage } from '../../../hooks/message.hook'

import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import FormControlLabel from '@mui/material/FormControlLabel'
import Paper from '@mui/material/Paper'
import { alpha } from '@mui/material/styles'
import Switch from '@mui/material/Switch'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TablePagination from '@mui/material/TablePagination'
import TableRow from '@mui/material/TableRow'
import TableSortLabel from '@mui/material/TableSortLabel'
import TextField from '@mui/material/TextField'
import Toolbar from '@mui/material/Toolbar'
import Typography from '@mui/material/Typography'
import { visuallyHidden } from '@mui/utils'
import PlanRow from './PlanRow'

function descendingComparator(a, b, orderBy) {
	if (b[orderBy] < a[orderBy]) {
		return -1
	}
	if (b[orderBy] > a[orderBy]) {
		return 1
	}
	return 0
}

function getComparator(order, orderBy) {
	return order === 'desc'
		? (a, b) => descendingComparator(a, b, orderBy)
		: (a, b) => -descendingComparator(a, b, orderBy)
}

function stableSort(array, comparator) {
	const stabilizedThis = array.map((el, index) => [el, index])
	stabilizedThis.sort((a, b) => {
		const order = comparator(a[0], b[0])
		if (order !== 0) {
			return order
		}
		return a[1] - b[1]
	})
	return stabilizedThis.map(el => el[0])
}

const headCells = [
	{
		id: '_id',
		numeric: false,
		disablePadding: false,
		label: 'ID',
	},
	{
		id: 'name',
		numeric: false,
		disablePadding: false,
		label: 'Name',
	},
	{
		id: 'percentDaily',
		numeric: false,
		disablePadding: false,
		label: 'Daily',
	},
	{
		id: 'termDays',
		numeric: false,
		disablePadding: false,
		label: 'Term',
	},
	{
		id: 'amountMin',
		numeric: false,
		disablePadding: false,
		label: 'Min Amount',
	},
	{
		id: 'amountMax',
		numeric: false,
		disablePadding: false,
		label: 'Max amount',
	},
	{
		id: 'displayOrder',
		numeric: false,
		disablePadding: false,
		label: 'Display Order',
	},
	{
		id: 'status',
		numeric: false,
		disablePadding: false,
		label: 'Status',
	},
	{
		id: 'action',
		numeric: false,
		disablePadding: false,
		label: 'Action',
	},
]

function EnhancedTableHead(props) {
	const { order, orderBy, rowCount, onRequestSort } = props
	const createSortHandler = property => event => {
		onRequestSort(event, property)
	}

	return (
		<TableHead>
			<TableRow>
				{headCells.map(headCell => (
					<TableCell
						key={headCell.id}
						align={headCell.numeric ? 'right' : 'center'}
						padding={headCell.disablePadding ? 'none' : 'normal'}
						sortDirection={orderBy === headCell.id ? order : false}
					>
						<TableSortLabel
							active={orderBy === headCell.id}
							direction={orderBy === headCell.id ? order : 'asc'}
							onClick={createSortHandler(headCell.id)}
						>
							{headCell.label}
							{orderBy === headCell.id ? (
								<Box component='span' sx={visuallyHidden}>
									{order === 'desc' ? 'sorted descending' : 'sorted ascending'}
								</Box>
							) : null}
						</TableSortLabel>
					</TableCell>
				))}
			</TableRow>
		</TableHead>
	)
}

EnhancedTableHead.propTypes = {
	numSelected: PropTypes.number.isRequired,
	onRequestSort: PropTypes.func.isRequired,
	order: PropTypes.oneOf(['asc', 'desc']).isRequired,
	orderBy: PropTypes.string.isRequired,
	rowCount: PropTypes.number.isRequired,
}

function EnhancedTableToolbar(props) {
	const { numSelected } = props

	return (
		<Toolbar
			sx={{
				pl: { sm: 2 },
				pr: { xs: 1, sm: 1 },
				...(numSelected > 0 && {
					bgcolor: theme =>
						alpha(
							theme.palette.primary.main,
							theme.palette.action.activatedOpacity
						),
				}),
			}}
		>
			<Typography
				sx={{ flex: '1 1 100%' }}
				variant='h6'
				id='tableTitle'
				component='div'
			>
				Plans
			</Typography>
		</Toolbar>
	)
}

EnhancedTableToolbar.propTypes = {
	numSelected: PropTypes.number.isRequired,
}

export default function PlansTable({ rows, refresh, addPlan, removePlan }) {
	const [order, setOrder] = React.useState('asc')
	const [orderBy, setOrderBy] = React.useState('calories')
	const [selected, setSelected] = React.useState([])
	const [page, setPage] = React.useState(0)
	const [dense, setDense] = React.useState(false)
	const [rowsPerPage, setRowsPerPage] = React.useState(25)

	const { loading, request } = useHttp()
	const { token } = React.useContext(AuthContext)

	const message = useMessage()

	const [formNewPlan, setFormNewPlan] = React.useState({
		name: '',
		percentDaily: '',
		termDays: '',
		amountMin: '',
		amountMax: '',
		displayOrder: '',
		status: true,
	})

	const formNewPlanChange = event => {
		setFormNewPlan({ ...formNewPlan, [event.target.name]: event.target.value })
	}

	const handleAddPlan = () => {
		console.log(formNewPlan)
		addPlan({ ...formNewPlan })

		// refresh()
	}

	const handleRemovePlan = plan => {
		removePlan(plan)

		// refresh()
	}

	const handleRequestSort = (event, property) => {
		const isAsc = orderBy === property && order === 'asc'
		setOrder(isAsc ? 'desc' : 'asc')
		setOrderBy(property)
	}

	const handleChangePage = (event, newPage) => {
		setPage(newPage)
	}

	const handleChangeRowsPerPage = event => {
		setRowsPerPage(parseInt(event.target.value, 10))
		setPage(0)
	}

	const handleChangeDense = event => {
		setDense(event.target.checked)
	}

	const emptyRows =
		page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0

	const visibleRows = React.useMemo(
		() =>
			stableSort(rows, getComparator(order, orderBy)).slice(
				page * rowsPerPage,
				page * rowsPerPage + rowsPerPage
			),
		[order, orderBy, page, rowsPerPage]
	)

	return (
		<Box sx={{ width: '100%' }}>
			<Paper sx={{ width: '100%', mb: 2 }}>
				<EnhancedTableToolbar numSelected={selected.length} />
				<TableContainer>
					<Table
						sx={{ minWidth: 750 }}
						aria-labelledby='tableTitle'
						size={dense ? 'small' : 'medium'}
					>
						<EnhancedTableHead
							numSelected={selected.length}
							order={order}
							orderBy={orderBy}
							onRequestSort={handleRequestSort}
							rowCount={rows.length}
						/>
						<TableBody>
							{visibleRows.map(row => {
								return (
									<PlanRow
										key={row._id}
										row={row}
										handleRemovePlan={handleRemovePlan}
									/>
								)
							})}

							<TableRow>
								<TableCell align='center'>Create Plan</TableCell>
								<TableCell align='center'>
									<TextField
										name='name'
										size='small'
										onChange={formNewPlanChange}
									/>
								</TableCell>
								<TableCell align='center'>
									<TextField
										size='small'
										name='percentDaily'
										onChange={formNewPlanChange}
									/>
								</TableCell>
								<TableCell align='center'>
									<TextField
										size='small'
										name='termDays'
										onChange={formNewPlanChange}
									/>
								</TableCell>
								<TableCell align='center'>
									<TextField
										size='small'
										name='amountMin'
										onChange={formNewPlanChange}
									/>
								</TableCell>
								<TableCell align='center'>
									<TextField
										size='small'
										name='amountMax'
										onChange={formNewPlanChange}
									/>
								</TableCell>
								<TableCell align='center'>
									<TextField
										size='small'
										name='displayOrder'
										onChange={formNewPlanChange}
									/>
								</TableCell>
								<TableCell align='center'>
									<Switch
										defaultChecked
										name='status'
										onChange={event => {
											setFormNewPlan({
												...formNewPlan,
												[event.target.name]: event.target.checked,
											})
										}}
									/>
								</TableCell>
								<TableCell align='center'>
									<Button onClick={handleAddPlan}>Add</Button>
								</TableCell>
							</TableRow>

							{emptyRows > 0 && (
								<TableRow
									style={{
										height: (dense ? 33 : 53) * emptyRows,
									}}
								>
									<TableCell colSpan={6} />
								</TableRow>
							)}
						</TableBody>
					</Table>
				</TableContainer>
				<TablePagination
					rowsPerPageOptions={[5, 10, 25]}
					component='div'
					count={rows.length}
					rowsPerPage={rowsPerPage}
					page={page}
					onPageChange={handleChangePage}
					onRowsPerPageChange={handleChangeRowsPerPage}
				/>
			</Paper>
			<FormControlLabel
				control={<Switch checked={dense} onChange={handleChangeDense} />}
				label='Dense padding'
			/>
		</Box>
	)
}
