import React, { useCallback, useContext, useEffect, useState } from 'react'
import { NavLink } from 'react-router-dom'

import { useHttp } from '../../hooks/http.hook'
import { AuthContext } from '../../context/AuthContext'
import { Loader } from '../../components/Loader'
import { useMessage } from '../../hooks/message.hook'

export const WalletsPage = () => {
	const userId = JSON.parse(localStorage.getItem('userData'))['userId']

	const [ready, setReady] = useState(false)

	const [form, setForm] = useState({})

	const message = useMessage()

	const { loading, request } = useHttp()
	const { token } = useContext(AuthContext)

	const fetchData = useCallback(async () => {
		try {
			const doc = await request('/api/profile/wallets', 'GET', null, {
				Authorization: `Bearer ${token}`,
			})
			setForm(doc)

			console.log('WalletsPage, fetchData, doc: ', doc)

			setReady(true)
		} catch (err) {
			console.log(err)
		}
	}, [token, request])

	const changeHandler = event => {
		setForm({ ...form, [event.target.name]: event.target.value })
	}

	const updateHandler = useCallback(async () => {
		if (!form.payeer) return message('All inputs are required!', 'error')

		try {
			const doc = await request(
				'/api/profile/wallets',
				'POST',
				{ ...form },
				{
					Authorization: `Bearer ${token}`,
				}
			)

			if (doc.success) message('Saved successful!', 'success')

			console.log('WalletsPage, updateHandler, doc: ', doc)

			setReady(true)
		} catch (err) {
			console.log(err)
		}
	}, [token, request, form])

	useEffect(() => {
		fetchData()
	}, [fetchData])

	return (
		<main>
			<div className='mx-auto max-w-7xl sm:px-6 lg:px-8'>
				<div className='grid grid-cols-1 gap-x-6 sm:grid-cols-6'>
					<div className='sm:col-span-2 sm:col-start-1'>
						<label
							htmlFor='payeer'
							className='block text-sm font-medium leading-6 text-gray-900'
						>
							Payeer
						</label>

						<div className='mt-2'>
							<div className='flex rounded-md shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-inset focus-within:ring-indigo-600 sm:max-w-md'>
								<span className='flex select-none items-center pl-3 sm:text-sm'>
									P
								</span>
								<input
									type='text'
									name='payeer'
									id='payeer'
									autoComplete='payeer'
									className='block flex-1 border-0 bg-transparent py-1.5 pl-1 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6'
									placeholder='0123456789'
									value={form.payeer ? form.payeer : ''}
									onChange={changeHandler}
								/>
							</div>
						</div>
					</div>

					<div className='sm:col-span-2 sm:col-start-1 text-center'>
						<button
							type='submit'
							className='rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 w-1/2'
							onClick={updateHandler}
						>
							Save
						</button>
					</div>
				</div>
			</div>
		</main>
	)
}
