import { useEffect } from 'react'
import LoaderLinear from '../../components/LoaderLinear'

import { useInvestment } from '../../hooks/admin/investment.hook'

import InvestmentsTable from './components/InvestmentsTable'

export const AdminInvestmentsPage = () => {
	const { ready, getInvestment, investmentList } = useInvestment()

	useEffect(() => {
		getInvestment()
	}, [])

	if (!ready) return <LoaderLinear />

	return <InvestmentsTable rows={investmentList} />
}
