import ContactPageIcon from '@mui/icons-material/ContactPage'

const pathname = window.location.pathname

const navItems = [
	{
		name: 'Home',
		to: '/',
		current: `${pathname}` === '/' ? true : false,
		icon: <ContactPageIcon />,
	},
	{
		name: 'Test',
		to: '/test',
		current: `${pathname}` === '/test' ? true : false,
		icon: <ContactPageIcon />,
	},
]

const authNavItems = [
	{
		name: 'Test',
		to: '/test',
		current: `${pathname}` === '/test' ? true : false,
		forAdmin: false,
	},
	{
		name: 'Home',
		to: '/',
		current: `${pathname}` === '/' ? true : false,
		forAdmin: false,
	},
	{
		name: 'Dashboard',
		to: '/cabinet/dashboard',
		current: `${pathname}` === '/cabinet/dashboard' ? true : false,
		forAdmin: false,
	},
	{
		name: 'Investments',
		to: '/investment',
		current: `${pathname}` === '/investment' ? true : false,
		forAdmin: false,
	},
	{
		name: 'Referrals',
		to: '/referral',
		current: `${pathname}` === '/referral' ? true : false,
		forAdmin: false,
	},
	{
		name: 'Admin',
		to: '/admin',
		current: `${pathname}` === '/admin' ? true : false,
		forAdmin: true,
	},
]

export { navItems, authNavItems }
