import React from 'react'
import Widget1 from '../../components/Widget1'

import { useIP } from '../../hooks/ip.hook'
import PageLayout from '../../layout/PageLayout'

import InfoIcon from '@mui/icons-material/Info'
import Widget2 from '../../components/Widget2'
import { Alert, Typography } from '@mui/material'
import StyledHeading from '../../components/StyledHeading'

export const DashboardPage = () => {
	const { ipAddress } = useIP()

	return (
		<PageLayout>
			{/* <Widget1 title='Your IP address' value={ipAddress} icon={<InfoIcon />} /> */}

			<StyledHeading>My Investments</StyledHeading>

			<Widget2 />
		</PageLayout>
	)
}
