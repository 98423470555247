import React, { useCallback, useContext, useEffect, useState } from 'react'
import { NavLink } from 'react-router-dom'

import { useHttp } from '../../hooks/http.hook'
import { AuthContext } from '../../context/AuthContext'
import { Loader } from '../../components/Loader'
import { useMessage } from '../../hooks/message.hook'

export const PersonalPage = () => {
	const userId = JSON.parse(localStorage.getItem('userData'))['userId']

	const [ready, setReady] = useState(false)

	const [form, setForm] = useState({
		firstName: '',
		gender: 0,
		lastName: '',
		username: '',
	})

	const { loading, request } = useHttp()
	const { token } = useContext(AuthContext)

	const message = useMessage()

	const fetchData = useCallback(async () => {
		try {
			const doc = await request(`/api/profile/personal`, 'GET', null, {
				Authorization: `Bearer ${token}`,
			})
			setForm({ ...form, ...doc })
			// console.log('doc', doc)
			setReady(true)
		} catch (err) {
			console.log(err)
		}
	}, [token, request])

	const changeHandler = event => {
		setForm({ ...form, [event.target.name]: event.target.value })
	}

	const updateHandler = useCallback(async () => {
		if (!form.firstName || !form.lastName || !form.gender || !form.birthday)
			return message('All inputs are required!', 'error')

		try {
			const doc = await request(
				`/api/profile/personal`,
				'POST',
				{ ...form },
				{
					Authorization: `Bearer ${token}`,
				}
			)

			if (doc.success) message('Saved successful!', 'success')

			setReady(true)
		} catch (err) {
			console.log(err)
		}
	}, [token, request, form])

	useEffect(() => {
		fetchData()
	}, [fetchData])

	// console.log('PersonalPage, form: ', form)

	return (
		<main>
			<div className='mx-auto max-w-7xl py-6 sm:px-6 lg:px-8'>
				<div className='grid grid-cols-2 sm:grid-cols-6 gap-x-6 gap-y-8'>
					<div className='sm:col-span-1 sm:col-start-1'>
						<label
							htmlFor='firstName'
							className='block text-sm font-medium leading-6 text-gray-900'
						>
							First name
						</label>
						<div className='mt-2'>
							<input
								type='text'
								name='firstName'
								required
								className='block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6'
								value={form.firstName}
								onChange={e => changeHandler(e)}
							/>
						</div>
					</div>

					<div className='sm:col-span-1 sm:col-start-2'>
						<label
							htmlFor='lastName'
							className='block text-sm font-medium leading-6 text-gray-900'
						>
							Last name
						</label>
						<div className='mt-2'>
							<input
								type='text'
								name='lastName'
								required
								className='block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6'
								value={form.lastName}
								onChange={changeHandler}
							/>
						</div>
					</div>

					<div className='col-span-2 col-start-1'>
						<label
							htmlFor='username'
							className='block text-sm font-medium leading-6 text-gray-900'
						>
							Username
						</label>
						<div className='mt-2'>
							<input
								type='text'
								name='username'
								disabled
								className='block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6'
								value={form.username}
								onChange={changeHandler}
							/>
						</div>
					</div>

					<div className='sm:col-span-2 sm:col-start-1'>
						<label
							htmlFor='username'
							className='block text-sm font-medium leading-6 text-gray-900'
						>
							Gender
						</label>
						<div className='mt-2'>
							<select
								type='text'
								name='gender'
								className='block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6'
								value={form.gender}
								onChange={changeHandler}
							>
								<option value='1'>Select...</option>
								<option value='2'>Male</option>
								<option value='3'>Female</option>
							</select>
						</div>
					</div>

					<div className='sm:col-span-2 sm:col-start-1'>
						<label
							htmlFor='birthday'
							className='block text-sm font-medium leading-6 text-gray-900'
						>
							Date of Birth
						</label>
						<div className='mt-2'>
							<input
								type='date'
								name='birthday'
								required
								className='block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6'
								value={form.birthday ? form.birthday.split('T')[0] : ''}
								onChange={changeHandler}
							/>
						</div>
					</div>

					<div className='sm:col-span-2 sm:col-start-1 text-center'>
						<button
							type='submit'
							className='rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 w-1/2'
							onClick={updateHandler}
						>
							Save
						</button>
					</div>
				</div>
			</div>
		</main>
	)
}
