import PropTypes from 'prop-types'
import * as React from 'react'
import { NavLink, useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { ThemeProvider, createTheme } from '@mui/material/styles'

import useLocalStorage from '../../hooks/localStorage.hook'
import i18n from '../../i18n'

import env from 'react-dotenv'

import Logotext2 from '../../assets/img/logotext2.svg'
import Logotext from '../../assets/img/logotext.svg'

import { AuthContext } from '../../context/AuthContext'
import Balance from '../Header/components/Balance'
import NotificationDrawer from '../NotificationDrawer'
import AvatarMenu from './components/AvatarMenu'
import LoaderLinear from '../LoaderLinear'
import { navItems, authNavItems } from './navigation'

import LogoutIcon from '@mui/icons-material/Logout'
import MenuIcon from '@mui/icons-material/Menu'
import AppBar from '@mui/material/AppBar'
import Avatar from '@mui/material/Avatar'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import CssBaseline from '@mui/material/CssBaseline'
import Divider from '@mui/material/Divider'
import Drawer from '@mui/material/Drawer'
import IconButton from '@mui/material/IconButton'
import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'
import ListItemButton from '@mui/material/ListItemButton'
import ListItemIcon from '@mui/material/ListItemIcon'
import ListItemText from '@mui/material/ListItemText'
import Menu from '@mui/material/Menu'
import MenuItem from '@mui/material/MenuItem'
import { alpha, styled } from '@mui/material/styles'
import Toolbar from '@mui/material/Toolbar'
import Tooltip from '@mui/material/Tooltip'
import Typography from '@mui/material/Typography'

const drawerWidth = 240

const darkTheme = createTheme({
	palette: {
		mode: 'dark',
		primary: {
			main: '#1976d2',
		},
	},
})

function Appbar(props) {
	// Languages
	const { t } = useTranslation()
	const [language, setLanguage] = useLocalStorage('language', 'en')

	const handleLanguageChange = () => {
		if (language === 'en') {
			i18n.changeLanguage('ru')
			setLanguage('ru')
		} else if (language === 'ru') {
			i18n.changeLanguage('en')
			setLanguage('en')
		}
	}

	const changeLanguageButton = (
		<Button
			variant='outlined'
			sx={{ color: '#fff' }}
			onClick={handleLanguageChange}
		>
			{language === 'en' ? t('ru') : t('en')}
		</Button>
	)

	// Appbar
	const { window } = props
	const [mobileOpen, setMobileOpen] = React.useState(false)

	const auth = React.useContext(AuthContext)

	const handleDrawerToggle = () => {
		setMobileOpen(prevState => !prevState)
	}

	const drawer = (
		<Box onClick={handleDrawerToggle} sx={{ textAlign: 'center' }}>
			<Box className='flex justify-center py-3'>
				<NavLink to='/'>
					<img src={Logotext2} alt='Dominicash' />
				</NavLink>
			</Box>

			<Divider />

			{/* {changeLanguageButton} */}

			{/* <Divider /> */}

			<List>
				{!auth.isAuth
					? navItems.map((item, index) => (
							<ListItem key={index} disablePadding>
								<ListItemButton
									component={NavLink}
									to={item.to}
									sx={{ textAlign: 'center' }}
								>
									<ListItemText primary={item.name} />
								</ListItemButton>
							</ListItem>
					  ))
					: authNavItems.map((item, index) => (
							<ListItem key={index} disablePadding>
								<ListItemButton
									component={NavLink}
									to={item.to}
									sx={{ textAlign: 'center' }}
								>
									<ListItemText primary={item.name} />
								</ListItemButton>
							</ListItem>
					  ))}
			</List>
		</Box>
	)

	const container =
		window !== undefined ? () => window().document.body : undefined

	return (
		<Box sx={{ display: 'flex' }}>
			<CssBaseline />

			<ThemeProvider theme={darkTheme}>
				<AppBar component='nav' position='fixed' color='primary'>
					<Toolbar>
						{/* <div className='flex'> */}
						<IconButton
							color='inherit'
							aria-label='open drawer'
							edge='start'
							onClick={handleDrawerToggle}
							sx={{ flexGrow: 0, display: { md: 'none' } }}
						>
							<MenuIcon />
						</IconButton>

						<Box
							sx={{
								flexGrow: 1,
							}}
						>
							<NavLink to='/' className='hidden lg:inline-block align-middle'>
								<img src={Logotext} alt='Dominicash' />
							</NavLink>
						</Box>

						<Box
							sx={{
								flexGrow: 1,
							}}
						>
							<Box sx={{ display: { xs: 'none', md: 'block' } }}>
								{!auth.isAuth
									? // Guest Nav Links
									  navItems.map((item, index) => (
											<NavLink key={index} to={item.to}>
												<Button sx={{ color: '#fff' }}>{item.name}</Button>
											</NavLink>
									  ))
									: // Auth Nav Links
									  authNavItems.map((item, index) => {
											if (item.forAdmin === auth.isAdmin || auth.isAdmin)
												return (
													<NavLink key={index} to={item.to}>
														<Button sx={{ color: '#fff' }}>{item.name}</Button>
													</NavLink>
												)
									  })}
							</Box>
						</Box>
						{!auth.isAuth ? (
							<Box>
								<NavLink to='/login'>
									<Button sx={{ color: '#fff', mr: 1 }}>Log In</Button>
								</NavLink>
								<NavLink to='/register'>
									<Button sx={{ color: '#fff' }}>Register</Button>
								</NavLink>
							</Box>
						) : (
							<Box sx={{ flexGrow: 0 }}>
								<Balance />
								<NotificationDrawer />
								<AvatarMenu />
							</Box>
						)}
						{/* {changeLanguageButton} */}
						{/* </div> */}
					</Toolbar>
				</AppBar>
			</ThemeProvider>

			<Box component='nav'>
				<Drawer
					container={container}
					variant='temporary'
					open={mobileOpen}
					onClose={handleDrawerToggle}
					ModalProps={{
						keepMounted: true, // Better open performance on mobile.
					}}
					sx={{
						display: { xs: 'block', md: 'none' },
						'& .MuiDrawer-paper': {
							boxSizing: 'border-box',
							width: drawerWidth,
						},
					}}
				>
					{drawer}
				</Drawer>
			</Box>

			{/* <Box component='main' sx={{ p: 3 }}> */}
			<Toolbar />
			{/* </Box> */}
		</Box>
	)
}

Appbar.propTypes = {
	/**
	 * Injected by the documentation to work in an iframe.
	 * You won't need it on your project.
	 */
	window: PropTypes.func,
}

export default Appbar
