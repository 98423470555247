import React, { useState } from 'react'

import logo from '../assets/img/logo2.svg'
import { useHttp } from '../hooks/http.hook'

import Button from '@mui/material/Button'
import ButtonGroup from '@mui/material/ButtonGroup'
import { isEmail } from '../utils/validators'

const MaintenancePage = () => {
	const [errors, setErrors] = useState({})
	const [success, setSuccess] = useState(false)

	const { request } = useHttp()

	const [email, setEmail] = useState('')

	const submit = () => {
		const errors = {}

		if (!isEmail(email)) {
			errors.email = 'Wrong email'
		}

		setErrors(errors)

		const message = `Subscribed email: ${email}`

		if (!Object.keys(errors).length) {
			request(
				`https://api.telegram.org/bot6444349578:AAFJ5ye99FLRBIvF74qOf8WJ7Mkl19Ugtls/sendMessage?chat_id=563245024&text=${message}`
			)
			setSuccess(true)
			setEmail('')
		}
	}

	return (
		<div className='min-h-screen bg-gray-900 flex flex-col items-center justify-center'>
			<img src={logo} alt='Dominicash' className='mb-8' />
			<h1 className='text-5xl text-white font-bold mb-8 animate-pulse'>
				Coming Soon
			</h1>
			<p className='text-center text-white text-lg mb-8'>
				We're working hard to bring you something amazing. Stay tuned!
			</p>
			<p className='text-center text-white text-lg mb-8'>
				Be the first to know about the start of the project!
			</p>

			<div className='flex'>
				<input
					placeholder='Enter your Email...'
					className='bg-black border border-r-0 border-white rounded-l-2xl p-3 m-0 text-white focus:border-white focus:outline-none'
					type='email'
					value={email}
					onChange={e => {
						setEmail(e.target.value)
					}}
				/>
				<button
					className='bg-black border border-l-0 text-white rounded-r-2xl rounded-sm p-3 m-0 hover:text-red-600'
					onClick={submit}
				>
					Subscribe
				</button>
			</div>
			<div>
				{errors.email ? (
					<span className='text-red-600'>{errors.email}</span>
				) : (
					''
				)}

				{success ? <span className='text-green-600'>Thank you!</span> : ''}
			</div>
		</div>
	)
}

export default MaintenancePage
