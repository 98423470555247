import React from 'react'

const StyledHeading = ({ children }) => {
	return (
		<div className='max-w-md mx-auto space-y-6 my-6'>
			<h2 className='flex flex-row flex-nowrap items-center'>
				<span
					className='flex-grow block border-t border-black'
					aria-hidden='true'
					role='presentation'
				></span>
				<span className='flex-none block mx-4 px-4 py-2.5 text-sm leading-none font-medium uppercase bg-black text-white'>
					{children}
				</span>
				<span
					className='flex-grow block border-t border-black'
					aria-hidden='true'
					role='presentation'
				></span>
			</h2>
		</div>
	)
}

export default StyledHeading
