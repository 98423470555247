const dateFormat = date => {
	date = new Date(date)
	return new Intl.DateTimeFormat('en-US', {
		dateStyle: 'medium',
		timeZone: 'GMT',
	}).format(date)
}

const dateTimeFormat = date => {
	date = new Date(date)
	return new Intl.DateTimeFormat('en-US', {
		dateStyle: 'medium',
		timeStyle: 'short',
		timeZone: 'GMT',
	}).format(date)
}

export { dateFormat, dateTimeFormat }
