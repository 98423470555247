import React, { useContext } from 'react'
import { NavLink, useNavigate } from 'react-router-dom'

import { AuthContext } from '../../../context/AuthContext'

import ContactPageIcon from '@mui/icons-material/ContactPage'
import LogoutIcon from '@mui/icons-material/Logout'
import MenuIcon from '@mui/icons-material/Menu'
import AppBar from '@mui/material/AppBar'
import Avatar from '@mui/material/Avatar'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import CssBaseline from '@mui/material/CssBaseline'
import Divider from '@mui/material/Divider'
import Drawer from '@mui/material/Drawer'
import IconButton from '@mui/material/IconButton'
import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'
import ListItemButton from '@mui/material/ListItemButton'
import ListItemIcon from '@mui/material/ListItemIcon'
import ListItemText from '@mui/material/ListItemText'
import Menu from '@mui/material/Menu'
import MenuItem from '@mui/material/MenuItem'
import { alpha, styled } from '@mui/material/styles'
import Toolbar from '@mui/material/Toolbar'
import Tooltip from '@mui/material/Tooltip'
import Typography from '@mui/material/Typography'

const avaNavItems = [
	{ name: 'Profile', to: '/profile', icon: <ContactPageIcon /> },
	// { name: 'Create', to: '/create', icon: '' },
	// { name: 'Links', to: '/links', icon: '' },
]

const StyledMenu = styled(props => (
	<Menu
		elevation={0}
		anchorOrigin={{
			vertical: 'bottom',
			horizontal: 'right',
		}}
		transformOrigin={{
			vertical: 'top',
			horizontal: 'right',
		}}
		{...props}
	/>
))(({ theme }) => ({
	'& .MuiPaper-root': {
		borderRadius: 6,
		marginTop: theme.spacing(1),
		minWidth: 180,
		color:
			theme.palette.mode === 'light'
				? 'rgb(55, 65, 81)'
				: theme.palette.grey[300],
		boxShadow:
			'rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px',
		'& .MuiMenu-list': {
			padding: '4px 0',
		},
		'& .MuiMenuItem-root': {
			'& .MuiSvgIcon-root': {
				fontSize: 18,
				color: theme.palette.text.secondary,
				marginRight: theme.spacing(1.5),
			},
			'&:active': {
				backgroundColor: alpha(
					theme.palette.primary.main,
					theme.palette.action.selectedOpacity
				),
			},
		},
	},
}))

const AvatarMenu = () => {
	const navigate = useNavigate()

	const auth = useContext(AuthContext)

	const [anchorElUser, setAnchorElUser] = React.useState(null)

	const handleOpenUserMenu = event => {
		setAnchorElUser(event.currentTarget)
	}

	const handleCloseUserMenu = () => {
		setAnchorElUser(null)
	}

	const logoutHandler = () => {
		// handleCloseUserMenu()
		auth.logout()
		navigate('/')
	}

	return (
		<>
			<Tooltip title='Open menu'>
				<IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
					<Avatar />
				</IconButton>
			</Tooltip>

			<StyledMenu
				id='menu-topbar'
				MenuListProps={{
					'aria-labelledby': 'demo-customized-button',
				}}
				anchorEl={anchorElUser}
				open={Boolean(anchorElUser)}
				onClose={handleCloseUserMenu}
			>
				{avaNavItems.map((nav, index) => (
					<NavLink key={index} to={nav.to}>
						<MenuItem key={index} onClick={handleCloseUserMenu}>
							{nav.icon ? nav.icon : ''}

							<Typography component='span'>{nav.name}</Typography>
						</MenuItem>
					</NavLink>
				))}

				<Divider />

				<MenuItem>
					<LogoutIcon />
					<Typography component='span' onClick={logoutHandler}>
						Sign out
					</Typography>
				</MenuItem>
			</StyledMenu>
		</>
	)
}

export default AvatarMenu
