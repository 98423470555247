import React from 'react'

const Input = ({
	type = 'text',
	title,
	name,
	value,
	onChange,
	placeholder,
	errorMessage,
}) => {
	return (
		<div>
			<label
				htmlFor={name}
				className='block text-sm font-bold ml-1 mb-2 dark:text-white'
			>
				{title}
			</label>
			<div className='relative'>
				<input
					type={type}
					id={name}
					name={name}
					placeholder={placeholder}
					className='py-3 px-4 block w-full border-2 border-gray-200 rounded-md text-sm focus:border-blue-500 focus:ring-blue-500 shadow-sm'
					aria-describedby={`${name}-error`}
					value={value}
					onChange={onChange}
				/>
			</div>
			<p className='hidden text-xs text-red-600 mt-2' id={`${name}-error`}>
				{errorMessage}
			</p>
		</div>
	)
}

export default Input
