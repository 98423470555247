import React, { useEffect, useState } from 'react'
import { NavLink, useNavigate, useParams } from 'react-router-dom'

import { usePlan } from '../../hooks/admin/plan.hook'
import currencyFormatUSD from '../../utils/currencyFormatUSD'

import ContactPageIcon from '@mui/icons-material/ContactPage'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp'
import TabContext from '@mui/lab/TabContext'
import TabList from '@mui/lab/TabList'
import TabPanel from '@mui/lab/TabPanel'
import { Button, FormControlLabel, Switch, TextField } from '@mui/material'
import Box from '@mui/material/Box'
import Collapse from '@mui/material/Collapse'
import IconButton from '@mui/material/IconButton'
import Paper from '@mui/material/Paper'
import Tab from '@mui/material/Tab'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import Typography from '@mui/material/Typography'
import PropTypes from 'prop-types'
import { useMessage } from '../../hooks/message.hook'

const AdminPlanPage = () => {
	const { id } = useParams()

	const message = useMessage()

	const navigate = useNavigate()

	const [plan, setPlan] = useState({})

	const { loading, ready, getPlan, updatePlan } = usePlan()

	const onChange = e => {
		setPlan({ ...plan, [e.target.name]: e.target.value })
	}

	const onSave = () => {
		console.log('plan', plan)
		updatePlan(id, plan).then(res =>
			res.success
				? message('Saved successful', 'success')
				: message('Error', 'error')
		)
		navigate('/admin/plans')
	}

	useEffect(() => {
		getPlan(id).then(res => setPlan(res))
	}, [])

	if (!ready || loading) return 'Loading...'

	return (
		<TabContext value='plan'>
			<Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
				<TabList variant='scrollable' scrollButtons='auto'>
					<NavLink to='/admin/plans'>
						<Tab
							label='Back to Plans'
							value='plans'
							icon={<ContactPageIcon />}
							iconPosition='start'
						/>
					</NavLink>
					<Tab
						label='Plan Info'
						value='plan'
						icon={<ContactPageIcon />}
						iconPosition='start'
					/>
				</TabList>
			</Box>

			<TabPanel value='plan'>
				<main className='max-w-sm'>
					<div className='grid grid-cols-2 gap-3'>
						<TextField
							fullWidth
							label='Plan Name'
							name='name'
							value={plan.name}
							required
							onChange={e => onChange(e)}
						/>

						<FormControlLabel
							control={
								<Switch
									name='status'
									checked={plan.status}
									onChange={e => setPlan({ ...plan, status: e.target.checked })}
								/>
							}
							label={plan.status ? 'Enabled' : 'Disabled'}
						/>

						<TextField
							fullWidth
							label='Min amount'
							name='amountMin'
							value={plan.amountMin}
							required
							onChange={e => onChange(e)}
						/>

						<TextField
							fullWidth
							label='Max amount'
							name='amountMax'
							value={plan.amountMax}
							required
							onChange={e => onChange(e)}
						/>

						<TextField
							fullWidth
							label='Daily Profit, %'
							name='percentDaily'
							value={plan.percentDaily}
							required
							onChange={e => onChange(e)}
						/>

						<TextField
							fullWidth
							label='Period'
							name='termDays'
							value={plan.termDays}
							required
							onChange={e => onChange(e)}
						/>
					</div>

					<div>
						<Button onClick={onSave}>Save</Button>
					</div>
				</main>
			</TabPanel>
		</TabContext>
	)
}

export default AdminPlanPage
