import { useEffect } from 'react'
import LoaderLinear from '../../components/LoaderLinear'

import { useIncome } from '../../hooks/income.hook'

import ReferralHistoryTable from './components/ReferralHistoryTable'

const ReferralHistory = () => {
	const { loading, ready, getRefIncomeByUserId, refIncomeList } = useIncome()

	useEffect(() => {
		getRefIncomeByUserId()
	}, [])

	if (loading || !ready) return <LoaderLinear />

	return !refIncomeList.length ? (
		'No data'
	) : (
		<ReferralHistoryTable rows={refIncomeList} />
	)
}

export default ReferralHistory
