import PropTypes from 'prop-types'
import * as React from 'react'

import currencyFormatUSD from '../../../utils/currencyFormatUSD'
import { dateTimeFormat } from '../../../utils/dateTimeFormat'

import { useMessage } from '../../../hooks/message.hook'

import ContentCopyIcon from '@mui/icons-material/ContentCopy'
import Button from '@mui/material/Button'
import FirstPageIcon from '@mui/icons-material/FirstPage'
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft'
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight'
import LastPageIcon from '@mui/icons-material/LastPage'
import Box from '@mui/material/Box'
import CircularProgress from '@mui/material/CircularProgress'
import IconButton from '@mui/material/IconButton'
import Paper from '@mui/material/Paper'
import { useTheme } from '@mui/material/styles'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableFooter from '@mui/material/TableFooter'
import TableHead from '@mui/material/TableHead'
import TablePagination from '@mui/material/TablePagination'
import TableRow from '@mui/material/TableRow'
import Typography from '@mui/material/Typography'
import CopyToClipboard from 'react-copy-to-clipboard'
import Tooltip from '@mui/material/Tooltip'
import VisibilityIcon from '@mui/icons-material/Visibility'
import { NavLink } from 'react-router-dom'
import CopyButton from '../../../components/CopyButton'

function TablePaginationActions(props) {
	const theme = useTheme()
	const { count, page, rowsPerPage, onPageChange } = props

	const handleFirstPageButtonClick = event => {
		onPageChange(event, 0)
	}

	const handleBackButtonClick = event => {
		onPageChange(event, page - 1)
	}

	const handleNextButtonClick = event => {
		onPageChange(event, page + 1)
	}

	const handleLastPageButtonClick = event => {
		onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1))
	}

	return (
		<Box sx={{ flexShrink: 0, ml: 2.5 }}>
			<IconButton
				onClick={handleFirstPageButtonClick}
				disabled={page === 0}
				aria-label='first page'
			>
				{theme.direction === 'rtl' ? <LastPageIcon /> : <FirstPageIcon />}
			</IconButton>
			<IconButton
				onClick={handleBackButtonClick}
				disabled={page === 0}
				aria-label='previous page'
			>
				{theme.direction === 'rtl' ? (
					<KeyboardArrowRight />
				) : (
					<KeyboardArrowLeft />
				)}
			</IconButton>
			<IconButton
				onClick={handleNextButtonClick}
				disabled={page >= Math.ceil(count / rowsPerPage) - 1}
				aria-label='next page'
			>
				{theme.direction === 'rtl' ? (
					<KeyboardArrowLeft />
				) : (
					<KeyboardArrowRight />
				)}
			</IconButton>
			<IconButton
				onClick={handleLastPageButtonClick}
				disabled={page >= Math.ceil(count / rowsPerPage) - 1}
				aria-label='last page'
			>
				{theme.direction === 'rtl' ? <FirstPageIcon /> : <LastPageIcon />}
			</IconButton>
		</Box>
	)
}

TablePaginationActions.propTypes = {
	count: PropTypes.number.isRequired,
	onPageChange: PropTypes.func.isRequired,
	page: PropTypes.number.isRequired,
	rowsPerPage: PropTypes.number.isRequired,
}

function CircularProgressWithLabel(props) {
	const value = (props.progress * 100) / props.progressTotal

	return (
		<Box sx={{ position: 'relative', display: 'inline-flex' }}>
			{/* <CircularProgress variant='determinate' {...props} /> */}
			<CircularProgress variant='determinate' value={value} size={props.size} />
			<Box
				sx={{
					top: 0,
					left: 0,
					bottom: 0,
					right: 0,
					position: 'absolute',
					display: 'flex',
					alignItems: 'center',
					justifyContent: 'center',
				}}
			>
				<Typography variant='caption' component='div' color='text.secondary'>
					{/* {`${Math.round(props.value)}%`} */}
					{props.progress}/{props.progressTotal}
				</Typography>
			</Box>
		</Box>
	)
}

CircularProgressWithLabel.propTypes = {
	progress: PropTypes.number.isRequired,
	progressTotal: PropTypes.number.isRequired,
}

const InvestmentsTable = ({ rows }) => {
	const [page, setPage] = React.useState(0)
	const [rowsPerPage, setRowsPerPage] = React.useState(5)

	const message = useMessage()

	// console.log(rows)

	const emptyRows =
		page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0

	const handleChangePage = (event, newPage) => {
		setPage(newPage)
	}

	const handleChangeRowsPerPage = event => {
		setRowsPerPage(parseInt(event.target.value, 10))
		setPage(0)
	}

	// console.log('InvestmentsTable', rows)

	return rows.length ? (
		<TableContainer component={Paper}>
			<Table aria-label='custom pagination table'>
				<TableHead>
					<TableRow>
						<TableCell>ID</TableCell>
						<TableCell>Amount</TableCell>
						<TableCell>Wallet</TableCell>
						<TableCell>User</TableCell>
						<TableCell>Plan</TableCell>
						<TableCell>Created</TableCell>
						<TableCell>Progress</TableCell>
						<TableCell align='right'>Status</TableCell>
					</TableRow>
				</TableHead>

				<TableBody>
					{(rowsPerPage > 0
						? rows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
						: rows
					).map(row => (
						<TableRow key={row._id}>
							<TableCell component='th' scope='row'>
								<NavLink to={`/admin/investment/${row._id}`} sx={{ mr: 3 }}>
									<VisibilityIcon />
								</NavLink>

								<CopyButton
									name={row._id.substring(20)}
									value={row._id}
									onCopyMessage='Investment ID copied.'
									tooltip={row._id}
								/>
							</TableCell>
							<TableCell>{currencyFormatUSD(row.amount)}</TableCell>
							<TableCell>{row.wallet}</TableCell>
							<TableCell>
								<CopyButton
									name={row.owner._id.substring(20)}
									value={row.owner._id}
									onCopyMessage='User ID copied.'
									tooltip={row.owner._id}
								/>

								<Typography
									variant='subtitle1'
									sx={{
										ml: 3,
										display: 'inline-block',
									}}
								>
									{row.owner.personal.username}
								</Typography>
							</TableCell>
							<TableCell>{row.plan.name}</TableCell>
							<TableCell>{dateTimeFormat(row.createdAt)}</TableCell>
							<TableCell>
								<CircularProgressWithLabel
									size={50}
									progress={row.incomes.length}
									progressTotal={row.plan.termDays}
								/>
								;
							</TableCell>
							<TableCell align='right'>
								{!row.closedAt ? 'Active' : 'Closed'}
							</TableCell>
						</TableRow>
					))}

					{emptyRows > 0 && (
						<TableRow style={{ height: 53 * emptyRows }}>
							<TableCell colSpan={6} />
						</TableRow>
					)}
				</TableBody>

				<TableFooter>
					<TableRow>
						<TablePagination
							rowsPerPageOptions={[5, 10, 25, { label: 'All', value: -1 }]}
							colSpan={3}
							count={rows.length}
							rowsPerPage={rowsPerPage}
							page={page}
							SelectProps={{
								inputProps: {
									'aria-label': 'rows per page',
								},
								native: true,
							}}
							onPageChange={handleChangePage}
							onRowsPerPageChange={handleChangeRowsPerPage}
							ActionsComponent={TablePaginationActions}
						/>
					</TableRow>
				</TableFooter>
			</Table>
		</TableContainer>
	) : (
		'No data'
	)
}

export default InvestmentsTable
