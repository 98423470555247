import React from 'react'

import PageLayout from '../../layout/PageLayout'

import { Container } from '@mui/material'

const FailPage = () => {
	return (
		<PageLayout>
			<Container>FailPage</Container>
		</PageLayout>
	)
}

export default FailPage
