import React, { useContext, useEffect } from 'react'

import { AuthContext } from '../../../context/AuthContext'

import currencyFormatUSD from '../../../utils/currencyFormatUSD'

import Radio from '@mui/material/Radio'
import RadioGroup from '@mui/material/RadioGroup'
import FormControlLabel from '@mui/material/FormControlLabel'
import FormControl from '@mui/material/FormControl'
import FormLabel from '@mui/material/FormLabel'

const StepWallet = ({
	investmentData,
	setInvestmentData,
	setDisabledNextButton,
}) => {
	const auth = useContext(AuthContext)

	const testHandle = e => {
		setInvestmentData({ ...investmentData, wallet: e.target.value })
		setDisabledNextButton(false)
	}

	useEffect(() => {
		if (!investmentData.wallet) setDisabledNextButton(true)
		else setDisabledNextButton(false)
	}, [])

	return (
		<FormControl>
			<FormLabel id='demo-row-radio-buttons-group-label'>
				Pay from wallet:
			</FormLabel>
			<RadioGroup
				aria-labelledby='demo-row-radio-buttons-group-label'
				name='wallet'
			>
				<FormControlLabel
					value='real'
					checked={investmentData.wallet === 'real'}
					onChange={e => testHandle(e)}
					control={<Radio />}
					label={`Real: ${currencyFormatUSD(auth.real)}`}
				/>
				<FormControlLabel
					value='bonus'
					checked={investmentData.wallet === 'bonus'}
					onChange={e => testHandle(e)}
					control={<Radio />}
					label={`Bonus: ${currencyFormatUSD(auth.bonus)}`}
				/>
			</RadioGroup>
		</FormControl>
	)
}

export default StepWallet
