import React, { useEffect } from 'react'
import { useAuth } from '../../hooks/auth.hook'

const LogoutPage = () => {
	const { logout } = useAuth()

	useEffect(() => {
		console.log('logout page')
		logout()
	}, [])

	return (
		<div className='min-h-screen bg-gray-900 flex flex-col items-center justify-center'>
			<h1 className='text-5xl text-white font-bold mb-8 animate-pulse'>
				Bye Bye!
			</h1>
			<p className='text-center text-white text-lg mb-8'>You are logged out!</p>
			<p>
				<button>Return to website!</button>
			</p>
		</div>
	)
}

export default LogoutPage
