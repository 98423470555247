import StyledHeading from '../../../components/StyledHeading'
import StyledHeading3 from '../../../components/StyledHeading3'

const items = [
	{
		title: 'Register',
		text: 'Immediately after activation you will receive a nice bonus.',
	},
	{
		title: 'Make a deposit',
		text: 'When you make your first deposit, you get +30% bonuses to your account!',
	},
	{
		title: 'Choose a plan',
		text: 'Choose any plan and get rewarded daily with fast withdrawals.',
	},
	{
		title: 'Get more money!',
		text: 'Invite friends and relatives! For each referral you will receive up to 10% of their profits.',
	},
]

const Steps = () => {
	return (
		<div className='p-4 bg-gray-700'>
			<ul className='grid place-content-center grid-rows-4 sm:grid-rows-2 grid-flow-col gap-8 py-3 mt-3'>
				{items.map((item, idx) => (
					<li key={idx} className='flex justify-center'>
						<div className='px-4 text-5xl font-extralight text-red-500 w-24'>
							0{idx + 1}.
						</div>
						<div>
							<div className='text-xl font-bold text-white'>{item.title}</div>
							<p className='max-w-xs py-2 text-sm text-white'>{item.text}</p>
						</div>
					</li>
				))}
			</ul>
		</div>
	)
}

export default Steps
