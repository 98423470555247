import { Alert, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { NavLink } from 'react-router-dom'
import StyledHeading from '../../components/StyledHeading'
import { useIP } from '../../hooks/ip.hook'
import { useLoggin } from '../../hooks/loggin.hook'
import { dateTimeFormat } from '../../utils/date'

export const SecurityPage = () => {
	const Loggin = useLoggin()

	const [ready, setReady] = useState(false)
	const [logginList, setLogginList] = useState([])
	const [timezone, setTimezone] = useState([])

	const { ipAddress } = useIP()

	useEffect(() => {
		Loggin.getLogginList().then(res => setLogginList(res))
		Loggin.getTimeZone().then(res => {
			console.log(res)
			setTimezone(res)
			setReady(true)
		})
	}, [])

	if (!ready) return 'Loading...'

	return (
		<main>
			<div className='mx-auto max-w-7xl py-6 sm:px-6 lg:px-8'>
				<Alert severity='info'>My IP address: {ipAddress}</Alert>
				<div className='flex flex-col'>
					<div className='overflow-x-auto sm:-mx-6 lg:-mx-8'>
						<div className='inline-block min-w-full py-2 sm:px-6 lg:px-8'>
							<div className='overflow-hidden'>
								<StyledHeading>Last loggins</StyledHeading>
								<table className='min-w-full text-left text-sm font-light'>
									<thead className='border-b font-medium dark:border-neutral-500'>
										<tr>
											<th scope='col' className='px-6 py-4'>
												Date
											</th>
											<th scope='col' className='px-6 py-4'>
												IP
											</th>
											<th scope='col' className='px-6 py-4'>
												User Agent
											</th>
										</tr>
									</thead>
									<tbody>
										{logginList.map((el, idx) => {
											const userAgent = el.userAgent

											const userOs = userAgent.substring(
												userAgent.indexOf('(') + 1,
												userAgent.indexOf(')')
											)

											return (
												<tr
													key={idx}
													className='border-b dark:border-neutral-500'
												>
													<td className='whitespace-nowrap px-6 py-4'>
														{dateTimeFormat(el.createdAt, timezone)}
													</td>
													<td className='whitespace-nowrap px-6 py-4'>
														{el.userIp}
													</td>
													<td className='whitespace-nowrap px-6 py-4'>
														{userOs}
													</td>
												</tr>
											)
										})}
									</tbody>
								</table>
							</div>
						</div>
					</div>
				</div>
			</div>
		</main>
	)
}
