import { useCallback } from 'react'

import Swal from 'sweetalert2'

// #todo Transfer from "hooks" to "utils"

export const useMessage = () => {
	const Toast = Swal.mixin({
		toast: true,
		position: 'bottom-start',
		showConfirmButton: false,
		timer: 3000,
		timerProgressBar: true,
		iconColor: 'white',
		customClass: {
			popup: 'colored-toast',
		},
	})

	return useCallback(
		(text, icon = 'info') => {
			if (text) {
				Toast.fire({
					title: text,
					icon: icon,
				})
			}
		},
		[Toast]
	)
}
