import React, { useContext, useEffect, useState } from 'react'
import { BrowserRouter as Router } from 'react-router-dom'

import { useRoutes } from './routes'

import { AuthContext } from './context/AuthContext'
import { AppContext } from './context/AppContext'

import { useAuth } from './hooks/auth.hook'
import { useIP } from './hooks/ip.hook'

import MaintenancePage from './pages/MaintenancePage'

import BottomBanner from './components/BottomBanner'
import LoaderLinear from './components/LoaderLinear'

// import './App.css'

function App() {
	const {
		token,
		login,
		logout,
		userId,
		ready,
		isAdmin,
		balance,
		setBalance,
		real,
		setReal,
		bonus,
		setBonus,
		notifications,
		setNotifications,
	} = useAuth()

	const isAuth = !!token

	const routes = useRoutes(isAuth, isAdmin)

	const { readyIp, ipAddress } = useIP()

	const { allowIpAddresses } = useContext(AppContext)

	if (!ready || !readyIp) {
		return <LoaderLinear />
	}

	const allowAccess = allowIpAddresses.includes(ipAddress)

	return !allowAccess ? (
		<MaintenancePage />
	) : (
		<AuthContext.Provider
			value={{
				token,
				login,
				logout,
				userId,
				isAuth,
				isAdmin,
				balance,
				setBalance,
				real,
				setReal,
				bonus,
				setBonus,
				notifications,
				setNotifications,
			}}
		>
			<Router>
				{routes}
				{/* {!isAuth && <BottomBanner />} */}
			</Router>
		</AuthContext.Provider>
	)
}

export default App
