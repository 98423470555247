import React from 'react'
import PropTypes from 'prop-types'
import { CopyToClipboard } from 'react-copy-to-clipboard'

import { useMessage } from '../../../hooks/message.hook'
import currencyFormatUSD from '../../../utils/currencyFormatUSD'
import { dateTimeFormat } from '../../../utils/dateTimeFormat'

import ContentCopyIcon from '@mui/icons-material/ContentCopy'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import FormControlLabel from '@mui/material/FormControlLabel'
import Paper from '@mui/material/Paper'
import { alpha } from '@mui/material/styles'
import Switch from '@mui/material/Switch'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TablePagination from '@mui/material/TablePagination'
import TableRow from '@mui/material/TableRow'
import TableSortLabel from '@mui/material/TableSortLabel'
import Toolbar from '@mui/material/Toolbar'
import Tooltip from '@mui/material/Tooltip'
import Typography from '@mui/material/Typography'
import { visuallyHidden } from '@mui/utils'
import { NavLink } from 'react-router-dom'
import CopyButton from '../../../components/CopyButton'

const UserRow = ({ row }) => {
	const message = useMessage()

	console.log(row)

	return (
		<TableRow hover tabIndex={-1}>
			<TableCell component='th' id={row._id} scope='row'>
				<CopyButton
					name={row._id.substring(20)}
					value={row._id}
					onCopyMessage='User ID copied.'
					tooltip={row._id}
				/>
			</TableCell>
			<TableCell align='center'>
				<NavLink to={`/admin/user/${row._id}`}>{row.personal.username}</NavLink>
			</TableCell>
			<TableCell align='center'>{row.contacts.email}</TableCell>
			<TableCell align='center'>
				<p>{currencyFormatUSD(row.balance.real)}</p>
				<p>{currencyFormatUSD(row.balance.bonus)}</p>
			</TableCell>
			<TableCell align='center'>
				{row.referrer && (
					<CopyButton
						name={row.referrer.substring(20)}
						value={row.referrer}
						onCopyMessage='Referrer ID copied.'
						tooltip={row.referrer}
					/>
				)}
			</TableCell>
			<TableCell align='center'>{row.createdIp}</TableCell>
			<TableCell align='center'>{dateTimeFormat(row.createdAt)}</TableCell>
		</TableRow>
	)
}

export default UserRow
