import React, { useEffect, useState } from 'react'

import { useUser } from '../../hooks/admin/user.hook'

export const AdminDashboardPage = () => {
	const {
		getUsersCount,
		getUsersActivatedCount,
		getUsersVerifiedCount,
		getUsersBlockedCount,
		getRegisteredTodayCount,
	} = useUser()

	const [usersCount, setUsersCount] = useState()
	const [usersActivatedCount, setUsersActivatedCount] = useState()
	const [usersVerifiedCount, setUsersVerifiedCount] = useState()
	const [usersBlockedCount, setUsersBlockedCount] = useState()
	const [registeredTodayCount, setRegisteredTodayCount] = useState()

	useEffect(() => {
		getUsersCount().then(res => setUsersCount(res))
		getUsersActivatedCount().then(res => setUsersActivatedCount(res))
		getUsersVerifiedCount().then(res => setUsersVerifiedCount(res))
		getUsersBlockedCount().then(res => setUsersBlockedCount(res))
		getRegisteredTodayCount().then(res => setRegisteredTodayCount(res))
	}, [])

	return (
		<main>
			<div className='mx-auto max-w-7xl py-6 sm:px-6 lg:px-8'>
				<div>Users: {usersCount}</div>
				<div>Registered today: {registeredTodayCount}</div>
				<div>Activated: {usersActivatedCount}</div>
				<div>Verified: {usersVerifiedCount}</div>
				<div>Blocked: {usersBlockedCount}</div>
				<div></div>
				<div>Online users: 0</div>
				<div></div>
				<div></div>
				<div></div>
				<div></div>
				<div></div>
				<div></div>
				<div>Active investments: 0</div>
				<div>Total investments: 0</div>
			</div>
		</main>
	)
}
