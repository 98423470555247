import { createContext } from 'react'

const siteUri = window.location.origin

export const AppContext = createContext({
	allowIpAddresses: [
		'31.148.168.19',
		'31.148.168.20',
		'31.148.168.6',
		'31.148.168.7',
		'31.148.168.8',
		'31.148.168.155',
		'185.71.65.92',
		'185.71.65.189',
		'149.202.17.210',
	],
	appVersion: 'v1',
	siteUri,
	siteDateStarted: '2023-11-10',
	referral1LineProfit: 10,
	referral2LineProfit: 5,
	referral3LineProfit: 3,
	depositMinAmount: 10,
	depositMaxAmount: 10000,
	withdrawMinAmount: 10,
	withdrawMaxAmount: 10000,
})
