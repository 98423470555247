import React from 'react'

import Appbar from '../components/Appbar'
import Footer from '../components/Footer'

// import '../App.css'

const PageLayout = ({ children }) => {
	return (
		<>
			<Appbar />
			<div className='bg-white'>
				{/* <div className='bg'></div>
			<div className='bg bg2'></div>
			<div className='bg bg3'></div> */}
				{children}
			</div>
			{/* <Footer /> */}
		</>
	)
}

export default PageLayout
