import React, { useEffect, useRef, useState } from 'react'
import ReCAPTCHA from 'react-google-recaptcha'
import { NavLink } from 'react-router-dom'

import { useHttp } from '../../hooks/http.hook'
import { useMessage } from '../../hooks/message.hook'

import PageLayout from '../../layout/PageLayout'

import Button from '@mui/material/Button'
import Paper from '@mui/material/Paper'
import TextField from '@mui/material/TextField'
import Typography from '@mui/material/Typography'

import styles from './LoginPage.module.scss'
import EditInput from '../../components/EditInput'
import Input from '../../components/UI/Input'
import { isEmail } from '../../utils/validators'

export const ForgotPage = () => {
	const message = useMessage()
	const { request, error, clearError } = useHttp()

	const captchaRef = useRef(null)

	const submitHandler = async e => {
		e.preventDefault()

		try {
			const formData = new FormData(e.target)
			const json = Object.fromEntries(formData)
			// const string = JSON.stringify(json)

			if (!isEmail(json.email))
				return message('Invalid Email address!', 'error')

			const captchaToken = captchaRef.current.getValue()

			captchaRef.current.reset()

			// const captchaData = await request('/api/recaptcha', 'POST', {
			// 	captchaToken,
			// })
			// if (!captchaData.success) {
			// 	return message(captchaData.message, 'error')
			// }

			const data = await request('/api/auth/forgot', 'POST', json)

			message(data.message, data.type)
		} catch (err) {}
	}

	// useEffect(() => {
	// 	message(error, 'error')
	// 	clearError()
	// }, [error, message, clearError])

	return (
		<PageLayout>
			<div className='bg-gray-700'>
				<main id='content' role='main' className='w-full max-w-md mx-auto p-6'>
					<div className='mt-7 bg-white  rounded-xl shadow-lg dark:bg-gray-800 dark:border-gray-700'>
						<div className='p-4 sm:p-7'>
							<div className='text-center'>
								<h1 className='block text-2xl font-bold text-gray-800 dark:text-white'>
									Forgot password?
								</h1>
								<p className='mt-2 text-sm text-gray-600 dark:text-gray-400'>
									Or{' '}
									<NavLink
										className='text-blue-600 decoration-2 hover:underline font-medium'
										to='/login'
									>
										Login here
									</NavLink>
								</p>
							</div>

							<div className='mt-5'>
								<form onSubmit={e => submitHandler(e)}>
									<div className='grid gap-y-4'>
										<Input
											title='Email address'
											type='email'
											name='email'
											placeholder='your@e.mail'
											// value={formData.email}
											// onChange={e =>
											// 	setFormData({ ...formData, email: e.target.value })
											// }
											errorMessage='Enter valid email!'
										/>

										<ReCAPTCHA
											ref={captchaRef}
											sitekey={process.env.REACT_APP_RECAPTCHA_SITE_KEY}
											className='mb-5 w-full'
										/>

										<button
											type='submit'
											className='py-3 px-4 inline-flex justify-center items-center gap-2 rounded-md border border-transparent font-semibold bg-blue-500 text-white hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2 transition-all text-sm dark:focus:ring-offset-gray-800'
											// disabled={isLoading}
											// onClick={e => submitHandler(e)}
										>
											Reset password
										</button>
									</div>
								</form>
							</div>
						</div>
					</div>
				</main>
			</div>
		</PageLayout>
	)
}
