import * as React from 'react'

import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import Accordion from '@mui/material/Accordion'
import AccordionDetails from '@mui/material/AccordionDetails'
import AccordionSummary from '@mui/material/AccordionSummary'
import Box from '@mui/material/Box'
import Container from '@mui/material/Container'
// import CssBaseline from '@mui/material/CssBaseline'
import Typography from '@mui/material/Typography'
import Footer from '../components/Footer'
import Appbar from '../components/Appbar'
import PageLayout from '../layout/PageLayout'
import faqItems from '../components/FAQ/items'

const FaqPage = () => {
	return (
		<PageLayout>
			<div className='bg-gray-700 p-4 shadow-xl py-8'>
				<h4 className='text-4xl font-bold text-white tracking-widest uppercase text-center'>
					FAQ
				</h4>
				<p className='text-center text-white text-sm mt-2'>
					Here are some of the frequently asked questions
				</p>
				<div className='space-y-12 px-2 xl:px-16 mt-12'>
					{faqItems.map((item, idx) => (
						<div key={idx} className='mt-4 flex'>
							<div>
								<div className='flex items-center h-16 border-l-4 border-white'>
									<span className='text-4xl text-white px-4'>Q.</span>
								</div>
								<div className='flex items-center h-16 border-l-4 border-white'>
									<span className='text-4xl text-white px-4'>A.</span>
								</div>
							</div>
							<div>
								<div className='flex items-center h-16'>
									<span className='text-lg text-white font-bold'>{item.q}</span>
								</div>
								<div className='flex items-center py-2'>
									<span className='text-white'>{item.a}</span>
								</div>
							</div>
						</div>
					))}
				</div>
			</div>
		</PageLayout>
	)
}

export default FaqPage
