import React, { useEffect } from 'react'
import LoaderLinear from '../../components/LoaderLinear'

import { useReferral } from '../../hooks/referral.hook'

import ReferralsTable from './components/ReferralsTable'

export const MyReferralsPage = () => {
	const { loading, ready, getReferral, referralData } = useReferral()

	useEffect(() => {
		getReferral()
	}, [])

	if (loading || !ready) return <LoaderLinear />

	console.log('referralData', referralData)

	return !referralData.length ? (
		'No data'
	) : (
		<ReferralsTable rows={referralData} />
	)
}
