import { useEffect, useState } from 'react'

import currencyFormatUSD from '../../../utils/currencyFormatUSD'

import {
	FormControl,
	FormHelperText,
	InputLabel,
	List,
	ListItem,
	ListItemText,
	Paper,
} from '@mui/material'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import InputAdornment from '@mui/material/InputAdornment'
import Modal from '@mui/material/Modal'
import OutlinedInput from '@mui/material/OutlinedInput'
import Typography from '@mui/material/Typography'
import Alert from '@mui/material/Alert'

const style = {
	position: 'absolute',
	top: '50%',
	left: '50%',
	transform: 'translate(-50%, -50%)',
	width: 400,
	bgcolor: 'background.paper',
	border: '2px solid #000',
	boxShadow: 24,
	p: 4,
}

export default function CalculateModal({ plan }) {
	const [open, setOpen] = useState(false)
	const handleOpen = () => setOpen(true)
	const handleClose = () => setOpen(false)

	const [amount, setAmount] = useState(plan.amountMin)

	const [dailyProfit, setDailyProfit] = useState(0)
	const [totalProfit, setTotalProfit] = useState(0)
	const [totalMoney, setTotalMoney] = useState(0)

	useEffect(() => {
		if (amount < plan.amountMin) {
			setDailyProfit(0)
			setTotalProfit(0)
			setTotalMoney(0)
		} else {
			setDailyProfit((amount / 100) * plan.percentDaily)
			setTotalProfit((amount / 100) * plan.percentDaily * plan.termDays)
			setTotalMoney(
				(amount / 100) * plan.percentDaily * plan.termDays + +amount
			)
		}
	}, [amount])

	return (
		<div>
			<Button
				onClick={handleOpen}
				color='warning'
				variant='contained'
				sx={{ mt: 3 }}
			>
				Calculate
			</Button>
			<Modal
				open={open}
				onClose={handleClose}
				aria-labelledby='modal-modal-title'
				aria-describedby='modal-modal-description'
			>
				<Box sx={style}>
					<Typography variant='h6' component='h2'>
						Plan "{plan.name}"
					</Typography>

					<FormControl fullWidth sx={{ my: 3 }}>
						<InputLabel htmlFor='outlined-adornment-amount'>Amount</InputLabel>
						<OutlinedInput
							type='number'
							value={amount}
							onChange={e => setAmount(e.target.value)}
							startAdornment={
								<InputAdornment position='start'>$</InputAdornment>
							}
							label='Amount'
						/>
						<FormHelperText>
							{`${currencyFormatUSD(plan.amountMin)} -
								${currencyFormatUSD(plan.amountMax)}`}
						</FormHelperText>
					</FormControl>

					<Alert severity='info'>The deposit will be returned at the end</Alert>

					<List dense>
						<ListItem
							secondaryAction={currencyFormatUSD(dailyProfit)}
							className='text-lg'
						>
							<ListItemText primary={`Daily Profit (${plan.percentDaily}%):`} />
						</ListItem>
						<ListItem
							secondaryAction={`${plan.termDays} days`}
							className='text-lg'
						>
							<ListItemText primary='Period:' />
						</ListItem>
						<ListItem
							secondaryAction={currencyFormatUSD(totalProfit)}
							className='text-lg'
						>
							<ListItemText
								primary={`Total Profit (${
									plan.percentDaily * plan.termDays
								}%):`}
							/>
						</ListItem>

						<ListItem
							secondaryAction={currencyFormatUSD(totalMoney)}
							className='text-lg text-red-600 bg-yellow-300'
						>
							<ListItemText primary='You will receive:' />
						</ListItem>
					</List>
				</Box>
			</Modal>
		</div>
	)
}
