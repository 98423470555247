import { useCallback, useContext, useEffect, useState } from 'react'

import { useHttp } from '../http.hook'
import { AuthContext } from '../../context/AuthContext'

export const useInvestment = () => {
	const { loading, request } = useHttp()
	const { token } = useContext(AuthContext)

	const [ready, setReady] = useState(false)
	const [investmentList, setInvestmentList] = useState([])

	const getInvestment = useCallback(async () => {
		try {
			const doc = await request(`/api/admin/investment`, 'GET', null, {
				Authorization: `Bearer ${token}`,
			})

			setInvestmentList(doc)
			setReady(true)
		} catch (err) {
			console.log(err)
		}
	}, [token, request])

	return {
		// loading,
		ready,
		getInvestment,
		investmentList,
	}
}
