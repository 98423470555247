import React from 'react'
import PropTypes from 'prop-types'

import { dateTimeFormat } from '../../../utils/dateTimeFormat'
import currencyFormatUSD from '../../../utils/currencyFormatUSD'

import ContentCopyIcon from '@mui/icons-material/ContentCopy'
import Button from '@mui/material/Button'
import FirstPageIcon from '@mui/icons-material/FirstPage'
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft'
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight'
import LastPageIcon from '@mui/icons-material/LastPage'
import Box from '@mui/material/Box'
import CircularProgress from '@mui/material/CircularProgress'
import IconButton from '@mui/material/IconButton'
import Paper from '@mui/material/Paper'
import { useTheme } from '@mui/material/styles'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableFooter from '@mui/material/TableFooter'
import TableHead from '@mui/material/TableHead'
import TablePagination from '@mui/material/TablePagination'
import TableRow from '@mui/material/TableRow'
import Typography from '@mui/material/Typography'
import CopyToClipboard from 'react-copy-to-clipboard'
import Tooltip from '@mui/material/Tooltip'
import CopyText from '../../../components/CopyText'

function CircularProgressWithLabel(props) {
	const progress =
		props.progress > props.progressTotal ? props.progress - 1 : props.progress

	const value = (progress * 100) / props.progressTotal

	return (
		<Box sx={{ position: 'relative', display: 'inline-flex' }}>
			{/* <CircularProgress variant='determinate' {...props} /> */}
			<CircularProgress variant='determinate' value={value} size={props.size} />
			<Box
				sx={{
					top: 0,
					left: 0,
					bottom: 0,
					right: 0,
					position: 'absolute',
					display: 'flex',
					alignItems: 'center',
					justifyContent: 'center',
				}}
			>
				<Typography variant='caption' component='div' color='text.white'>
					{/* {`${Math.round(props.value)}%`} */}
					{progress}/{props.progressTotal}
				</Typography>
			</Box>
		</Box>
	)
}

CircularProgressWithLabel.propTypes = {
	progress: PropTypes.number.isRequired,
	progressTotal: PropTypes.number.isRequired,
}

function classNames(...classes) {
	return classes.filter(Boolean).join(' ')
}

function msToTime(duration) {
	var minutes = parseInt((duration / (1000 * 60)) % 60),
		hours = parseInt((duration / (1000 * 60 * 60)) % 24),
		days = parseInt(duration / (1000 * 60 * 60 * 24))

	hours = hours < 10 ? '0' + hours : hours
	minutes = minutes < 10 ? '0' + minutes : minutes

	return days + 'd ' + hours + 'h ' + minutes + 'm'
}

const InvestmentCard = ({ obj }) => {
	const daily = (obj.amount / 100) * obj.plan.percentDaily

	const claimed = obj.incomes.reduce((totalSumm, item) => {
		totalSumm += item.amount
		return totalSumm
	}, 0)

	const total =
		obj.wallet === 'real'
			? daily * obj.plan.termDays + obj.amount
			: daily * obj.plan.termDays

	const nextProfit =
		obj.plan.termDays - obj.incomes.length === 1 ? daily + obj.amount : daily

	const nextIncomeDate =
		obj.incomes.length > 0
			? dateTimeFormat(
					new Date(obj.incomes[obj.incomes.length - 1].createdAt).setDate(
						new Date(obj.incomes[obj.incomes.length - 1].createdAt).getDate() +
							1
					)
			  )
			: dateTimeFormat(
					new Date(obj.createdAt).setDate(new Date(obj.createdAt).getDate() + 1)
			  )

	const nextIncomeRemain = msToTime(
		new Date(nextIncomeDate) - new Date(dateTimeFormat(Date.now()))
	)

	return (
		<div className='flex item'>
			<div
				className={classNames(
					obj.closedAt ? 'bg-blue-950' : 'bg-blue-900',
					'rounded-2xl px-3 py-6 text-white w-full max-w-sm'
				)}
			>
				<div className='flex justify-between'>
					<div
						className={classNames(
							obj.wallet === 'real' ? 'bg-green-600' : 'bg-red-600',
							'px-3 py-1 rounded-xl'
						)}
					>
						{obj.wallet === 'real' ? 'Real' : 'Bonus'}
					</div>
					<div className=' text-sky-600'>{dateTimeFormat(obj.createdAt)}</div>
				</div>

				<div className='flex justify-center my-3 italic'>
					Plan "{obj.plan.name}"
				</div>

				<div className='flex justify-center align-middle gap-x-4'>
					<div>
						<div className='flex justify-center font-bold text-xl'>
							{currencyFormatUSD(obj.amount)}
						</div>
						<div className='flex justify-center align-middle font-thin text-sm'>
							Amount
						</div>
					</div>
					<div>
						<div className='flex justify-center font-bold text-xl'>
							{obj.plan.percentDaily}%
						</div>
						<div className='flex justify-center align-middle font-thin text-sm'>
							Daily
						</div>
					</div>
					<div>
						<div className='flex justify-center font-bold text-xl'>
							{obj.plan.termDays} days
						</div>
						<div className='flex justify-center align-middle font-thin text-sm'>
							Period
						</div>
					</div>
				</div>

				<div
					className={classNames(
						obj.wallet === 'real' ? 'border-y-green-600' : 'border-y-red-600',
						'border-y-2 py-3 my-3'
					)}
				>
					{obj.closedAt ? (
						<div
							className={classNames(
								obj.wallet === 'real' ? 'bg-green-600' : 'bg-red-600',
								'flex justify-center my-3 italic p-3'
							)}
						>
							Closed at {dateTimeFormat(obj.closedAt)}
						</div>
					) : (
						<div className='my-3 italic'>
							<div className='flex justify-center italic'>
								You will receive {currencyFormatUSD(nextProfit)}
							</div>
							<div className='flex justify-center italic'>
								Remain: {nextIncomeRemain}
							</div>
						</div>
					)}
				</div>

				<div className='flex justify-center align-middle gap-x-4'>
					<div>
						<div className='flex justify-center font-bold text-xl'>
							<CircularProgressWithLabel
								size={50}
								progress={obj.incomes.length}
								progressTotal={obj.plan.termDays}
							/>
						</div>
					</div>

					<div>
						<div className='flex justify-center font-bold text-xl'>
							{currencyFormatUSD(claimed)} / {currencyFormatUSD(total)}
						</div>
						<div className='flex justify-center align-middle font-thin text-sm'>
							Total Claimed
						</div>
					</div>
				</div>

				<div className='flex justify-center text-xs mx-auto mt-6 text-gray-300'>
					{obj.wallet === 'real'
						? 'The body will be returned at the end of the term'
						: '-'}
				</div>

				<div className='flex justify-center text-xs mx-auto mt-6 text-gray-300'>
					ID:{' '}
					<CopyText
						name={obj._id}
						value={obj._id}
						onCopyMessage='ID copied!'
						tooltip='Copy ID'
					/>
				</div>
			</div>
		</div>
	)
}

export default InvestmentCard
