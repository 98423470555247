import React from 'react'

import UsersTable from './components/UsersTable'

export const AdminUsersPage = () => {
	return (
		<main>
			<div className='mx-auto max-w-7xl py-6 sm:px-6 lg:px-8'>
				<UsersTable />
			</div>
		</main>
	)
}
