import React from 'react'

import PageLayout from '../../layout/PageLayout'

import { Container } from '@mui/material'

const SuccessPage = () => {
	return (
		<PageLayout>
			<Container>SuccessPage</Container>
		</PageLayout>
	)
}

export default SuccessPage
